define('jobbio-backend/components/team-collaboration/edit-team-member', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        errors: null,
        onEditModalOpened: Ember.observer('editModalOpened', function () {
            this.set('first_name', this.get('member.user.first_name'));
            this.set('last_name', this.get('member.user.last_name'));
            this.set('email', this.get('member.user.email'));
            this.set('role', this.get('member.role'));
            this.set('country_code', this.get('member.user.country_code'));
            this.set('password', '');
            this.set('errors', {});
        }),
        init() {
            this._super(...arguments);
        },
        didRender() {
            // Allow only first '+' and numbers for country code field
            window.$('#edit_team_member_country_code').on('keypress keyup blur', function (event) {
                if (event.which < 48 || event.which > 57) {
                    if (event.which == 43) {
                        if (window.$('#edit_team_member_country_code').val() != '') {
                            event.preventDefault();
                        }
                    } else {
                        event.preventDefault();
                    }
                }
            });
        },
        actions: {
            submit(member) {
                member.set('first_name', this.get('first_name'));
                member.set('last_name', this.get('last_name'));
                member.set('email', this.get('email'));
                member.set('role', this.get('role'));
                member.set('country_code', this.get('country_code'));
                member.set('password', this.get('password'));
                var self = this;
                member.validate().then(({ validations }) => {
                    var errors = {};
                    var forceExecute = false;
                    validations.get('errors').forEach(error => {
                        errors[`${error.attribute}`] = error.message ? error.message : '';
                    });
                    if (!this.get('password')) {
                        errors['password'] = '';
                    }
                    if (validations.get('errors.firstObject.attribute') == 'password' && validations.get('errors.firstObject.type') == 'presence') {
                        forceExecute = true;
                    }
                    this.set('errors', errors);
                    if (validations.get('isValid') || forceExecute) {
                        member.save().then(() => {
                            self.toast.success('Updated successfully');
                            window.$('#editTeamMember').modal('hide');
                        }).catch(xhr => {
                            var errors = {};
                            xhr.errors.forEach(error => {
                                errors[error.source.pointer.replace('/data/attributes/', '')] = error.detail;
                            });
                            this.set('errors', errors);
                        });
                    }
                });
            },
            selectRole: function (role) {
                this.set('role', role);
            }
        }
    });
});