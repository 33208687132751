define('jobbio-backend/models/billing', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const BillValidations = (0, _emberCpValidations.buildValidations)({
    contact_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    })],
    contact_email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    contact_address: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    })],
    vat_number: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    })]
  });

  exports.default = _emberData.default.Model.extend(BillValidations, {
    contact_name: _emberData.default.attr('string'),
    contact_email: _emberData.default.attr('string'),
    contact_address: _emberData.default.attr('string'),
    vat_number: _emberData.default.attr('string'),
    errors: _emberData.default.attr()
  });
});