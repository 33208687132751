define('jobbio-backend/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),

    load() {
      let userId = this.get('session.data.authenticated.company_member.user.id');
      if (!Ember.isEmpty(userId)) {
        return this.get('store').findRecord('teammember', userId).then(response => {
          this.set('teammember', response.get('company_member'));
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});