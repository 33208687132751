define("jobbio-backend/models/location", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    address: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    country_short: _emberData.default.attr("string"),
    lat: _emberData.default.attr("string"),
    lng: _emberData.default.attr("string"),
    job: _emberData.default.hasMany("job")
  });
});