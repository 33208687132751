define('jobbio-backend/models/email', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const EmailValidations = (0, _emberCpValidations.buildValidations)({
        email: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'This is a required field!'
        }), (0, _emberCpValidations.validator)('format', {
            type: 'email'
        })]
    });

    exports.default = _emberData.default.Model.extend(EmailValidations, {
        email: _emberData.default.attr('string')
    });
});