define('jobbio-backend/components/application/new-application-type-filter', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    actions: {
      setSelectedApplicationType(applicationType) {
        this.set('selectedApplicationType', applicationType);
      }
    }
  });
});