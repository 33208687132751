define('jobbio-backend/models/question', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const QuestionValidations = (0, _emberCpValidations.buildValidations)({
		text: [(0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: 'This is a required field.'
		}), (0, _emberCpValidations.validator)('length', {
			max: 255,
			message: 'Ensure this field has no more than 255 characters.'
		})],
		type: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: 'This is a required field.'
		}),
		value_from: [(0, _emberCpValidations.validator)('inline', {
			validate(value, options, model) {
				if (model.get('type') == 'slider') {
					if (!value) {
						return 'This is a required field.';
					} else if (model.get('value_to') && parseInt(value) >= parseInt(model.get('value_to'))) {
						return 'The from value must be less than the to value.';
					} else {
						return true;
					}
				} else {
					return true;
				}
			}
		})],
		value_to: [(0, _emberCpValidations.validator)('inline', {
			validate(value, options, model) {
				if (model.get('type') == 'slider' && !value) {
					return 'This is a required field.';
				} else {
					return true;
				}
			}
		})]
	});

	exports.default = _emberData.default.Model.extend(QuestionValidations, {
		text: _emberData.default.attr("string"),
		type: _emberData.default.attr("string"),
		video_token: _emberData.default.attr("string"),
		options: _emberData.default.attr(),
		value_from: _emberData.default.attr("string"),
		value_to: _emberData.default.attr("string"),
		in_bank: _emberData.default.attr("number"),
		created_at: _emberData.default.attr("date"),
		updated_at: _emberData.default.attr("date")
	});
});