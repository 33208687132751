define('jobbio-backend/components/settings/integration-list', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    link: _environment.default.APP.CLOUD_FRONT_HOST,
    workable_auth: _environment.default.APP.WORKABLE_AUTH,
    workable_client_id: _environment.default.APP.WORKABLE_CLIENT_ID,
    domain: _environment.default.APP.DOMAIN,
    api_key: '',
    board: '',
    source: '',
    user: '',
    greenhouse: false,
    init() {
      this._super(...arguments);
      var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/integrations';
      var self = this;
      this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
        window.$.ajax({
          url: url,
          headers: {
            Authorization: headerValue
          },
          type: 'GET',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        }).then(response => {
          if (response.greenhouse.api_key) {
            self.set('api_key', response.greenhouse.api_key);
            self.set('board', response.greenhouse.board);
            self.set('source_id', parseInt(response.greenhouse.source));
            self.set('user_id', parseInt(response.greenhouse.user));
            self.set('greenhouse', true);
          }
          self.set('workable', response.workable);
          if (response.lever.domain) {
            self.set('lever_domain', response.lever.domain);
            self.set('lever_api_key', response.lever.api_key);
            self.set('lever', true);
          }
          if (response.slack.channel) {
            self.set('slack_channel', response.slack.channel);
            self.set('slack_api_key', response.slack.api_key);
            self.set('slack', true);
          }
          if (response.jazzhr.api_key) {
            self.set('jazzhr_key', response.jazzhr.api_key);
            self.set('jazzhr', true);
          }
          if (response.peoplehr.api_key) {
            self.set('peoplehr_key', response.peoplehr.api_key);
            self.set('peoplehr', true);
          }
        });
      });
    },
    actions: {
      addGreenHouse: function () {
        var data = this.getProperties('api_key');
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/greenhouse';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('greenhouse', true);
            self.toast.success('Greenhouse details updated');
          }, () => {
            self.toast.success('Greenhouse API Key is invalid');
          });
        });
      },
      revokeWorkable: function () {
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/workable';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('workable', false);
            self.toast.success('Workable access revoked');
          });
        });
      },
      addLever: function () {
        var data = { 'domain': this.get('lever_domain'), 'api_key': this.get('lever_api_key') };
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/lever';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('lever', true);
            self.toast.success('Lever details updated');
          }, () => {
            self.toast.success('Lever details were not updated');
          });
        });
      },
      updateLever: function () {
        var data = { 'domain': this.get('lever_domain'), 'api_key': this.get('lever_api_key') };
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/lever';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'PATCH',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('lever', true);
            self.toast.success('Lever details updated');
          }, () => {
            self.toast.success('Lever details were not updated');
          });
        });
      },
      revokeLever: function () {
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/lever';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('lever', false);
            self.toast.success('Lever access revoked');
          });
        });
      },
      addSlack: function () {
        var data = { 'channel': this.get('slack_channel'), 'api_key': this.get('slack_api_key') };
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/slack';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('slack', true);
            self.toast.success('Slack details updated');
          }, () => {
            self.toast.success('Slack details were not updated');
          });
        });
      },
      updateSlack: function () {
        var data = { 'channel': this.get('slack_channel'), 'api_key': this.get('slack_api_key') };
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/slack';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'PATCH',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('slack', true);
            self.toast.success('Slack details updated');
          }, () => {
            self.toast.success('Slack details were not updated');
          });
        });
      },
      revokeSlack: function () {
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/slack';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('slack', false);
            self.toast.success('Slack access revoked');
          });
        });
      },
      addJazz: function () {
        var data = { 'api_key': this.get('jazzhr_key') };
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/jazzhr';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('jazzhr', true);
            self.toast.success('JazzHR details updated');
          }, () => {
            self.toast.success('JazzHR details were not updated');
          });
        });
      },
      updateJazz: function () {
        var data = { 'api_key': this.get('jazzhr_key') };
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/jazzhr';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'PATCH',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('jazzhr', true);
            self.toast.success('JazzHr details updated');
          }, () => {
            self.toast.success('JazzHR details were not updated');
          });
        });
      },
      revokeJazz: function () {
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/jazzhr';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('jazzhr', false);
            self.set('jazzhr_key', '');
            self.toast.success('JazzHR access revoked');
          });
        });
      },
      addPeople: function () {
        var data = { 'api_key': this.get('peoplehr_key') };
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/peoplehr';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('peoplehr', true);
            self.toast.success('PeopleHR details updated');
          }, () => {
            self.toast.success('PeopleHR details were not updated');
          });
        });
      },
      updatePeople: function () {
        var data = { 'api_key': this.get('peoplehr_key') };
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/peoplehr';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'PATCH',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('peoplehr', true);
            self.toast.success('PeopleHR details updated');
          }, () => {
            self.toast.success('PeopleHR details were not updated');
          });
        });
      },
      revokePeople: function () {
        var self = this;
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/peoplehr';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.set('peoplehr', false);
            self.set('peoplehr_key', '');
            self.toast.success('PeopleHR access revoked');
          });
        });
      }
    }
  });
});