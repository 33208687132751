define('jobbio-backend/components/team-collaboration/share-talent-modal', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    sharedTeamMembers: null,
    selectedBackground: Ember.computed('property', function () {
      return new Ember.String.htmlSafe('background-color: #e2e2e2;');
    }),
    init() {
      this._super(...arguments);
      this.set('sharedTeamMembers', []);
    },
    actions: {
      clearModal: function () {
        this.set('sharedTeamMembers', []);
      },
      shareTalents: function () {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', `share_talent_${this.get('id')}`);
        } else {
          var self = this;
          var data = {
            application_id: parseInt(this.get('selectedApplication.id')),
            recipients: this.get('sharedTeamMembers')
          };
          if (this.get('sharedTeamMembers').length) {
            this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
              window.$.ajax({
                url: `${this.get('apiBaseUrl')}/jobs/applications/share`,
                headers: {
                  Authorization: headerValue
                },
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json;charset=utf-8',
                dataType: 'json'
              }).done(() => {
                self.toast.success('Application shared');
                window.$(`#share-talent-team-members_${this.get('id')}`).modal('hide');
                self.send('clearModal');
              });
            });
          } else {
            window.swal("Oops!", "You need to select at least one team member", "warning");
          }
        }
      },
      toggleSharedTeamMembers: function (teamMemberID) {
        if (this.get('sharedTeamMembers').includes(teamMemberID)) {
          this.get('sharedTeamMembers').removeObject(parseInt(teamMemberID));
        } else {
          this.get('sharedTeamMembers').pushObject(parseInt(teamMemberID));
        }
      }
    }
  });
});