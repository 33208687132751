define('jobbio-backend/components/application/job-tag-filter', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    tags: null,
    tag_error: "",
    loading: false,
    loaded: false,
    modelType: Ember.computed('id', function () {
      if (this.get('id') == 'live_jobs') {
        return 'live-job-tag';
      } else if (this.get('id') == 'paused_jobs') {
        return 'paused-job-tag';
      } else if (this.get('id') == 'closed_jobs') {
        return 'closed-job-tag';
      } else if (this.get('id') == 'talent_pool') {
        return 'talent-pool-tag';
      } else if (this.get('id') == 'followers') {
        return 'followers-tag';
      }
    }),
    onAttrsUpdated: Ember.observer('job.id', 'tagUpdated', 'shortlisted', function () {
      var self = this;
      setTimeout(function () {
        self.set('selectedTag', null);
        self.set('loading', false);
        self.set('loaded', false);
        self.get('store').unloadAll(self.get('modelType'));
        self.send('load');
      }, 1000);
    }),
    init() {
      this._super(...arguments);
      this.set('tags', []);
      this.send('load');
    },
    actions: {
      load() {
        if (this.get('loading') || this.get('loaded')) {
          return;
        }
        var params = {
          job_id: this.get('job.id')
        };
        if (this.get('searchFollowers')) {
          params['talent_type'] = "follower";
        } else if (this.get('searchTalentPool')) {
          params['talent_type'] = "in_pool";
        }
        this.set('loading', true);
        this.get('store').query(this.get('modelType'), params).then(response => {
          this.set('loading', false);
          if (response == null) {
            return;
          }
          var self = this;
          this.set('tags', []);
          response.forEach(function (object) {
            if (!self.get('job') && (self.get('id') == 'live_jobs' || self.get('id') == 'paused_jobs' || self.get('id') == 'closed_jobs')) {
              object.set('talent_no', '');
            }
            self.get('tags').pushObject(object);
          });
          this.set('loaded', true);
        });
      },
      setSelectedTag(tag) {
        this.set('selectedTag', tag);
      },
      addTag(tagName) {
        if (tagName != '' || tagName == undefined) {
          if (tagName.length < 31) {
            this.get('store').createRecord("talent-pool-tag", {
              name: tagName,
              talent_no: 0
            }).save().then(() => {
              this.set('tagUpdated', !this.get('tagUpdated'));
            });
            this.set('new_tag_name', '');
            this.set('tag_error', '');
          } else {
            this.set('tag_error', 'Ensure this field has no more than 30 characters.');
          }
        } else {
          this.set('tag_error', 'This is a required field');
        }
      },
      removeTag(tag) {
        tag.deleteRecord();
        tag.save().then(() => {
          this.set('tagUpdated', !this.get('tagUpdated'));
        });
      },
      editTag(tag) {
        tag.save().then(() => {
          this.set('tagUpdated', !this.get('tagUpdated'));
        });
      }
    }
  });
});