define('jobbio-backend/serializers/job', ['exports', 'jobbio-backend/serializers/drf', 'ember-data'], function (exports, _drf, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      stages: {
        embedded: 'always'
      }
    }
  });
});