define('jobbio-backend/components/common/dashboard-feed-list-job-item', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    message: Ember.computed(function () {
      var type = this.get('type');
      var owner_id = this.get("item.company_member.id");
      var current_user_id = this.get('session.data.authenticated.company_member.id');
      var start = "";
      if (current_user_id == owner_id) {
        start = "Your '";
      }
      if (type == "job_posted") {
        return start + this.get('job.title') + "' job was posted";
      } else if (type == "job_views") {
        return start + this.get('job.title') + "' job has " + this.get("item.value") + " views";
      } else if (type == "job_applications") {
        return start + this.get('job.title') + "' job has " + this.get("item.value") + " applications";
      } else if (type == "job_paused") {
        return start + this.get('job.title') + "' job has been paused";
      } else if (type == "job_drafted") {
        return start + this.get('job.title') + "' job was saved as a draft";
      } else if (type == "job_closed") {
        return start + this.get('job.title') + "' job has been closed";
      } else if (type == "job_updated") {
        return start + this.get('job.title') + "' job has been updated";
      } else if (type == "job_unpaused") {
        return start + this.get('job.title') + "' job has been unpaused";
      }
      return this.get('job.title') + type;
    }),
    init() {
      this._super(...arguments);
    },
    actions: {
      setSelectedJob(job) {
        var self = this;
        self.get('store').findRecord('job', job.id).then(function (response) {
          self.set('clickedFeed', response);
          window.$('#jobFeedPopup').modal('show');
        });
      }
    }
  });
});