define('jobbio-backend/controllers/company/jobs', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        selectedTab: 'live_jobs',
        resetSelectedJobs: false,
        pausedJobs: null,
        liveJobs: null,
        draftJobs: null,
        selectedPausedJob: null,
        selectedLiveJob: null,
        selectedDraftJob: null,
        selectedJob: null,
        init() {
            this._super(...arguments);
            this.set('liveJobs', []);
            this.set('pausedJobs', []);
            this.set('draftJobs', []);
        }
    });
});