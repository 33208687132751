define('jobbio-backend/helpers/remove-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.removeText = removeText;
  function removeText(params /*, hash*/) {
    var [moment, trim] = params;
    var text = moment._d.toString();
    var newText = text.slice(0, text.indexOf(trim));
    return newText;
  }

  exports.default = Ember.Helper.helper(removeText);
});