define('jobbio-backend/components/employer-branding/edit-testimonial', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    options: null,
    onEditClicked: Ember.observer('editClicked', function () {
      this.send('resetModal');
    }),
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        plugins: ['autolink lists charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist '
      });
    },
    actions: {
      editTestimonial: function () {
        if (this.get('title') && this.get('detail') && this.get('text')) {
          var formData = this.getProperties('title', 'detail', 'text');
          formData.type = 'text';
          formData.branding = 'testimony';
          var url = _environment.default.APP.API_HOST + '/jobs/content/' + this.get('testimonial.id');
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: url,
              headers: {
                Authorization: headerValue
              },
              type: 'PATCH',
              data: JSON.stringify(formData),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).then(() => {
              this.set('testimonial.title', formData.title);
              this.set('testimonial.detail', formData.detail);
              this.set('testimonial.text', formData.text);
              window.$('#editToTestimonial .close').click();
              this.toast.success('Text updated');
            });
          });
        }

        if (!this.get('title')) {
          this.set('errors.title', 'This is a required field!');
        } else {
          this.set('errors.title', '');
        }

        if (!this.get('detail')) {
          this.set('errors.detail', 'This is a required field!');
        } else {
          this.set('errors.detail', '');
        }

        if (!this.get('text')) {
          this.set('errors.text', 'This is a required field!');
        } else {
          this.set('errors.text', '');
        }
      },
      resetModal() {
        this.setProperties({
          title: this.get('testimonial.title'),
          detail: this.get('testimonial.detail'),
          text: this.get('testimonial.text')
        });
        window.tinymce.get('editedBrandingTestimonialText').undoManager.clear();
        this.set('errors', {});
      }
    }
  });
});