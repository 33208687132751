define('jobbio-backend/router', ['exports', 'jobbio-backend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('company', { path: '/' }, function () {
      this.route('dashboard', { path: '/' });
      this.route('post-job');
      this.route('job', { path: '/job/:job_id' }, function () {
        this.route('applications');
      });
      this.route('question-bank');
      this.route('content-bank');
      this.route('edit-account');
      this.route('notifications');
      this.route('auto-replies');
      this.route('talent-pool');
      this.route('new-talent');
      this.route('applications');
      this.route('jobs');
      this.route('settings');
      this.route('team-collaboration');
      this.route('employer-branding');
    });
    this.route('infinity-scroll');
    this.route('workable-callback');
  });

  exports.default = Router;
});