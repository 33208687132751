define('jobbio-backend/routes/company/job/applications', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    renderTemplate: function () {
      this.render('company/job/applications', { into: 'application' });
    },
    model: function () {
      var job = this.modelFor('company.job');
      return this.store.findRecord('job', job.id);
    }
  });
});