define('jobbio-backend/components/settings/data-setting', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    setting: null,
    init() {
      this._super(...arguments);
      this.send("load");
    },
    actions: {
      load() {
        this.get('store').queryRecord("gdpr-settings", {}).then(record => {
          this.set("setting", record);
        });
      },
      selectedApplicationAction(action) {
        this.set("setting.ats_action", action);
        this.send("save");
      },
      selectedApplicationDuration(duration) {
        this.set("setting.ats_action_threshold", duration);
        this.send("save");
      },
      selectedTalentPoolAction(action) {
        this.set("setting.ats_action_talent_pool", action);
        this.send("save");
      },
      selectedTalentPoolDuration(duration) {
        this.set("setting.ats_talent_pool_threshold", duration);
        this.send("save");
      },
      selectedFollowerAction(action) {
        this.set("setting.ats_action_following", action);
        this.send("save");
      },
      selectedFollowerDuration(duration) {
        this.set("setting.ats_following_threshold", duration);
        this.send("save");
      },
      save() {
        this.get("setting").save().then(() => {
          this.toast.success('Data settings updated');
        });
      }
    }
  });
});