define('jobbio-backend/serializers/conversation', ['exports', 'jobbio-backend/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      let { modelName } = primaryModelClass;
      var newId = payload[modelName].id;

      if (store.hasRecordForId(modelName, newId)) {
        let record = store.peekRecord(modelName, newId);
        store.unloadRecord(record);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});