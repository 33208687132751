define('jobbio-backend/components/company/job-tabs', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);
    },
    actions: {
      setSelectedTab(tabName) {
        this.set('selectedTab', tabName);
      }
    }
  });
});