define('jobbio-backend/controllers/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service('session'),
        showPopup: Ember.observer('currentRouteName', function () {
            if (this.get('currentRouteName') == 'company.post-job' || this.get('currentRouteName') == 'company.jobs') {
                this.get('store').queryRecord('tour', {}).then(response => {
                    var tour = response;
                    if (tour.get('guided_tour') == false) {
                        window.swal({
                            title: 'Lets Get Your Job Live',
                            text: 'Take a few moments to add your job details and we will start attracting the right talent.',
                            icon: "success",
                            buttons: {
                                post_job: {
                                    text: 'Post Job',
                                    closeModal: true,
                                    className: 'btn btn-warning application-swal-post-job'
                                }
                            }
                        });
                        tour.set('guided_tour', true);
                        tour.save();
                    }
                });
            }
        }),
        actions: {
            invalidateSession() {
                this.get('session').invalidate();
            }
        }
    });
});