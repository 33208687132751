define('jobbio-backend/components/team-collaboration/team-members-list', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    errors: null,
    init() {
      this._super(...arguments);
      this.set('errors', {
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        country_code: '',
        password: ''
      });
    },
    actions: {
      editTeamMember: function (member) {
        this.set('selectedMember', member);
        this.set('editModalOpened', !this.get('editModalOpened'));
        window.$('#editTeamMember').modal('show');
      },
      selectRole: function (role) {
        this.set('editCompanyMemberRole', role);
      }
    }
  });
});