define('jobbio-backend/components/company/jobs-column', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    expanded: false,
    selectedJob: null,
    jobType: null,
    jobs: null,
    init() {
      this._super(...arguments);
      this.set('jobs', []);
    }
  });
});