define('jobbio-backend/components/common/dashboard-feed-list-talent-item', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    talentName: Ember.computed(function () {
      return this.get("talent.user.first_name") + " " + this.get("talent.user.last_name");
    }),
    message: Ember.computed(function () {
      var type = this.get('type');
      var talentName = this.get("talentName");
      if (type == "new_applicant") {
        var owner_id = this.get("item.job.created_by");
        var current_user_id = this.get('session.data.authenticated.company_member.id');
        var relation = " the '";
        if (current_user_id == owner_id) {
          relation = " your '";
        }
        return talentName + " has applied to " + relation + this.get('item.job.title') + "' role";
      } else if (type == "talent_meesage") {
        return "You received a message from " + talentName;
      } else if (type == "new_follower") {
        return talentName + " is now following you";
      }
      return talentName;
    }),
    title: Ember.computed(function () {
      var type = this.get('type');
      if (type == "new_applicant") {
        return " | " + this.get('item.job.title');
      }
      return "";
    }),
    init() {
      this._super(...arguments);
    },
    actions: {
      setSelectedJob(application, job) {
        var self = this;
        var exist = this.get('store').peekRecord('feed-application', application.id);
        var emberApplication;
        if (!exist) {
          emberApplication = this.get('store').createRecord('feed-application', application);
        } else {
          emberApplication = exist;
        }
        self.set('selectedApplication', emberApplication);
        self.get('store').findRecord('job', job.id).then(function (response) {
          self.set('clickedFeed', response);
          window.$('#applicationFeedPopup').modal('show');
          setTimeout(function () {
            window.$('.message-body').scrollTop(0);
          }, 1000);
        });
      },
      setSelectedTalent(talent) {
        var self = this;
        self.set('selectedTalent', talent);
        window.$('#talentFeedPopup').modal('show');
        setTimeout(function () {
          window.$('.message-body').scrollTop(0);
        }, 1000);
      }
    }
  });
});