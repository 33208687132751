define('jobbio-backend/components/employer-branding/post-testimonial', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    errors: null,
    options: null,
    onAddNewClicked: Ember.observer('addNewClicked', function () {
      this.send('resetModal');
    }),
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist '
      });
    },
    actions: {
      postTestimonial: function () {
        if (this.get('title') && this.get('detail') && this.get('text')) {
          var content = this.getProperties('title', 'detail', 'text');
          content.type = 'text';
          content.branding = 'testimony';
          var testimonial = this.get('store').createRecord('branding-testimonial', content);
          var self = this;
          testimonial.save().then(response => {
            window.Intercom('trackEvent', 'testimonial-posted');
            var content = {};
            response.set('content', content);
            response.set('content.id', response.get('id'));
            response.set('content.title', response.get('title'));
            response.set('content.text', response.get('text'));
            response.set('content.detail', response.get('detail'));
            response.set('content.type', response.get('type'));
            response.set('content.branding', response.get('branding'));
            self.get('testimonials').unshiftObject(response);
            window.swal({
              title: "Congrats!",
              text: "Your testimonial has been added. Do you want to add another?",
              icon: "success",
              buttons: {
                cancel: {
                  text: "Close",
                  visible: true,
                  closeModal: true,
                  className: "btn btn-secondary post_testimonial_swal_cancel"
                },
                confirm: {
                  text: "Add Another",
                  closeModal: true,
                  className: "btn btn-warning post_testimonial_swal_confirm"
                }
              }
            }).then(willAdd => {
              if (willAdd) {
                self.setProperties({
                  text: '',
                  title: '',
                  detail: ''
                });
              } else {
                self.send('resetModal');
                window.$('#postToTestimonial .close').click();
              }
            });
          });
        }

        if (!this.get('title')) {
          this.set('errors.title', 'This is a required field!');
        } else {
          this.set('errors.title', '');
        }

        if (!this.get('detail')) {
          this.set('errors.detail', 'This is a required field!');
        } else {
          this.set('errors.detail', '');
        }

        if (!this.get('text')) {
          this.set('errors.text', 'This is a required field!');
        } else {
          this.set('errors.text', '');
        }
      },
      resetModal() {
        this.setProperties({
          title: '',
          detail: '',
          text: ''
        });
        this.set('errors', {});
        window.tinymce.get('newBrandingTestimonialText').undoManager.clear();
      }
    }
  });
});