define('jobbio-backend/routes/company/dashboard', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service(),
    beforeModel: function (transition) {
      this._super(...arguments);
      if (!this.get('session.isAuthenticated')) {
        transition.abort();
      }
    },
    model: function (params) {
      if (params.workable == 'true') {
        this.toast.success('Workable details updated');
      } else if (params.workable == 'false') {
        this.toast.success('Workable details not updated');
      }
    },
    activate: function () {
      this.get('store').queryRecord('subscription', {}).then(response => {
        if (response.get('package.partner')) {
          if (!response.get('package_pop_up')) {
            var onEsc = null;
            var onClickOutside = null;
            if (response.get('package.lock')) {
              onEsc = false;
              onClickOutside = false;
            } else {
              onEsc = true;
              onClickOutside = true;
            }
            if (response.get('package.onboarding_info.primary_cta_text') != 'none') {
              var swalPopup = function () {
                window.swal({
                  title: response.get('package.onboarding_info.title'),
                  text: response.get('package.onboarding_info.text'),
                  buttons: { confirm: {
                      text: response.get('package.onboarding_info.primary_cta_text'),
                      closeModal: false,
                      className: "btn btn-warning dashboard_activate_swal_confirm"
                    } },
                  closeOnClickOutside: onClickOutside,
                  closeOnEsc: onEsc
                }).then(cta1 => {
                  if (cta1) {
                    swalPopup();
                    window.open(response.get('package.onboarding_info.primary_cta_url'), '_blank');
                  }
                });
              };
              swalPopup();
            } else {
              window.swal({
                title: response.get('package.onboarding_info.title'),
                text: response.get('package.onboarding_info.text'),
                buttons: false,
                closeOnClickOutside: onClickOutside,
                closeOnEsc: onEsc
              });
            }
          }
        }
      });
    }
  });
});