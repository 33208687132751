define('jobbio-backend/models/job-question', ['exports', 'jobbio-backend/models/question', 'ember-data'], function (exports, _question, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _question.default.extend({
    question_id: _emberData.default.attr(),
    job_id: _emberData.default.attr()
  });
});