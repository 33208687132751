define('jobbio-backend/components/application/application-tags-item', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    talent: null,
    selectedTags: null,
    tag: null,
    isSelectedTag: Ember.computed('selectedTags', function () {
      var selectedTags = this.get('selectedTags');
      for (var key in selectedTags) {
        var tag = selectedTags[key];
        if (this.get('tag.id') == tag.id) {
          return true;
        }
      }
      return false;
    }),
    actions: {
      clicked() {
        var isSelectedTag = this.get('isSelectedTag');
        var tag = this.get('tag');
        var selectedTags = this.get('selectedTags');

        var url = this.get('apiBaseUrl') + "/talent/" + this.get('talent.user.id') + "/tags/" + tag.id;
        var method = "POST";
        if (isSelectedTag) {
          method = "DELETE";
          for (var key in selectedTags) {
            var selectedTag = selectedTags[key];
            if (tag.id == selectedTag.id) {
              delete selectedTags[key];
              break;
            }
          }
        } else {
          selectedTags.pushObject(tag);
        }
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: method
          }).then(() => {
            this.set('tagUpdated', !this.get('tagUpdated'));
          });
        });
        let temp = this.get('selectedTags').slice(0);
        this.set('selectedTags', temp);
      }
    }
  });
});