define('jobbio-backend/components/common/dashboard-chat-list-item', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    didRender() {
      this._super(...arguments);
      if (this.get('message.read') == false) {
        this.set('message.read', true);
        this.get('message').save().then(() => {
          this.get('store').unloadAll('teammember');
          this.get('currentUser').load();
        });
        this.get('conversation').decrementProperty('unread');
      }
    }
  });
});