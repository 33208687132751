define('jobbio-backend/components/common/dashboard-conversation-list', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    currentUser: Ember.inject.service(),
    modelType: 'conversation',
    params: Ember.computed('cursor', 'type', function () {
      var params = this._super();
      params['type'] = this.get('type');
      return params;
    }),
    name: Ember.computed('type', function () {
      return this.get('type') == 'company_member' ? 'Team Members' : 'Talents';
    }),
    onUpdateConversationList: Ember.observer('updateConversationList', function () {
      this.set('objects', []);
      this.set('hasMore', true);
      this.set('loading', false);
      this.send('loadMore');
    }),
    didRender() {
      this._super(...arguments);
      if (this.get('type') == 'company_member') {
        // this.get('store').query('memberdetail', {}).then(response => {
        //   this.set('teamMembers', response);
        // });
      }
    }
  });
});