define('jobbio-backend/components/company/question-bank-details', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.get('store').unloadAll('question');
      this.get('store').query('question', {}).then(response => {
        var questions = response.toArray().reverse();
        this.set('questions', questions);
      });
    },
    actions: {
      deleteQuestion: function (question) {
        var self = this;
        window.swal({
          title: "Are you sure?",
          text: "Do you want to delete this question?",
          icon: "warning",
          buttons: {
            cancel: {
              text: "Close",
              visible: true,
              closeModal: true,
              className: "btn btn-secondary delete_question_swal_cancel"
            },
            confirm: {
              text: "Delete",
              closeModal: true,
              className: "btn btn-danger delete_question_swal_confirm"
            }
          },
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            question.deleteRecord();
            question.save();
            self.get('questions').removeObject(question);
          }
        });
      }
    }
  });
});