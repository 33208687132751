define('jobbio-backend/components/common/dashboard-conversations', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.get('store').query('memberdetail', {}).then(response => {
        this.set('teamMembers', response);
      });
    }
  });
});