define("jobbio-backend/components/application/new-applications", ["exports", "jobbio-backend/components/base-component"], function (exports, _baseComponent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    selectedJob: null,
    type: "pending"
  });
});