define('jobbio-backend/models/job-application', ['exports', 'jobbio-backend/models/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    job_id: _emberData.default.attr()

  });
});