define('jobbio-backend/components/base-component', ['exports', 'jobbio-backend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service(),
    cloudfront: _environment.default.APP.CLOUD_FRONT_HOST,
    apiBaseUrl: _environment.default.APP.API_HOST,
    homepage: _environment.default.APP.DOMAIN,
    actions: {
      showFrozenPopup(className) {
        window.swal({
          closeOnClickOutside: false,
          closeOnEsc: false,
          text: "Your account is now frozen. Please contact your relationship manager to unlock your account",
          icon: "warning",
          buttons: {
            cancel: {
              text: "Close",
              visible: true,
              closeModal: true,
              className: `btn btn-secondary ${className}_swal_cancel`
            }
          }
        });
      }
    }
  });
});