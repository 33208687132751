define("jobbio-backend/models/job-type", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr("string"),
        job: _emberData.default.belongsTo("job")
    });
});