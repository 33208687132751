define('jobbio-backend/components/company/content-bank-new', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        init() {
            this._super(...arguments);
        },
        actions: {
            openContentBankPopup: function () {
                this.set('isContentBankPopupOpened', !this.get('isContentBankPopupOpened'));
            }
        }
    });
});