define('jobbio-backend/components/common/team-member-list', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    didRender() {
      this._super(...arguments);
      if (!this.get('selectedTeamMemberID') && this.get('teamMembers').length > 0) {
        this.set('selectedTeamMemberID', parseInt(this.get('teamMembers')[0].id));
      }
    },
    selectedBackground: Ember.computed('property', function () {
      return new Ember.String.htmlSafe('background-color: #dddddd;');
    }),
    actions: {
      selectTeamMember(teamMember) {
        this.set('selectedTeamMemberID', teamMember.get('user.id'));
      }
    }
  });
});