define('jobbio-backend/serializers/tour', ['exports', 'jobbio-backend/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    extractId() {
      return 1;
    }
  });
});