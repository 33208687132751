define('jobbio-backend/components/employer-branding/post-branding', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    errors: null,
    options: null,
    onAddNewClicked: Ember.observer('addNewClicked', function () {
      this.send('close');
    }),
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        plugins: ['autolink lists charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist '
      });
      this.set('errors', {});
      if (this.get('type') == 'Gallery') {
        this.set('plugins', ['autolink lists link image charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table contextmenu directionality', 'emoticons template paste textcolor colorpicker textpattern imagetools']);
        this.set('toolbar1', 'bold | bullist | link ');
      }
    },
    actions: {
      postGallery: function () {
        var self = this;
        var formData = this.getProperties('title');
        if (!formData.title) formData.title = '';
        formData.type = "image";
        formData.branding = "gallery";
        if (this.get('image')) {
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            var options = {
              headers: {
                Authorization: headerValue
              },
              contentType: 'multipart/form-data',
              method: 'POST',
              fileKey: 'image',
              data: formData
            };
            this.get('image').upload(_environment.default.APP.API_HOST + '/jobs/content', options).then(response => {
              window.Intercom('trackEvent', 'gallery-posted');
              var data = {
                content: {
                  id: response.body.id
                }
              };
              var gallery = this.get('store').createRecord('branding-gallery', data);
              gallery.save().then(record => {
                self.get('posts').unshiftObject(record);
              });
              window.swal({
                title: "Congrats!",
                text: "Your image has been added. Do you want to add another?",
                icon: "success",
                buttons: {
                  cancel: {
                    text: "Close",
                    visible: true,
                    closeModal: true,
                    className: "btn btn-secondary post_gallery_swal_cancel"
                  },
                  confirm: {
                    text: "Add Another",
                    closeModal: true,
                    className: "btn btn-warning post_gallery_swal_confirm"
                  }
                }
              }).then(willAdd => {
                if (willAdd) {
                  self.setProperties({
                    title: '',
                    image: ''
                  });
                } else {
                  self.send('close');
                  window.$(`#postTo${self.get('type')} .close`).click();
                }
              });
            });
          });
        }
        if (this.get('image')) {
          this.set('errors.image', '');
        } else {
          this.set('errors.image', 'Your file cannot be empty');
        }
      },
      postContent: function () {
        this.set('errors.image', {});
        var self = this;
        var formData = {};
        formData.title = this.get('image_title');
        formData.text = this.get('image_text');
        formData.type = "image";
        formData.branding = "panel";

        if (this.get('image_title') && this.get('image') && this.get('image_text')) {
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            var options = {
              headers: {
                Authorization: headerValue
              },
              contentType: 'multipart/form-data',
              method: 'POST',
              fileKey: 'image',
              data: formData
            };
            this.get('image').upload(_environment.default.APP.API_HOST + '/jobs/content', options).then(response => {
              window.Intercom('trackEvent', 'panel-posted');
              var data = {
                content: {
                  id: response.body.id
                }
              };
              var content = this.get('store').createRecord('branding-content', data);
              content.save().then(record => {
                self.get('posts').unshiftObject(record);
              });
              window.swal({
                title: "Congrats!",
                text: "Your image has been added. Do you want to add another?",
                icon: "success",
                buttons: {
                  cancel: {
                    text: "Close",
                    visible: true,
                    closeModal: true,
                    className: "btn btn-secondary post_content_swal_cancel"
                  },
                  confirm: {
                    text: "Add Another",
                    closeModal: true,
                    className: "btn btn-warning post_content_swal_confirm"
                  }
                }
              }).then(willAdd => {
                if (willAdd) {
                  self.setProperties({
                    image_title: '',
                    image_text: '',
                    image: '',
                    video_title: '',
                    video_text: '',
                    video: ''
                  });
                } else {
                  self.send('close');
                  window.$(`#postTo${self.get('type')} .close`).click();
                }
              });
            });
          });
        }
        if (this.get('image_title')) {
          this.set('errors.image.title', '');
        } else {
          this.set('errors.image.title', 'This field cannot be empty');
        }
        if (this.get('image')) {
          this.set('errors.image.image', '');
        } else {
          this.set('errors.image.image', 'Your file cannot be empty');
        }
        if (this.get('image_text')) {
          this.set('errors.image.text', '');
        } else {
          this.set('errors.image.text', 'This field cannot be empty');
        }
      },
      postVideo: function () {
        this.set('errors.video', {});
        var self = this;
        var content = {};
        content.title = this.get('video_title');
        content.video = this.get('video');
        content.text = this.get('video_text');
        content.type = "video";
        content.branding = "panel";
        var validYoutubeURL = /^(https\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(content.video);
        var validVimeoURL = /^(https\:\/\/)?(player\.vimeo\.com)\/.+$/.test(content.video);
        if (this.get('video_title') && this.get('video') && this.get('video_text') && (validYoutubeURL || validVimeoURL)) {
          var video = this.get('store').createRecord('branding-panel', content);
          video.save().then(response => {
            window.Intercom('trackEvent', 'video-posted');
            var content = {};
            response.set('content', content);
            response.set('content.id', response.get('id'));
            response.set('content.title', response.get('title'));
            response.set('content.text', response.get('text'));
            response.set('content.video', response.get('video'));
            response.set('content.type', response.get('type'));
            response.set('content.branding', response.get('branding'));
            self.get('posts').unshiftObject(response);
            window.swal({
              title: "Congrats!",
              text: "Your video has been added. Do you want to add another?",
              icon: "success",
              buttons: {
                cancel: {
                  text: "Close",
                  visible: true,
                  closeModal: true,
                  className: "btn btn-secondary post_video_swal_cancel"
                },
                confirm: {
                  text: "Add Another",
                  closeModal: true,
                  className: "btn btn-warning post_video_swal_confirm"
                }
              }
            }).then(willAdd => {
              if (willAdd) {
                self.setProperties({
                  image_title: '',
                  image_text: '',
                  image: '',
                  video_title: '',
                  video_text: '',
                  video: ''
                });
              } else {
                self.send('close');
                window.$(`#postTo${self.get('type')} .close`).click();
              }
            });
          });
        }
        if (this.get('video_title')) {
          this.set('errors.video.title', '');
        } else {
          this.set('errors.video.title', 'This field cannot be empty');
        }
        if (this.get('video')) {
          if (!validYoutubeURL && !validVimeoURL) {
            this.set('errors.video.url', 'Please enter a valid URL');
          } else {
            this.set('errors.video.url', '');
          }
        } else {
          this.set('errors.video.url', 'This field cannot be empty');
        }
        if (this.get('video_text')) {
          this.set('errors.video.text', '');
        } else {
          this.set('errors.video.text', 'This field cannot be empty');
        }
      },
      close() {
        if (this.get('type') == 'Gallery') {
          this.setProperties({
            title: '',
            image: ''
          });
          this.set('errors', {});
        } else {
          this.setProperties({
            image_title: '',
            image_text: '',
            image: '',
            video_title: '',
            video_text: '',
            video: ''
          });
          this.set('errors', {
            'image': {},
            'video': {}
          });
          window.tinymce.get('newImageBrandingContentText').undoManager.clear();
          window.tinymce.get('newVideoBrandingContentText').undoManager.clear();
        }
      },
      uploadImage: function (file) {
        this.set('image', file);
      }
    }
  });
});