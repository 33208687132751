define('jobbio-backend/routes/company/post-job', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return Ember.RSVP.hash({
        form: this.store.queryRecord('form', 1) // 1 is meaningless
        //companies: this.store.findAll('company')
      });
    },
    afterModel() {
      window.$('.main-panel').scrollTop(0);
    }
  });
});