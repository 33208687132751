define('jobbio-backend/components/application/application-experience', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    date_to: Ember.computed(function () {
      if (this.get('experience.current') || this.get('experience.date_to') == null) {
        return "Present";
      } else {
        return window.moment(this.get('experience.date_to')).format('MMM, YYYY');
      }
    })
  });
});