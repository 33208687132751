define('jobbio-backend/components/employer-branding/content', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        init() {
            this._super(...arguments);
        }
    });
});