define('jobbio-backend/components/application/bulk-action-popup', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    saving: false,
    onBulkActionsPopupOpened: Ember.observer('bulkActionsPopupOpened', function () {
      this.set('messageText', '');
    }),
    init() {
      this._super(...arguments);
    },
    actions: {
      message() {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', `application_detail_${this.get('bulkAction')}`);
        } else {
          if (this.get('messageText')) {
            this.set('saving', true);
            var self = this;
            var bulkListLength = this.get('bulkList').length;
            var data = {};
            var key = 1;
            this.get('bulkList').forEach(function (application) {
              data[key] = {
                participants: [application.get('talent.user.id')],
                text: self.get('messageText')
              };
              key++;
            });
            this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
              window.$.ajax({
                url: `${this.get('apiBaseUrl')}/conversations/bulk`,
                headers: {
                  Authorization: headerValue
                },
                type: 'POST',
                data: JSON.stringify(data),
                contentType: 'application/json;charset=utf-8',
                dataType: 'json'
              }).done(function () {
                self.toast.success(`A message was sent to ${bulkListLength} talents`);
                window.$(`#bulkActionsPopup_${self.get('id')}`).modal('hide');
                self.set('bulkList', []);
                self.set('saving', false);
              }).fail(function () {
                self.toast.error('Something went wrong');
                self.set('saving', false);
              });
            });
          }
        }
      },
      bulkAction() {
        var status = this.get('bulkAction') == 'shortlist' ? 'shortlisted' : this.get('bulkAction') == 'pass' ? 'passed' : this.get('bulkAction') == 'revert' ? 'submitted' : null;
        if (this.get('currentUser.teammember.company.status') == 'frozen' && status != 'passed') {
          this.send('showFrozenPopup', `application_detail_${this.get('bulkAction')}`);
        } else {
          this.set('saving', true);
          var self = this;
          var applicationsIDs = this.get('bulkList').map(application => parseInt(application.id, 10));
          var bulkListLength = this.get('bulkList').length;
          var selectedApplicationType = this.get('bulkList.firstObject.status');
          var data = {
            applications: applicationsIDs,
            status: status
          };
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: `${this.get('apiBaseUrl')}/jobs/${this.get('job.id')}/applications/bulk`,
              headers: {
                Authorization: headerValue
              },
              type: 'PATCH',
              data: JSON.stringify(data),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).done(function () {
              self.toast.success(`${bulkListLength} Talents ${self.get('bulkAction')}ed`);
              self.get('updateApplication')();
              window.$(`#bulkActionsPopup_${self.get('id')}`).modal('hide');
              self.get('bulkList').forEach(function (application) {
                application.set('status', status);
              });
              if (status == 'submitted') {
                self.set('job.passed', self.get('job.passed') - bulkListLength);
                self.set('job.new_applications', self.get('job.new_applications') + bulkListLength);
                self.set('currentUser.teammember.company.new_applications', self.get('currentUser.teammember.company.new_applications') + bulkListLength);
              } else {
                if (selectedApplicationType == 'pending') {
                  if (self.get('job.pending') > 0) {
                    self.set('job.pending', self.get('job.pending') - bulkListLength);
                  }
                } else {
                  if (self.get('job.new_applications') > 0) {
                    self.set('job.new_applications', self.get('job.new_applications') - bulkListLength);
                  }
                }
                self.set(`job.${status}`, self.get(`job.${status}`) + bulkListLength);
                self.set('currentUser.teammember.company.new_applications', self.get('currentUser.teammember.company.new_applications') - bulkListLength);
                if (status == 'shortlisted') {
                  self.set('currentUser.teammember.company.talent_pool_no', self.get('currentUser.teammember.company.talent_pool_no') + bulkListLength);
                }
              }
              self.set('saving', false);
            }).fail(function () {
              self.toast.error('Something went wrong');
              self.set('saving', false);
            });
          });
        }
      },
      requestBio() {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_request');
        } else {
          this.set('saving', true);
          var self = this;
          var applicationsIDs = this.get('bulkList').map(application => parseInt(application.id, 10));
          var data = {
            application_ids: applicationsIDs
          };
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: `${this.get('apiBaseUrl')}/jobs/${this.get('job.id')}/applications/bio/bulk`,
              headers: {
                Authorization: headerValue
              },
              type: 'POST',
              data: JSON.stringify(data),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).done(function () {
              self.toast.success(`Bio requested`);
              window.$(`#bulkActionsPopup_${self.get('id')}`).modal('hide');
              self.get('bulkList').forEach(function (application) {
                application.set('bio_requested', true);
              });
              self.set('bulkList', []);
              self.set('saving', false);
            }).fail(function () {
              self.toast.error('Something went wrong');
              self.set('saving', false);
            });
          });
        }
      }
    }
  });
});