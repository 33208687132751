define('jobbio-backend/components/settings/interview-stages', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    stages: null,
    init() {
      this._super(...arguments);
      this.set('stages', []);
      this.get('store').query('stage-settings', {}).then(response => {
        this.set('stages', response.toArray());
      });
    },
    actions: {
      deleteStage: function (stage) {
        stage.deleteRecord();
        stage.save().then(() => {
          this.toast.success('Interview stages updated');
        });
        this.get('stages').removeObject(stage);
      },
      updateStage: function (stage) {
        stage.save().then(() => {
          this.toast.success('Interview stages updated');
        });
      },
      addStage: function () {
        if (this.get('newStageName') != '' && this.get('newStageName')) {
          if (this.get('newStageName').length < 31) {
            this.get('store').createRecord('stage-settings', {
              name: this.get('newStageName')
            }).save().then(record => {
              this.get('stages').pushObject(record);
              this.toast.success('Interview stages updated');
            });
            this.set('newStageNameError', '');
            this.set('newStageName', '');
          } else {
            this.set('newStageNameError', 'Ensure this field has no more than 30 characters.');
          }
        } else {
          this.set('newStageNameError', 'This field cannot be empty.');
        }
      }
    }
  });
});