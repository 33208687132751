define('jobbio-backend/components/common/dashboard-conversation-list-item', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    currentUser: Ember.inject.service('current-user'),
    hasUnread: Ember.computed('conversation.unread', function () {
      return this.get('conversation.unread') > 0;
    }),
    title: Ember.computed('conversation.id', function () {
      var currentUserId = this.get('session.data.authenticated.company_member.user.id');
      return this.get('conversation').title(currentUserId);
    }),
    image: Ember.computed('conversation', function () {
      if (this.get('conversation.participants').length > 2) {
        return this.get('currentUser.teammember.company.image_logo');
      } else {
        return this.get('conversation.participants')[1].avatar;
      }
    }),
    role: Ember.computed('conversation.id', function () {
      if (this.get('conversation.last_message') == null) {
        return "";
      }
      var last_message = this.get('conversation.last_message').substring(0, 30);
      if (last_message.length == 30) {
        last_message += "...";
      }
    })
  });
});