define('jobbio-backend/routes/workable-callback', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'jobbio-backend/config/environment'], function (exports, _authenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    model: function (params) {
      var data = { 'token': params.code };
      var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/workable';
      var self = this;
      this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
        window.$.ajax({
          url: url,
          headers: {
            Authorization: headerValue
          },
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        }).then(() => {
          self.transitionTo('company.dashboard', { queryParams: { workable: true } });
        }, () => {
          self.transitionTo('company.dashboard', { queryParams: { workable: false } });
        });
      });
    }
  });
});