define('jobbio-backend/components/active-pagination-component', ['exports', 'jobbio-backend/components/pagination-component', 'ember-concurrency'], function (exports, _paginationComponent, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    resetAllowed: true,
    isLoading: Ember.computed('loading', 'resetSearch.isRunning', function () {
      return this.get('loading') | this.get('resetSearch.isRunning');
    }),
    didRender() {
      if (this.get('autoLoad') && this.get('hasLoadedInitialy') == false) {
        this.send('loadMore');
      }
    },
    searchTermChanged: Ember.observer('searchTerm', function () {
      if (this.get('resetAllowed')) {
        this.set('objects', []);
        this.get('resetSearch').perform();
      }
    }),
    resetSearch: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      this.set('cursor', '');
      this.setProperties({
        hasLoadedInitialy: false,
        loading: false,
        hasMore: true,
        objects: []
      });
      this.send('loadMore');
    }).restartable()
  });
});