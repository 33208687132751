define('jobbio-backend/models/memberdetail', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const MemberValidations = (0, _emberCpValidations.buildValidations)({
    first_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    })],
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    country_code: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    })],
    role: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!'
    })],
    password: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'This is a required field!'
      }), (0, _emberCpValidations.validator)('length', {
        min: 6,
        message: 'Password is too short (minimum is 6 characters)'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^(?=.*[0-9])(?=.*[a-zA-Z])([\S]+)$/,
        message: 'Password must include at least one letter and a number'
      })]
    }
  });

  exports.default = _emberData.default.Model.extend(MemberValidations, {
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    country_code: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    user: _emberData.default.attr(),
    company: _emberData.default.attr(),
    created_at: _emberData.default.attr('date'),
    jobs_posted_no: _emberData.default.attr('number'),
    new_applications_no: _emberData.default.attr('number'),
    passed_applications_no: _emberData.default.attr('number'),
    shortlisted_applications_no: _emberData.default.attr('number'),
    new_messages_no: _emberData.default.attr('number'),
    shared_applicants_no: _emberData.default.attr('number'),
    received_applicants_no: _emberData.default.attr('number'),
    conversations: _emberData.default.attr(),
    unread: _emberData.default.attr()
  });
});