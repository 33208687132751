define('jobbio-backend/components/company/content-bank-details', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    options: null,
    updating: false,
    onTabChanged: Ember.observer('selectedTab', function () {
      if (this.get('selectedTab') != 'content_bank') {
        this.get('store').unloadAll('job-content');
        this.get('store').query('job-content', {}).then(response => {
          var content = response.toArray().reverse();
          this.set('content', content);
        });
      }
    }),
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist | link '
      });
      this.get('store').unloadAll('job-content');
      this.get('store').query('job-content', {}).then(response => {
        var content = response.toArray().reverse();
        this.set('content', content);
      });
    },
    actions: {
      updateContent: function (content) {
        if (content.get('title') && content.get('text')) {
          content.save().then(() => {
            content.set('isUpdated', false);
            this.toast.success('Content updated');
          });
        }
        if (content.get('title')) {
          content.set('errors.title', '');
        } else {
          content.set('errors.title', 'This field cannot be empty');
        }
        if (content.get('text')) {
          content.set('errors.text', '');
        } else {
          content.set('errors.text', 'This field cannot be empty');
        }
      },
      deleteContent: function (content) {
        var self = this;
        window.swal({
          title: "Are you sure?",
          text: "Do you want to delete this content?",
          icon: "warning",
          buttons: {
            cancel: {
              text: "Close",
              visible: true,
              closeModal: true,
              className: "btn btn-secondary delete_content_bank_swal_cancel"
            },
            confirm: {
              text: "Delete",
              closeModal: true,
              className: "btn btn-danger delete_content_bank_swal_confirm"
            }
          },
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            content.deleteRecord();
            content.save();
            self.get('content').removeObject(content);
          }
        });
      },
      updateTitle(content) {
        content.set('isUpdated', true);
      },
      updateText(content, text) {
        content.set('text', text);
        content.set('isUpdated', true);
      }
    }
  });
});