define('jobbio-backend/components/settings/collect-widget', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);
      this.set('value', `
    <!-- add within your head tag -->
    <link href="https://widgets.jobbio.com/collect/css/styles.css" rel="stylesheet" />

    <!-- add within your body tag -->
    <div class="jobbio-hfw show-right-side">
      <div class="jobbio-hfw-section">
        <!-- starts : buttons -->
        <div class="jobbio-hfw-buttons">
          <!-- starts : subscribe -->
          <a href="https://jobbio.com/talent/register?channel=` + this.get('currentUser.teammember.company.slug') + `&source=collect_` + this.get('currentUser.teammember.company.slug') + `" class="jobbio-hfw-subscribe">Send Me Job Alerts</a>
          <!-- end : subscribe -->
          <!-- starts : submit -->
          <a href="https://jobbio.com/talent/register?channel=` + this.get('currentUser.teammember.company.slug') + `&source=collect_pool_` + this.get('currentUser.teammember.company.slug') + `" class="jobbio-hfw-submit">Submit &amp; Send CV</a>
          <!-- end : submit -->
          <!-- starts : view open roles -->
          <a href="https://jobbio.com/` + this.get('currentUser.teammember.company.slug') + `" class="jobbio-hfw-view-roles">View Our Open Roles</a>
          <!-- end : view open roles -->
        </div>
        <!-- end : buttons -->
        <!-- starts : default hiring button -->
        <a class="jobbio-hfw-hire" href="javascript:jobbio_HFW.showCards();">We Are Hiring!</a>
        <!-- end : default hiring button -->
      </div>
    </div>
    <audio id="jobbioButtonAudio">
      <source src="https://widgets.jobbio.com/collect/sounds/jingle.mp3" type="audio/mpeg">
    </audio>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
    <script src="https://widgets.jobbio.com/collect/js/widget.js"></script>
    <script type="text/javascript">
      window.$( document ).ready(function() {
          jobbio_HFW.load('');
      });
    </script>
    `);
    }
  });
});