define('jobbio-backend/routes/company/settings', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    model() {
      return this.get('store').findRecord('channel', this.get('currentUser.teammember.company.slug')).then(response => {
        return { 'channel_id': response.id };
      });
    },
    afterModel() {
      // Initialize the currentUser
      this.get('store').unloadAll('teammember');
      this.get('currentUser').load();
      //Initialize the notification
      this.get('store').unloadAll('notification');
      this.get('store').queryRecord('notification', {});

      window.$('.main-panel').scrollTop(0);
    }
  });
});