define('jobbio-backend/components/common/job-edit-questions', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment', 'ember-data'], function (exports, _baseComponent, _environment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    questions: Ember.computed('job.questions', 'selectedTab', function () {
      var jobQuestions = this.get('job.questions');
      var jobQuestionIDs = [];
      jobQuestions.forEach(function (question) {
        jobQuestionIDs.push(parseInt(question.id));
      });
      var questionsToShow = [];
      return _emberData.default.PromiseArray.create({
        promise: this.get('store').query('question', {}).then(response => {
          response.forEach(function (question) {
            if (!jobQuestionIDs.includes(parseInt(question.id))) {
              questionsToShow.pushObject(question);
            }
          });
          return questionsToShow;
        })
      });
    }),
    actions: {
      addQuestion: function (question) {
        var data = {
          question_id: question.id,
          job_id: this.get('job.id')
        };
        this.get('store').createRecord('job-question', data).save();
        this.get('job.questions').pushObject(question);
        this.get('questions').removeObject(question);
      },
      removeQuestion: function (question) {
        var url = _environment.default.APP.API_HOST + '/jobs/' + this.get('job.id') + '/questions/' + question.id;
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          });
        });
        this.get('job.questions').removeObject(question);
        this.get('questions').pushObject(question);
      }
    }
  });
});