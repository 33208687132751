define('jobbio-backend/components/company/location-popup', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        city_label: 'City',
        state_label: 'State/Region',
        code_label: 'Postcode',
        errors: null,
        inactive: true,
        isNewJob: Ember.observer('job.id', function () {
            this.set('inputted_country', this.get('job.location.country'));
            this.set('inputted_country_short', this.get('job.location.country_short'));
            this.set('inputted_city', this.get('job.location.city'));
            this.set('inputted_state', this.get('job.location.state'));
            this.set('inputted_address', this.get('job.location.address'));
            this.set('inputted_post_code', this.get('job.location.post_code'));
            this.set('errors', {});
        }),
        isJobCleared: Ember.observer('jobCleared', function () {
            this.set('inputted_country', '');
            this.set('inputted_country_short', '');
            this.set('inputted_city', '');
            this.set('inputted_state', '');
            this.set('inputted_address', '');
            this.set('inputted_post_code', '');
            this.set('errors', {});
            window.$('#addLocationPopupSelect').val(window.$("#addLocationPopupSelect option:first").val());
        }),
        init() {
            this._super(...arguments);
            this.set('errors', {});
        },
        actions: {
            selectCountry(country) {
                this.set('inputted_country', country);
                switch (country) {
                    case 'Ireland':
                        this.set('city_label', 'City/Town');
                        this.set('state_label', 'County');
                        this.set('code_label', 'Eircode');
                        this.set('inputted_country_short', 'IE');
                        break;
                    case 'United States':
                        this.set('city_label', 'City/Town');
                        this.set('state_label', 'State');
                        this.set('code_label', 'ZIP');
                        this.set('inputted_country_short', 'US');
                        break;
                    case 'United Kingdom':
                        this.set('city_label', 'Locality');
                        this.set('state_label', 'City');
                        this.set('code_label', 'Postcode');
                        this.set('inputted_country_short', 'UK');
                        break;
                    case 'Canada':
                        this.set('city_label', 'Municipality');
                        this.set('state_label', 'State');
                        this.set('code_label', 'Postal Code');
                        this.set('inputted_country_short', 'CA');
                        break;
                    default:
                        this.set('city_label', 'City');
                        this.set('state_label', 'State/Region');
                        this.set('code_label', 'Postcode');
                        this.set('inputted_country_short', country);
                        break;
                }
            },
            setAddress() {
                if (this.get('inputted_country') && this.get('inputted_address') && this.get('inputted_city') && this.get('inputted_state') && this.get('inputted_post_code')) {
                    if (this.get('id') == 'add') {
                        var full_address = this.get('inputted_address') + ', ' + this.get('inputted_city') + ', ' + this.get('inputted_state') + ', ' + this.get('inputted_country') + ', ' + this.get('inputted_post_code');
                        this.set('full_address', full_address);
                        this.set('country', this.get('inputted_country'));
                        this.set('country_short', this.get('inputted_country_short'));
                        this.set('address', this.get('inputted_address'));
                        this.set('city', this.get('inputted_city'));
                        this.set('state', this.get('inputted_state'));
                        this.set('post_code', this.get('inputted_post_code'));
                        this.set('backup_country', this.get('inputted_country'));
                        this.set('backup_country_short', this.get('inputted_country_short'));
                        this.set('backup_city', this.get('inputted_city'));
                        this.set('backup_state', this.get('inputted_state'));
                        this.set('backup_post_code', this.get('inputted_post_code'));
                        this.set('backup_address', this.get('inputted_address'));
                        this.set('inactive', true);
                        this.set('gAddress', '');
                    } else {
                        this.set('job.location.country', this.get('inputted_country'));
                        this.set('job.location.country_short', this.get('inputted_country_short'));
                        this.set('job.location.address', this.get('inputted_address'));
                        this.set('job.location.city', this.get('inputted_city'));
                        this.set('job.location.state', this.get('inputted_state'));
                        this.set('job.location.post_code', this.get('inputted_post_code'));
                        this.set('job.location.updated', true);
                        this.set('backup_country', this.get('inputted_country'));
                        this.set('backup_country_short', this.get('inputted_country_short'));
                        this.set('backup_city', this.get('inputted_city'));
                        this.set('backup_state', this.get('inputted_state'));
                        this.set('backup_post_code', this.get('inputted_post_code'));
                        this.set('backup_address', this.get('inputted_address'));
                        this.set('inactive', true);
                        this.set('gAddress', '');
                    }
                    window.$("#" + this.get('id') + "LocationPopup").click();
                }
                if (!this.get('inputted_country')) {
                    this.set('errors.inputted_country', 'This is a required filed');
                } else {
                    this.set('errors.inputted_country', '');
                }
                if (!this.get('inputted_address')) {
                    this.set('errors.inputted_address', 'This is a required filed');
                } else {
                    this.set('errors.inputted_address', '');
                }
                if (!this.get('inputted_city')) {
                    this.set('errors.inputted_city', 'This is a required filed');
                } else {
                    this.set('errors.inputted_city', '');
                }
                if (!this.get('inputted_state')) {
                    this.set('errors.inputted_state', 'This is a required filed');
                } else {
                    this.set('errors.inputted_state', '');
                }
                if (!this.get('inputted_post_code')) {
                    this.set('errors.inputted_post_code', 'This is a required filed');
                } else {
                    this.set('errors.inputted_post_code', '');
                }
            },
            close() {
                if (this.get('id') == 'edit') {
                    this.set('inputted_country', this.get('job.location.country'));
                    this.set('inputted_country_short', this.get('job.location.country_short'));
                    this.set('inputted_address', this.get('job.location.address'));
                    this.set('inputted_city', this.get('job.location.city'));
                    this.set('inputted_state', this.get('job.location.state'));
                    this.set('inputted_post_code', this.get('job.location.post_code'));
                    window.$('#editLocationPopupSelect').val(this.get('job.location.country'));
                } else {
                    if (!this.get('full_address')) {
                        this.set('inputted_country', '');
                        this.set('inputted_country_short', '');
                        this.set('inputted_address', '');
                        this.set('inputted_city', '');
                        this.set('inputted_state', '');
                        this.set('inputted_post_code', '');
                        window.$('#addLocationPopupSelect').val(window.$("#addLocationPopupSelect option:first").val());
                    } else {
                        this.set('inputted_country', this.get('backup_country'));
                        this.set('inputted_country_short', this.get('backup_country_short'));
                        this.set('inputted_address', this.get('backup_address'));
                        this.set('inputted_city', this.get('backup_city'));
                        this.set('inputted_state', this.get('backup_state'));
                        this.set('inputted_post_code', this.get('backup_post_code'));
                        window.$('#editLocationPopupSelect').val(this.get('backup_country'));
                        window.$('#addLocationPopupSelect').val(this.get('backup_country'));
                    }
                }
                window.$("#" + this.get('id') + "LocationPopup").click();
                this.set('gAddress', '');
                this.set('inactive', true);
                this.set('errors', {});
            },
            didUpdatePlace: function (place) {
                var country, country_short, city, state, postal_code;
                var add_ress = place.place.formatted_address;
                this.set('inputted_address', add_ress.substring(0, add_ress.indexOf(',')));
                if (!place.lng) {
                    this.set('longitude', null);
                } else {
                    this.set('longitude', place.lng.toFixed(8));
                }
                if (!place.lat) {
                    this.set('longitude', null);
                } else {
                    this.set('latitude', place.lat.toFixed(8));
                }

                place.place.address_components.forEach(function (address_component) {
                    var type = address_component.types[0];
                    if (type == "country") {
                        country = address_component.long_name;
                        country_short = address_component.short_name;
                    }
                    if (type == "locality" || type == "postal_town") {
                        city = address_component.long_name;
                    } else if (type == "administrative_area_level_1") {
                        state = address_component.long_name;
                    }
                    if (type == "postal_code") {
                        postal_code = address_component.long_name;
                    }
                });

                window.$('#editLocationPopupSelect').val(country);
                window.$('#addLocationPopupSelect').val(country);
                this.set('inputted_country', country);
                this.set('inputted_country_short', country_short);
                this.set('inputted_city', city);
                this.set('inputted_state', state);
                this.set('inputted_post_code', postal_code);
                this.set('inactive', false);
            },
            invalidUserSelection: function () {}
        }
    });
});