define('jobbio-backend/components/pagination-component', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseComponent.default.extend({
		store: Ember.inject.service(),
		infinity: Ember.inject.service(),
		objects: null,
		modelType: '',
		searchTerm: '',
		cursor: '',
		page_size: 20,
		hasLoadedInitialy: false,
		hasMore: true,
		autoLoad: true,
		resetAllowed: true,
		loading: false,
		init: function () {
			this._super();
			this.set("objects", []);
		},
		didRender() {
			if (this.get('autoLoad') && this.get('hasLoadedInitialy') == false) {
				this.send('loadMore');
			}
		},
		params: Ember.computed('searchTerm', 'cursor', 'page_size', function () {
			return {
				search: this.get('searchTerm'),
				cursor: this.get('cursor'),
				page_size: this.get('page_size')
			};
		}),
		isLoading: Ember.computed('loading', function () {
			return this.get('loading');
		}),
		actions: {
			loadMore() {
				if (this.get('loading') || this.get('hasMore') == false) {
					return;
				}
				var params = this.get('params');
				this.set('loading', true);
				this.get('store').query(this.get('modelType'), params).then(response => {
					this.set('loading', false);
					this.set('hasLoadedInitialy', true);
					if (response == null) {
						this.set('hasMore', false);
						return;
					}
					var self = this;
					response.forEach(function (object) {
						self.get('objects').pushObject(object);
					});
					Ember.get(this, 'infinity').infinityLoad(this.get('objects'));
					var meta = response.get('meta');
					this.set('cursor', meta.next);
					if (meta.next == null) {
						this.set('hasMore', false);
					}
					this.send('loaded');
				});
			},
			// DO NOT REMOVE THIS
			// it functions as a life cyclehook for components that extend this class
			// to hook into
			loaded() {}
		},
		canLoadMore: Ember.computed('hasMore', 'hasLoadedInitialy', function () {
			return this.get('hasMore') && this.get('hasLoadedInitialy') == true;
		})
	});
});