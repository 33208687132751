define('jobbio-backend/components/application/application-filter', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    showApplicationTypeFilter: Ember.computed('searchApplications', function () {
      return this.get('searchApplications') == true;
    }),
    showJobStageFilter: Ember.computed('searchApplications', function () {
      return this.get('searchApplications') == true;
    }),
    showTagFilter: true
  });
});