define('jobbio-backend/components/application/application-answers', ['exports', 'jobbio-backend/components/loader-component'], function (exports, _loaderComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _loaderComponent.default.extend({
    modelType: 'answer',
    answers: Ember.computed.alias('objects'),
    talent: null,
    application: null,
    autoLoad: true,
    reloadOnUpdateAttrs: true,
    params(self) {
      var params = {
        talent_id: self.get('talent.user.id'),
        application_id: self.get('application.id')
      };
      return params;
    }
  });
});