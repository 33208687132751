define('jobbio-backend/controllers/company/team-collaboration', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        store: Ember.inject.service(),
        currentUser: Ember.inject.service(),
        teamMembers: null,
        init() {
            this._super(...arguments);
            var teamMembers = [];
            this.get('store').query('memberdetail', {}).then(members => {
                members.forEach(member => {
                    teamMembers.pushObject(member);
                });
                this.set('teamMembers', teamMembers);
            });
        }
    });
});