define("jobbio-backend/models/gdpr-settings", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    ats_action: _emberData.default.attr("string"),
    ats_action_threshold: _emberData.default.attr("string"),
    ats_action_talent_pool: _emberData.default.attr("string"),
    ats_talent_pool_threshold: _emberData.default.attr("string"),
    ats_action_following: _emberData.default.attr("string"),
    ats_following_threshold: _emberData.default.attr("string"),
    answers_top: _emberData.default.attr("boolean")
  });
});