define('jobbio-backend/serializers/company-member', ['exports', 'jobbio-backend/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    extractId(modelClass, resourceHash) {
      var id = resourceHash['user']['id'];
      return id;
    }
  });
});