define("jobbio-backend/models/branding-testimonial", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr("string"),
        detail: _emberData.default.attr("string"),
        text: _emberData.default.attr("string"),
        type: _emberData.default.attr("string"),
        branding: _emberData.default.attr("string"),
        content: _emberData.default.attr(),
        errors: _emberData.default.attr(),
        created_at: _emberData.default.attr("date"),
        updated_at: _emberData.default.attr("date")
    });
});