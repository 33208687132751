define('jobbio-backend/components/company/post-question', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    saving: false,
    errors: null,
    content: null,
    onQuestionPopupOpened: Ember.observer('isQuestionPopupOpened', function () {
      this.send('clearQuestion');
    }),
    init() {
      this._super(...arguments);
      this.set('errors', null);
      this.set('content', [{
        option: ''
      }]);
    },
    didRender() {
      // var self = this;
      // Prevent '-' & '+' inputs in the From & To fields
      window.$('input.from-to-field').on('keypress keyup blur', function (event) {
        if (event.which == 43 || event.which == 45) {
          event.preventDefault();
        }
      });
      // var id = "replace_me-v2_recorder_question";
      // ziggeoApp.on("ready", function() {
      //   var recorder = new ZiggeoApi.V2.Recorder({
      //     element: document.getElementById(id),
      //     attrs: {
      //       width: 480,
      //       height: 320,
      //       theme: "modern",
      //       themecolor: "red",
      //       allowupload: false,
      //       title: "question",
      //       manualsubmit: true,
      //     }
      //   });
      //   recorder.activate();
      // });
      // var element = document.getElementById(id);
      // var embedding = ZiggeoApi.V2.Recorder.findByElement(element);
      // embedding.on("manually_submitted", function () {
      //     var token = embedding.get('video')
      //     self.send('postVideo', token);
      // });
    },
    actions: {
      post_question: function () {
        var errors = {};
        var question = this.getProperties('text', 'type', 'value_from', 'value_to');
        var new_question = this.get('store').createRecord('question', question);
        new_question.validate().then(({ validations }) => {
          validations.get('errors').forEach(error => {
            var field = error.attribute;
            errors[field] = error.message;
          });
          this.set('errors', errors);
          if (validations.get('isValid')) {
            this.set('saving', true);
            new_question.save().then(response => {
              window.Intercom('trackEvent', 'question-post');
              if (this.get('view') == 'content-bank') {
                this.get('questions').unshiftObject(response);
              } else if (this.get('view') == 'post-job') {
                this.set('questionAdded', !this.get('questionAdded'));
              }
              var text = "";
              if (this.get('view') == "post-job") {
                text = "Your question has been added to your question bank and this job.";
              } else if (this.get("view") == "content-bank") {
                text = "Your question has been added to your question bank.";
              }
              window.swal({
                title: "Congrats!",
                text: text,
                icon: "success",
                buttons: {
                  cancel: {
                    text: "Close",
                    visible: true,
                    closeModal: true,
                    className: "btn btn-secondary post_question_swal_cancel"
                  },
                  confirm: {
                    text: "Add Another Question",
                    closeModal: true,
                    className: "btn btn-warning post_question_swal_confirm"
                  }
                }
              }).then(willAdd => {
                if (!willAdd) {
                  window.$('#addNewQuestion .close').click();
                }
                this.set('saving', false);
                this.send('clearQuestion');
              });
            });
          }
        });
      },
      postVideo: function (token) {
        var data = {};
        data.text = this.get('text');
        data.video_token = token;
        data.type = "video";
        var video = this.get('store').createRecord('question', data);
        video.save().then(response => {
          this.get('questions').unshiftObject(response);
          window.swal({
            title: "Congrats!",
            text: "Your question has been added to your question bank.",
            icon: "success",
            buttons: {
              cancel: {
                text: "Close",
                visible: true,
                closeModal: true,
                className: "btn btn-secondary post_question_swal_cancel"
              },
              confirm: {
                text: "Add Another Question",
                closeModal: true,
                className: "btn btn-warning post_question_swal_confirm"
              }
            }
          }).then(willAdd => {
            if (!willAdd) {
              window.$('#addNewQuestion .close').click();
            }
            this.set('saving', false);
            this.send('clearQuestion');
          });
        });
      },
      selectType: function (type) {
        if (type) {
          window.$(".display-selections .form-group").not("." + type).hide();
          window.$("." + type).show();
        } else {
          window.$(".display-selections .form-group").hide();
        }
        this.set('type', type);
        this.setProperties({
          value_from: null,
          value_to: null,
          content: [{ option: '' }],
          errors: {}
        });
      },
      clearQuestion() {
        this.setProperties({
          text: '',
          type: '',
          value_from: null,
          value_to: null,
          content: [{ option: '' }],
          errors: {}
        });
        window.$(".choose-form-field").val(window.$(".choose-form-field option:first").val());
        window.$(".display-selections .form-group").hide();
      },
      close() {
        window.$('#addNewQuestion .close').click();
        this.send('clearQuestion');
      }
    }
  });
});