define('jobbio-backend/components/application/new-job-list', ['exports', 'jobbio-backend/components/active-pagination-component'], function (exports, _activePaginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activePaginationComponent.default.extend({
    modelType: 'job',
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['status'] = this.get('type');
      if (this.get('model.id')) {
        params['id'] = this.get('model.id');
      }
      return params;
    }),
    init() {
      this._super(...arguments);
      this.set('cursor', '');
      this.setProperties({
        hasLoadedInitialy: false,
        hasMore: true,
        objects: []
      });
      this.send('loadMore');
    },
    didRender() {
      this._super(...arguments);

      // Set the new posted job as selected Job
      if (this.get('currentUser.teammember.company.jobPosted')) {
        this.set('currentUser.teammember.company.jobPosted', null);
        this.set('selectedJob', this.get('objects.firstObject'));
      }

      this.set('jobs', this.get('objects'));
    },
    actions: {
      loaded() {
        if (this.get('selectedJob') != null) {
          return;
        }
        var job = this.get('objects.firstObject');
        if (job == null) {
          return;
        }
        this.set('selectedJob', job);
        this.set('jobs', this.get('objects'));
        var type = this.get('type');
        if (type == 'active') {
          this.set('liveJobs', this.get('objects'));
        } else if (type == 'paused') {
          this.set('pausedJobs', this.get('objects'));
        } else if (type == 'draft') {
          this.set('draftJobs', this.get('objects'));
        } else if (type == 'closed') {
          this.set('closedJobs', this.get('objects'));
        }
      },
      sendJobSelected(job) {
        this.set('selectedJob', job);
        window.$('.job-detail-wrapper').scrollTop(0);
      }
    }
  });
});