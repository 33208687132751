define('jobbio-backend/components/settings/auto-replies', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    options: null,
    saving: false,
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        content_style: ".mce-content-body {font-size:0.9rem;}",
        plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | link '
      });
      this.get('store').query('auto-reply', {}).then(response => {
        this.set('auto_replies', response);
      });
    },
    actions: {
      autoReplies: function (value) {
        this.set('saving', true);
        value.save();
        var self = this;
        Ember.run.later(function () {
          self.set('saving', false);
          self.toast.success('Auto replies updated');
        }, 500);
      },
      autoRepliesSwitch: function (value) {
        value.set('is_active', !value.get('is_active'));
        var self = this;
        value.save().then(() => {
          self.toast.success('Auto replies updated');
        });
      }
    }
  });
});