define('jobbio-backend/models/job', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const JobValidations = (0, _emberCpValidations.buildValidations)({
        title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            max: 100,
            message: 'Ensure this field has no more than 100 characters.'
        })],
        contract: (0, _emberCpValidations.validator)('presence', true),
        level: (0, _emberCpValidations.validator)('presence', true),
        job_type_id: (0, _emberCpValidations.validator)('presence', true),
        salary_from: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
            allowString: true,
            positive: true,
            gt: 0,
            message: 'This value must be greater than zero'
        })],
        salary_to: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
            allowString: true,
            positive: true,
            gt: 0,
            message: 'This value must be greater than zero'
        }), (0, _emberCpValidations.validator)('inline', {
            validate(value, options, model) {
                if (parseInt(model.get('salary_from')) > parseInt(value)) {
                    return 'To salary cannot be less than From salary';
                } else {
                    return true;
                }
            }
        })],
        salary_type: (0, _emberCpValidations.validator)('presence', true),
        currency: (0, _emberCpValidations.validator)('presence', true),
        skills: (0, _emberCpValidations.validator)('presence', true),
        description: (0, _emberCpValidations.validator)('presence', true),
        address: (0, _emberCpValidations.validator)('presence', true),
        country: (0, _emberCpValidations.validator)('presence', true),
        country_short: (0, _emberCpValidations.validator)('presence', true),
        post_code: (0, _emberCpValidations.validator)('presence', true),
        state: (0, _emberCpValidations.validator)('presence', true),
        city: (0, _emberCpValidations.validator)('presence', true),
        redirect: (0, _emberCpValidations.validator)('format', {
            regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
            message: 'Please enter a valid URL',
            allowBlank: true
        })
    });

    exports.default = _emberData.default.Model.extend(JobValidations, {
        company: _emberData.default.attr(),
        title: _emberData.default.attr("string"),
        description: _emberData.default.attr("string"),
        contract: _emberData.default.attr("string"),
        level: _emberData.default.attr("string"),
        slug: _emberData.default.attr("string"),

        skills: _emberData.default.attr(),
        stages: _emberData.default.hasMany('stage'),
        content: _emberData.default.attr(),
        questions: _emberData.default.attr(),
        logs: _emberData.default.attr(),

        type: _emberData.default.attr(),
        location: _emberData.default.attr(),
        longitude: _emberData.default.attr(),
        latitude: _emberData.default.attr(),
        address: _emberData.default.attr(),
        country: _emberData.default.attr(),
        country_short: _emberData.default.attr(),
        city: _emberData.default.attr(),
        state: _emberData.default.attr(),
        post_code: _emberData.default.attr(),

        salary_from: _emberData.default.attr("number"),
        salary_to: _emberData.default.attr("number"),
        salary_disclosed: _emberData.default.attr("number"),
        salary_type: _emberData.default.attr("string"),
        currency: _emberData.default.attr("string"),

        status: _emberData.default.attr("string"),
        hired: _emberData.default.attr("boolean"),

        views: _emberData.default.attr("number"),
        impressions: _emberData.default.attr("number"),
        applications: _emberData.default.attr("number"),
        new_applications: _emberData.default.attr("number"),
        passed: _emberData.default.attr("number"),
        shortlisted: _emberData.default.attr("number"),
        drafts: _emberData.default.attr("number"),
        pending: _emberData.default.attr("number"),

        redirect: _emberData.default.attr("string"),

        published_at: _emberData.default.attr("date"),
        updated_at: _emberData.default.attr("date"),
        created_at: _emberData.default.attr("date"),

        skill_names: _emberData.default.attr(), // not a real value from API - used for updating skills
        job_type_id: _emberData.default.attr("number"), // ^^
        errors: _emberData.default.attr(), // ^^
        stage_error: _emberData.default.attr() // ^^
    });
});