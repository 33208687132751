define('jobbio-backend/models/auto-reply', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    event: _emberData.default.attr(),
    body: _emberData.default.attr(),
    is_active: _emberData.default.attr()
  });
});