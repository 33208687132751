define('jobbio-backend/components/application/application-answer', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    answer_text: Ember.computed(function () {
      var type = this.get('answer.question.type');
      if (type == 'text') {
        return this.get('answer.text');
      } else if (type == 'bool_tf') {
        return this.get('answer.bool') ? "True" : "False";
      } else if (type == 'bool_yn') {
        return this.get('answer.bool') ? "Yes" : "No";
      } else if (type == 'single_select') {
        return this.get('answer.option.text');
      } else if (type == 'slider') {
        return this.get('answer.value');
      } else if (type == 'range') {
        return this.get('answer.value_from') + " - " + this.get('answer.value_to');
      } else if (type == 'multi_select') {
        var text = "";
        var options = this.get('answer.options');
        for (var key in options) {
          if (key != "_super") {
            var option = options[key];
            text += option.text + ", ";
          }
        }
        var new_text = text.slice(0, text.length - 2);
        return new_text;
      }
    })
  });
});