define('jobbio-backend/components/employer-branding/branding-new', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        init() {
            this._super(...arguments);
        },
        actions: {
            dragEnd({ sourceList, sourceIndex, targetList, targetIndex }) {
                if (sourceList === targetList && sourceIndex === targetIndex) return;
                const sourceItem = sourceList.objectAt(sourceIndex);
                const targetItem = sourceList.objectAt(sourceIndex);
                sourceList.removeAt(sourceIndex);
                targetList.insertAt(targetIndex, sourceItem);
                var temp;
                if (this.get('modelType') == 'branding-content') {
                    temp = {
                        title: targetItem.get('title'),
                        content: targetItem.get('content'),
                        image: targetItem.get('image')
                    };
                    targetItem.set('text', sourceItem.get('text'));
                    sourceItem.set('text', temp.text);
                } else {
                    temp = {
                        title: targetItem.get('title'),
                        gallery: targetItem.get('gallery'),
                        image: targetItem.get('image')
                    };
                }
                targetItem.set('title', sourceItem.get('title'));
                targetItem.set('image', sourceItem.get('image'));
                sourceItem.set('title', temp.title);
                sourceItem.set('image', temp.image);
                this.send('reorder', sourceItem, targetIndex);
            },
            reorder: function (sourceItem, targetIndex) {
                sourceItem.set('order', targetIndex);
                sourceItem.save();
            },
            addNew(type) {
                this.set('addNewClicked', !this.get('addNewClicked'));
                window.$(`#postTo${type}`).modal('show');
            }
        }
    });
});