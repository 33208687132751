define('jobbio-backend/controllers/workable-callback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      'code': { refreshModel: true }
    }
  });
});