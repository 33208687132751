define("jobbio-backend/models/channel", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    caption: _emberData.default.attr("string"),
    description: _emberData.default.attr("string")
  });
});