define('jobbio-backend/models/stage', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    applications_no: _emberData.default.attr(),
    job_id: _emberData.default.attr('number'),
    job: _emberData.default.belongsTo('job')
  });
});