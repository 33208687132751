define('jobbio-backend/models/answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    question: _emberData.default.attr(),
    option: _emberData.default.attr(),
    options: _emberData.default.attr(),

    text: _emberData.default.attr(),
    value_from: _emberData.default.attr(),
    value_to: _emberData.default.attr(),
    value: _emberData.default.attr(),
    bool: _emberData.default.attr(),
    video_token: _emberData.default.attr(),

    created_at: _emberData.default.attr()
  });
});