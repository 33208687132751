define('jobbio-backend/components/common/dashboard-feed', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'feed-item',
    page_size: 10,
    params: Ember.computed('cursor', function () {
      var params = this._super();
      return params;
    })
  });
});