define('jobbio-backend/components/team-collaboration/shared-talent-list', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'talent-shared',
    sharedTalents: null,
    onTeamMemberChange: Ember.observer('selectedTeamMemberID', function () {
      this.set('cursor', '');
      this.set('hasMore', true);
      this.set('objects', []);
      this.set('sharedTalents', []);
      this.send('loadMore', true);
    }),
    params: Ember.computed('selectedTeamMemberID', 'type', function () {
      var params = this._super();
      params['filter'] = this.get('type');
      if (this.get('selectedTeamMemberID')) {
        params['member_id'] = this.get('selectedTeamMemberID');
      }
      return params;
    }),
    selectedBackground: Ember.computed('property', function () {
      return new Ember.String.htmlSafe('background-color: rgba(0, 0, 0, 0.05);');
    }),
    init() {
      this._super(...arguments);
      this.set('sharedTalents', []);
    },
    didRender() {
      if (this.get('selectedTeamMemberID') && this.get('autoLoad') && this.get('hasLoadedInitialy') == false) {
        this.send('loadMore');
      }
    },
    actions: {
      loaded() {
        this.set('selectedTalent', this.get('objects.firstObject'));
        this.set('sharedTalents', this.get('objects'));
      },
      selectTalent(talent) {
        this.set('selectedTalent', talent);
      }
    }
  });
});