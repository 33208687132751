define('jobbio-backend/components/application/application-notes', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'note',
    autoLoad: false,
    talent: null,
    params: Ember.computed('cursor', 'talent', function () {
      var params = this._super();
      params['talent_id'] = this.get('talent.user.id');
      return params;
    }),
    onTalentChanged: Ember.observer('talent.id', function () {
      this.set('new_note_text', '');
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.send("loadMore");
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.set('hasMore', true);
      this.set('objects', []);
      this.set('cursor', '');
      this.send("loadMore");
    },
    actions: {
      onAddNote(body) {
        this.get('store').createRecord("note", {
          body: body,
          talent_id: this.get('talent.user.id')
        }).save().then(note => {
          this.get('objects').pushObject(note);
        });
        this.set('new_note_text', '');
      }
    }
  });
});