define('jobbio-backend/components/common/dashboard-stats', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    currentUser: Ember.inject.service(),
    didRender() {
      this._super(...arguments);
      if (this.get('currentUser.teammember.company.status') == 'frozen') {
        window.swal({
          closeOnClickOutside: false,
          closeOnEsc: false,
          text: "Your account is now frozen. Please contact your relationship manager to unlock your account",
          icon: "warning",
          buttons: {
            cancel: {
              text: "Close",
              visible: true,
              closeModal: true,
              className: "btn btn-secondary dashboard_swal_cancel"
            }
          }
        });
      }
    }
  });
});