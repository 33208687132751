define('jobbio-backend/components/common/dashboard-rightbar', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    store: Ember.inject.service(),
    modelType: 'latest-application',
    page_size: 10,
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['type'] = 'shortlisted';
      return params;
    }),
    init() {
      this._super(...arguments);
    },
    actions: {
      setSelectedJob(application, job) {
        var self = this;
        self.set('selectedApplication', application);
        self.get('store').findRecord('job', job.id).then(function (response) {
          self.set('clickedFeed', response);
          window.$('#rightBarFeedPopup').modal('show');
          setTimeout(function () {
            window.$('.message-body').scrollTop(0);
          }, 1000);
        });
      },
      pass() {
        window.$('#rightBarFeedPopup').modal('hide');
      }
    }
  });
});