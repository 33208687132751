define('jobbio-backend/services/team', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    init() {
      this._super(...arguments);
      this.get('store').findAll('company-member').then(response => {
        this.set('members', response);
      });
    }
  });
});