define('jobbio-backend/components/common/job-content-list', ['exports', 'jobbio-backend/components/base-component', 'ember-data'], function (exports, _baseComponent, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    contents: Ember.computed('job.content', 'selectedTab', function () {
      var jobContent = this.get('job.content');
      var jobContentIDs = [];
      jobContent.forEach(function (content) {
        jobContentIDs.push(parseInt(content.id));
      });
      var contentToShow = [];
      return _emberData.default.PromiseArray.create({
        promise: this.get('store').query('job-content', {}).then(response => {
          response.forEach(function (content) {
            if (!jobContentIDs.includes(parseInt(content.id))) {
              contentToShow.pushObject(content);
            }
          });
          return contentToShow;
        })
      });
    })
  });
});