define('jobbio-backend/components/application/application-search-detail', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    searchApplications: true,
    searchFollowers: false,
    searchTalentPool: false,
    onApplicationSelected: Ember.observer('selectedApplication', function () {
      window.$('.message-body').scrollTop(0);
    }),
    onTalentSelected: Ember.observer('selectedTalent', function () {
      window.$('.message-body').scrollTop(0);
    }),
    init() {
      this._super(...arguments);
    },
    actions: {
      updateApplication() {
        var index = this.get('applications').indexOf(this.get('selectedApplication'));
        var beforeArr = this.get('applications').slice(0, index);
        var afterArr = this.get('applications').slice(index);
        if (this.get('bulkList').length) {
          beforeArr = beforeArr.filter(application => {
            return !this.get('bulkList').includes(application);
          });
          afterArr = afterArr.filter(application => {
            return !this.get('bulkList').includes(application);
          });
          this.set('bulkList', []);
        } else {
          afterArr.shift();
        }
        if (afterArr.length) {
          this.set('selectedApplication', afterArr[0]);
        } else if (beforeArr.length) {
          this.set('selectedApplication', beforeArr[beforeArr.length - 1]);
        } else {
          this.set('selectedApplication', null);
        }
        this.set('applications', beforeArr.concat(afterArr));
      }
    }
  });
});