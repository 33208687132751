define('jobbio-backend/components/settings/social-media', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    saving: false,
    actions: {
      socialMedia: function () {
        this.set('saving', true);
        this.get('socialmedia').save();
        var self = this;
        Ember.run.later(function () {
          self.set('saving', false);
          self.toast.success('Social media updated');
        }, 500);
      }
    }
  });
});