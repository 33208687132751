define('jobbio-backend/components/company/job-details', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        errors: null,
        onTitleChanged: Ember.observer('job.title', function () {
            if (this.get('job')) {
                if (!this.get('job.title')) {
                    this.set('errors.title', 'This is a required field');
                } else if (this.get('job.title').length >= 100) {
                    this.set('errors.title', 'Ensure this field has no more than 100 characters.');
                } else {
                    this.set('errors.title', '');
                }
            }
        }),
        init() {
            this._super(...arguments);
            this.set('errors', {});
        }
    });
});