define('jobbio-backend/components/application/talent-search', ['exports', 'jobbio-backend/components/active-pagination-component'], function (exports, _activePaginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activePaginationComponent.default.extend({
    modelType: Ember.computed('searchFollowers', 'searchTalentPool', function () {
      if (this.get("searchFollowers")) {
        return "follower";
      } else {
        return "talent";
      }
    }),
    searchFollowers: false,
    searchTalentPool: false,
    onAttrsChanged: Ember.observer('tag', function () {
      this.get('resetSearch').perform();
    }),
    params: Ember.computed('cursor', function () {
      var params = this._super();
      if (this.get('tag')) {
        params['tag'] = this.get('tag.id');
      }
      return params;
    }),
    onShortlisted: Ember.observer('shortlisted', function () {
      this.set("objects", []);
      this.set('cursor', '');
      this.set('loading', false);
      this.set('hasMore', true);
      this.send('loadMore');
    }),
    onAddedToTalentPool: Ember.observer('addedToTalentPool', function () {
      this.get('resetSearch').perform();
    }),
    actions: {
      loaded() {
        var talent = this.get('objects.firstObject');
        this.set('selectedTalent', talent);
      },
      selectTalent(talent) {
        this.set('selectedTalent', talent);
      }
    }
  });
});