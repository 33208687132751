define('jobbio-backend/components/employer-branding/edit-branding', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    initialVideoUrl: '',
    options: null,
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        plugins: ['autolink lists charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist '
      });
      if (this.get('type') == 'Gallery') {
        this.set('plugins', ['autolink lists link image charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table contextmenu directionality', 'emoticons template paste textcolor colorpicker textpattern imagetools']);
        this.set('toolbar1', 'bold | bullist | link ');
      }
    },
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.get('post.video')) {
        this.set('initialVideoUrl', this.get('post.video'));
      }
    },
    actions: {
      editGallery: function () {
        var url = _environment.default.APP.API_HOST + '/jobs/content/' + this.get('post.id');
        if (this.get('editPostImage')) {
          var formData = {};
          if (!this.get('post.title')) this.set('post.title', '');
          formData.title = this.get('post.title');
          formData.image = this.get('editPostImage');
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            var options = {
              headers: {
                Authorization: headerValue
              },
              contentType: 'multipart/form-data',
              method: 'PATCH',
              fileKey: 'image',
              data: formData
            };
            this.get('editPostImage').upload(url, options).then(response => {
              this.set('emberPost.title', this.get('post.title'));
              this.set('emberPost.image', response.body.image);
              this.toast.success('Image updated');
              this.send('close');
            });
          });
        } else {
          var content = {};
          if (!this.get('post.title')) this.set('post.title', null);
          content.title = this.get('post.title');
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: url,
              headers: {
                Authorization: headerValue
              },
              type: 'PATCH',
              data: JSON.stringify(content),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).then(() => {
              this.set('emberPost.title', this.get('post.title'));
              this.toast.success('Image updated');
              this.send('close');
            });
          });
        }
      },
      editContent: function () {
        if (this.get('post.title') && this.get('post.text')) {
          if (this.get('editPostImage')) {
            var formData = {};
            formData.title = this.get('post.title');
            formData.text = this.get('post.text');
            formData.image = this.get('editPostImage');
            this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
              var options = {
                headers: {
                  Authorization: headerValue
                },
                contentType: 'multipart/form-data',
                method: 'PATCH',
                fileKey: 'image',
                data: formData
              };
              this.get('editPostImage').upload(_environment.default.APP.API_HOST + '/jobs/content/' + this.get('post.id'), options).then(response => {
                this.set('emberPost.title', this.get('post.title'));
                this.set('emberPost.text', this.get('post.text'));
                this.set('emberPost.image', response.body.image);
                this.send('close');
                this.toast.success('Content updated');
              });
            });
          } else {
            var url = _environment.default.APP.API_HOST + '/jobs/content/' + this.get('post.id');
            var content = {};
            content.title = this.get('post.title');
            content.text = this.get('post.text');
            this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
              window.$.ajax({
                url: url,
                headers: {
                  Authorization: headerValue
                },
                type: 'PATCH',
                data: JSON.stringify(content),
                contentType: 'application/json;charset=utf-8',
                dataType: 'json'
              }).then(() => {
                this.set('emberPost.title', this.get('post.title'));
                this.set('emberPost.text', this.get('post.text'));
                this.send('close');
                this.toast.success('Content updated');
              });
            });
          }
        }
        if (this.get('post.title')) {
          this.set('post.errors.image.title', '');
        } else {
          this.set('post.errors.image.title', 'This field cannot be empty');
        }
        if (this.get('post.text')) {
          this.set('post.errors.image.text', '');
        } else {
          this.set('post.errors.image.text', 'This field cannot be empty');
        }
      },
      editVideo: function () {
        var validYoutubeURL = /^(https\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(this.get('post.video'));
        var validVimeoURL = /^(https\:\/\/)?(player\.vimeo\.com)\/.+$/.test(this.get('post.video'));
        if (this.get('post.title') && this.get('post.video') && this.get('post.text') && (validYoutubeURL || validVimeoURL)) {
          var formData = {};
          formData.title = this.get('post.title');
          formData.text = this.get('post.text');
          formData.video = this.get('post.video');
          var url = _environment.default.APP.API_HOST + '/jobs/content/' + this.get('post.id');
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: url,
              headers: {
                Authorization: headerValue
              },
              type: 'PATCH',
              data: JSON.stringify(formData),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).then(() => {
              this.set('emberPost.title', this.get('post.title'));
              this.set('emberPost.text', this.get('post.text'));
              this.set('emberPost.video', this.get('post.video'));
              this.send('close');
              this.toast.success('Content updated');
            });
          });
        }
        if (this.get('post.title')) {
          this.set('post.errors.video.title', '');
        } else {
          this.set('post.errors.video.title', 'This field cannot be empty');
        }
        if (this.get('post.video')) {
          this.set('post.errors.video.url', '');
        } else {
          this.set('post.errors.video.url', 'This field cannot be empty');
        }
        if (this.get('post.video')) {
          if (!validYoutubeURL && !validVimeoURL) {
            this.set('post.errors.video.url', 'Please enter a valid URL');
          } else {
            this.set('post.errors.video.url', '');
          }
        } else {
          this.set('post.errors.video.url', 'This field cannot be empty');
        }
        if (this.get('post.text')) {
          this.set('post.errors.video.text', '');
        } else {
          this.set('post.errors.video.text', 'This field cannot be empty');
        }
      },
      close() {
        this.setProperties({
          editPostImage: ''
        });
        window.$(`#editTo${this.get('type')} .close`).click();
      },
      uploadImage: function (file) {
        this.set('editPostImage', file);
      }
    }
  });
});