define('jobbio-backend/components/employer-branding/testimonial-detail', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _paginationComponent.default.extend({
        modelType: 'branding-testimonial',
        params: Ember.computed('cursor', function () {
            var params = this._super();
            params['type'] = 'testimony';
            return params;
        }),
        store: Ember.inject.service(),
        options: null,
        updating: false,
        init() {
            this._super(...arguments);
            this.set('options', {
                menubar: false,
                height: 300,
                theme: 'silver',
                elementpath: false,
                plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
                toolbar1: 'bold | bullist '
            });
        },
        actions: {
            deleteTestimonial: function (testimonial) {
                var self = this;
                window.swal({
                    title: "Are you sure?",
                    text: "Do you want to delete this testimonial?",
                    icon: "warning",
                    buttons: {
                        cancel: {
                            text: "Close",
                            visible: true,
                            closeModal: true,
                            className: "btn btn-secondary delete_testimonial_swal_cancel"
                        },
                        confirm: {
                            text: "Delete",
                            closeModal: true,
                            className: "btn btn-danger delete_testimonial_swal_confirm"
                        }
                    },
                    dangerMode: true
                }).then(willDelete => {
                    if (willDelete) {
                        testimonial.deleteRecord();
                        testimonial.save();
                        self.get('objects').removeObject(testimonial);
                    }
                });
            },
            setTestimonial: function (testimonial) {
                this.set('testimonial', testimonial);
                this.set('editClicked', !this.get('editClicked'));
            }
        }
    });
});