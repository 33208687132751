define('jobbio-backend/components/common/dashboard-sidebar', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    hasVpoTeamCollaboration: false,
    role: Ember.computed('currentUser.teammber.role', function () {
      return this.get('currentUser.teammember.role') == 'admin' || this.get('currentUser.teammember.role') == 'recruiter_admin' ? 'Admin' : 'Staff';
    }),
    logoDark: Ember.computed(function () {
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(`${this.get('cloudfront')}images/site/logo_black.png`);
    }),
    image: Ember.computed('currentUser.teammber.user.avatar', function () {
      return this.get('currentUser.teammber.user.avatar');
    }),
    name: Ember.computed('currentUser.teammember.user.{first_name,last_name}', function () {
      return this.get('currentUser.teammember.company.name');
    }),
    didInsertElement() {
      this._super(...arguments);
      this.send('getSubscription');
    },
    actions: {
      invalidateSession: function () {
        this.get('session').invalidate();
        window.location.href = `https://${_environment.default.APP.DOMAIN}`;
      },
      getSubscription: function () {
        this.get('store').queryRecord('subscription', {}).then(response => {
          this.set('hasVpoTeamCollaboration', response.get('vpo.team_collaboration'));
        });
      }
    }
  });
});