define('jobbio-backend/session-stores/application', ['exports', 'ember-simple-auth/session-stores/cookie', 'jobbio-backend/config/environment'], function (exports, _cookie, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const oneMonth = 14 * 24 * 60 * 60;

  exports.default = _cookie.default.extend({
    // set an explicit expiration time so session does not expire when window is closed
    cookieExpirationTime: oneMonth,
    cookieDomain: `.${_environment.default.APP.DOMAIN}`
  });
});