define('jobbio-backend/components/settings/billing-information', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    savingBillingInfo: false,
    init() {
      this._super(...arguments);
      this.get('store').unloadAll('billing');
      this.get('store').query('billing', {}).then(response => {
        this.set('billingInformation', response);
      });
    },
    actions: {
      addBillingInfo: function () {
        var data = this.getProperties('contact_name', 'contact_email', 'contact_address', 'vat_number');
        var billing = this.get('store').createRecord('billing', data);
        billing.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = error.message;
          });
          this.set('errors', errors);
          if (validations.get('isValid')) {
            this.set('errors', {});
            this.set('savingBillingInfo', true);
            var self = this;
            billing.save().then(() => {
              Ember.run.later(function () {
                self.get('store').query('billing', {}).then(response => {
                  self.set('billingInformation', response);
                });
                self.set('savingBillingInfo', false);
                self.toast.success('Billing info updated');
              }, 500);
            }).catch(() => {
              self.set('savingBillingInfo', false);
            });
          }
        });
      },
      updateBillingInfo: function (billing) {
        billing.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = error.message;
          });
          billing.set('errors', errors);
          if (validations.get('isValid')) {
            billing.set('errors', {});
            this.set('savingBillingInfo', true);
            var self = this;
            billing.save().then(() => {
              Ember.run.later(function () {
                self.set('savingBillingInfo', false);
                self.toast.success('Billing info updated');
              }, 500);
            }).catch(() => {
              self.set('savingBillingInfo', false);
            });
          }
        });
      }
    }
  });
});