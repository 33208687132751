define('jobbio-backend/components/common/dashboard-chat-list', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'message',
    conversation: null,
    messageText: '',
    lastMessage: null,
    autoLoad: false,
    didRender() {
      Ember.run.later(function () {
        window.$('.chat-form')[0].scrollTop = window.$('.chat-form')[0].scrollHeight;
      }, 100);
      // send messages when type the Enter key
      var self = this;
      window.$('input.chat-input').on('keypress keyup blur', function (event) {
        if (event.which == 13) {
          self.send('sendConversationMessage', self.get('messageText'));
        }
      });
    },
    didUpdateAttrs() {
      // clear out old messages
      this.set('objects', []);
      this.set('hasMore', true);
      this.set('loading', false);
      this.set('cursor', '');
      this.set('lastMessage', null);
      if (this.get('conversation') != null) {
        this.send('loadMore');
      }
    },
    messages: Ember.computed('objects.lastObject', function () {
      // reverse the order of objects so the newest is at the bottom
      if (this.get('objects')) {
        var conversationMessages = this.get('objects').sortBy('created_at').reverse();
        if (this.get('lastMessage')) {
          conversationMessages.pushObject(this.get('lastMessage'));
        }
        return conversationMessages;
      } else {
        return [];
      }
    }),
    params: Ember.computed('cursor', 'conversation.id', function () {
      var params = this._super();
      if (params['cursor'] == undefined || params['cursor'] == 'undefined') {
        delete params['cursor'];
      }
      params['conversation_id'] = this.get('conversation.id');
      params['paginate'] = true;
      return params;
    }),
    onMessageFormOpened: Ember.observer('messageFormOpened', function () {
      this.set('messageText', '');
    }),
    actions: {
      sendConversationMessage(messageText) {
        if (messageText) {
          if (this.get('currentUser.teammember.company.status') == 'frozen') {
            this.set('messageText', '');
            this.send('showFrozenPopup', 'send_conversation_message');
          } else {
            var conversation = this.get('conversation');
            if (conversation == null) {
              // we can't send the message if we don't have a conversation for it
              alert('An unexpected error occurred, please try again later');
              return;
            }
            // create a fake message to add to the chat
            var messageParams = {
              'is_sender': true,
              'text': messageText,
              'read': true,
              'conversation_id': this.get('conversation.id')
            };
            var message = this.get('store').createRecord('message', messageParams).save();
            this.set('lastMessage', message);
            this.get('messages').pushObject(message);
            this.set('messageText', '');
          }
        }
      },
      loaded() {
        // reset the array to trigger reversing the messages
        let temp = this.get('objects').slice(0);
        this.set('objects', temp);
      },
      loadMoreMessage() {
        if (this.get('conversation') != null) {
          this.send('loadMore');
        }
      }
    }
  });
});