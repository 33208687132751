define('jobbio-backend/components/settings/change-password', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    saving: false,
    actions: {
      changePassword: function () {
        var data = this.getProperties('current_password', 'password', 'confirm_password');
        var password = this.get('store').createRecord('password', data);
        password.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = error.message;
          });
          this.set('errors', errors);
          if (validations.get('isValid')) {
            this.set('errors', {});
            this.set('saving', true);
            var self = this;
            password.save().then(() => {
              Ember.run.later(function () {
                self.set('saving', false);
                self.toast.success('Password updated');
                self.set('current_password', '');
                self.set('password', '');
                self.set('confirm_password', '');
                self.get('session').invalidate();
              }, 500);
            }).catch(xhr => {
              xhr.errors.forEach(error => {
                self.set('saving', false);
                if (error.detail == 'Error, Invalid current password provided') {
                  this.set('errors.current_password', 'Invalid current password provided');
                }
              });
            });
          }
        });
      }
    }
  });
});