define('jobbio-backend/components/common/dashboard-feed-list', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);
    },
    actions: {
      pass() {
        window.$('#applicationFeedPopup').modal('hide');
      }
    }
  });
});