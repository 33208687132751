define('jobbio-backend/models/notification', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Validations = (0, _emberCpValidations.buildValidations)({
    action_email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'This is a required field!',
      disabled: Ember.computed.not('model.application_action_email')
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowBlank: true
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    new_follower: _emberData.default.attr(),
    new_message: _emberData.default.attr(),
    new_applicant: _emberData.default.attr(),
    pending_applicant: _emberData.default.attr(),
    action_email: _emberData.default.attr(),
    application_action_email: _emberData.default.attr(),
    errors: _emberData.default.attr()
  });
});