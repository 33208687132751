define("jobbio-backend/models/content", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr("string"),
        type: _emberData.default.attr("string"),
        text: _emberData.default.attr("string"),
        image: _emberData.default.attr("string"),
        video: _emberData.default.attr("string"),
        in_bank: _emberData.default.attr("boolean"),
        views: _emberData.default.attr("number"),
        impressions: _emberData.default.attr("number"),
        created_at: _emberData.default.attr("date"),
        updated_at: _emberData.default.attr("date")
    });
});