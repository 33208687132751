define('jobbio-backend/components/team-collaboration/team-members', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    errors: null,
    init() {
      this._super(...arguments);
      this.set('errors', {});
    },
    didRender() {
      // Allow only first '+' and numbers for country code field
      window.$('#country_code').on('keypress keyup blur', function (event) {
        if (event.which < 48 || event.which > 57) {
          if (event.which == 43) {
            if (window.$('#country_code').val() != '') {
              event.preventDefault();
            }
          } else {
            event.preventDefault();
          }
        }
      });
    },
    actions: {
      clearForm: function () {
        this.setProperties({
          first_name: '',
          last_name: '',
          email: '',
          role: '',
          country_code: '',
          password: ''
        });
        this.set('errors', {});
        window.$('.add-team-member-form')[0].reset();
        window.$('.add-team-member-form').slideToggle();
      },
      addTeamMember: function () {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'add_team_member');
        } else {
          var data = this.getProperties('first_name', 'last_name', 'email', 'country_code', 'password');
          data.role = this.get('addCompanyMemberRole') ? this.get('addCompanyMemberRole') : '';
          var member = this.get('store').createRecord('memberdetail', data);
          member.validate().then(({ validations }) => {
            var errors = {};
            validations.get('errors').forEach(error => {
              errors[`${error.attribute.replace('user.', '')}`] = error.message;
            });
            this.set('errors', errors);
            if (validations.get('isValid')) {
              member.save().then(() => {
                this.get('teamMembers').pushObject(member);
                this.send('clearForm');
              }).catch(xhr => {
                var errors = {};
                xhr.errors.forEach(error => {
                  errors[error.source.pointer.replace('/data/attributes/', '')] = error.detail;
                });
                this.set('errors', errors);
              });
            }
          });
        }
      },
      selectRole: function (role) {
        this.set('addCompanyMemberRole', role);
      }
    }
  });
});