define('jobbio-backend/components/employer-branding/branding-detail', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['type'] = this.get('type');
      return params;
    }),
    store: Ember.inject.service(),
    options: null,
    updating: false,
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist | link '
      });
    },
    didRender() {
      this._super(...arguments);
      if (this.get('objects')) {
        this.set('tiles', this.get('objects'));
      }
    },
    actions: {
      deletePost: function (post) {
        var self = this;
        var name = this.get('name') == 'gallery' ? 'image' : this.get('name');
        window.swal({
          title: "Are you sure?",
          text: `Do you want to delete this ${name}?`,
          icon: "warning",
          buttons: {
            cancel: {
              text: "Close",
              visible: true,
              closeModal: true,
              className: `btn btn-secondary delete_${name}_swal_cancel`
            },
            confirm: {
              text: "Delete",
              closeModal: true,
              className: `btn btn-danger delete_${name}_swal_confirm`
            }
          },
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            post.deleteRecord();
            post.save();
            self.get('objects').removeObject(post);
          }
        });
      },
      setPost: function (post) {
        var content = {
          id: post.id,
          title: post.title,
          text: post.text,
          image: post.image,
          video: post.video
        };
        this.set('post', content);
        this.set('emberPost', post);
        if (!post.errors) {
          if (this.get('modelType') == 'branding-gallery') {
            this.set('post.errors', {});
          } else {
            this.set('post.errors', {
              'image': {},
              'video': {}
            });
          }
        }
        if (this.get('modelType') == 'branding-content') {
          setTimeout(function () {
            window.tinymce.get('editedBrandingContentText').undoManager.clear();
          }, 500);
        }
      }
    }
  });
});