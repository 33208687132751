define('jobbio-backend/components/content-component', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    actions: {
      remove() {
        this.get('job.content').removeObject(this.get('content'));
        this.get('contents').pushObject(this.get('content'));
        var url = _environment.default.APP.API_HOST + '/jobs/' + this.get('job.id') + "/content/" + this.get('content.id');
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE'
          });
        });
      },
      add() {
        var data = { id: this.get('content.id') };
        this.get('job.content').pushObject(this.get('content'));
        this.get('contents').removeObject(this.get('content'));
        var url = _environment.default.APP.API_HOST + '/jobs/' + this.get('job.id') + "/content";
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          });
        });
      },
      updateTextPost(content) {
        var url = _environment.default.APP.API_HOST + "/jobs/content/" + this.get('content.id');
        var data = { 'title': content.title, 'text': content.text, 'type': 'text' };
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'PATCH',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            this.get('currentUser').load();
          }, xhr => {
            this.set('error', xhr.responseText);
          });
        });
      }
    }
  });
});