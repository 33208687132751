define('jobbio-backend/components/common/dashboard-feed-list-item', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    isJob: Ember.computed(function () {
      return this.get('item.type').indexOf('job') > -1;
    }),
    isTalent: Ember.computed(function () {
      return this.get('item.talent') != undefined;
    }),
    init() {
      this._super(...arguments);
    }
  });
});