define('jobbio-backend/components/application/application-detail', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    downloading: false,
    teamMembers: null,
    noApplicant: Ember.computed('application', 'talent', function () {
      return this.get('application') == null && this.get('talent') == null;
    }),
    isNewPendingApplication: Ember.computed('application.status', function () {
      return this.get('application') == null ? false : this.get('application.status') == 'submitted' || this.get('application.status') == 'pending';
    }),
    isPassedApplication: Ember.computed('application.status', function () {
      return this.get('application') == null ? false : this.get('application.status') == 'passed';
    }),
    isPendingApplication: Ember.computed('application.status', function () {
      return this.get('application') == null ? false : this.get('application.status') == 'pending';
    }),
    isClosedFeed: Ember.computed('job.status', function () {
      return this.get('job.status') == 'closed';
    }),
    canMessageTalent: Ember.computed('follower', 'application', function () {
      return this.get('follower') || this.get('application.status') == 'passed' ? false : true;
    }),
    canInterviewTalent: Ember.computed('application.{status,interview_requested}', function () {
      return this.get('application') == null ? false : this.get('application.status') == 'shortlisted' && this.get('application.interview_requested') == false;
    }),
    canBulkAction: Ember.computed('application.status', function () {
      return this.get('application.status') == 'pending' || this.get('application.status') == 'passed' || this.get('application.status') == 'submitted' ? true : false;
    }),
    selectedStage: Ember.computed('application.stage', 'job', function () {
      if (this.get('application.stage') == null) {
        return "Add Stage";
      }
      if (this.get('application.stage.name')) {
        return this.get('application.stage.name');
      } else {
        var stages = this.get('job.stages');
        var name;
        if (stages) {
          stages.forEach(stage => {
            if (stage.id == this.get('application.stage')) {
              name = stage.get('name');
            }
          });
        }
        return name;
      }
    }),
    full_name: Ember.computed('talent.user.{first_name,last_name}', function () {
      return `${this.get('talent.user.first_name')} ${this.get('talent.user.last_name')}`;
    }),
    avatar: Ember.computed('talent.user.avatar', function () {
      return this.get('talent.user.avatar');
    }),
    introduction: Ember.computed('talent.introduction', function () {
      return this.get('talent.introduction');
    }),
    role: Ember.computed('talent.{current_title,current_company}', function () {
      var title = this.get('talent.current_title');
      var company = this.get('talent.current_company');
      if (title == null) {
        return;
      }
      if (company == null) {
        return title;
      }
      return `${title}, ${company}`;
    }),
    downloadUrl: Ember.computed('application.id', function () {
      return `${this.get('apiBaseUrl')}/jobs/applications/${this.get('application.id')}`;
    }),
    bulkDownloadUrl: Ember.computed('application.id', function () {
      return `${this.get('apiBaseUrl')}/jobs/applications/bulk`;
    }),
    canEdit: Ember.computed('application.status', function () {
      return this.get('application.status') != 'passed';
    }),
    init() {
      this._super(...arguments);
      this.get('store').queryRecord('gdpr-settings', {}).then(record => {
        this.set('setting', record);
      });
      var self = this;
      var teamMembers = [];
      this.get('store').findAll('memberdetail').then(members => {
        members.forEach(member => {
          if (member.get('id')) {
            if (member.get('user.id') != self.get('currentUser.teammember.id')) {
              teamMembers.pushObject(member);
            }
          }
        });
        this.set('teamMembers', teamMembers);
      });
    },
    actions: {
      setBulkAction(bulkAction) {
        this.set('bulkAction', bulkAction);
        window.$(`#bulkActionsPopup_${this.get('id')}`).modal('show');
        this.set('bulkActionsPopupOpened', !this.get('bulkActionsPopupOpened'));
      },
      shortlist(application) {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_shortlist');
        } else {
          if (application.get('status') == 'pending') {
            application.set('status', "shortlisted");
            application.save().then(() => {
              this.set('shortlisted', !this.get('shortlisted'));
            });
            this.toast.success('Applicant shortlisted');
            this.get('updateApplication')();
            if (this.get('job.pending') > 0) {
              this.get('job').decrementProperty('pending');
            }
            this.get('job').incrementProperty('shortlisted');
            this.set('currentUser.teammember.company.new_applications', this.get('currentUser.teammember.company.new_applications') - 1);
            this.set('currentUser.teammember.company.talent_pool_no', this.get('currentUser.teammember.company.talent_pool_no') + 1);
          } else {
            application.set('status', "shortlisted");
            application.save().then(() => {
              this.set('shortlisted', !this.get('shortlisted'));
            });
            this.toast.success('Applicant shortlisted');
            this.get('updateApplication')();
            if (this.get('job.new_applications') > 0) {
              this.get('job').decrementProperty('new_applications');
            }
            this.get('job').incrementProperty('shortlisted');
            this.set('currentUser.teammember.company.new_applications', this.get('currentUser.teammember.company.new_applications') - 1);
            this.set('currentUser.teammember.company.talent_pool_no', this.get('currentUser.teammember.company.talent_pool_no') + 1);
          }
        }
      },
      pass(application) {
        if (application.get('status') == 'pending') {
          application.set('status', "passed");
          application.save();
          this.toast.success('Applicant passed');
          this.get('updateApplication')();
          if (this.get('job.pending') > 0) {
            this.get('job').decrementProperty('pending');
          }
          this.get('job').incrementProperty('passed');
          this.set('currentUser.teammember.company.new_applications', this.get('currentUser.teammember.company.new_applications') - 1);

          if (this.get('pass')) {
            this.send('pass');
          }
        } else {
          application.set('status', "passed");
          application.save();
          this.toast.success('Applicant passed');
          this.get('updateApplication')();
          if (this.get('job.new_applications') > 0) {
            this.get('job').decrementProperty('new_applications');
          }
          this.get('job').incrementProperty('passed');
          this.set('currentUser.teammember.company.new_applications', this.get('currentUser.teammember.company.new_applications') - 1);

          if (this.get('pass')) {
            this.send('pass');
          }
        }
      },
      revert(application) {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_revert');
        } else {
          application.set('status', "submitted");
          application.save();
          this.toast.success('Applicant moved to new');
          this.get('updateApplication')();
          this.get('job').decrementProperty('passed');
          this.get('job').incrementProperty('new_applications');
          this.set('currentUser.teammember.company.new_applications', this.get('currentUser.teammember.company.new_applications') + 1);
        }
      },
      addToTalentPool(talent) {
        // api returns a 204 here but ember needs an id for its store so we will set
        // a random id for it
        var randomId = Math.floor(Math.random() * 10000 + 1);
        var new_talent = this.get('store').createRecord('talent-pool', {
          'id': randomId,
          'talent_id': talent.get('user.id')
        });
        new_talent.save().then(() => {
          this.set('addedToTalentPool', !this.get('addedToTalentPool'));
        });
        this.toast.success('Talent has been added to talent pool');
      },
      changeStage(application, stage) {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_application_detail_change_stage');
        } else {
          application.set('stage', stage);
          application.save().then(() => {
            this.send('reloadJob');
          });
        }
      },
      downloadApplication() {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_download_application');
        } else {
          this.set('downloading', true);
          var self = this;
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            var url = this.get('downloadUrl');
            window.$.ajax({
              url: url,
              headers: {
                Authorization: headerValue
              },
              type: 'GET',
              xhr: function () {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                return xhr;
              }
            }).done(function (data) {
              self.set('downloading', false);
              var blob = new Blob([data]);
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${self.get('full_name')}.pdf`;
              document.body.appendChild(link);
              link.click();
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
              }, 100);
            });
          });
        }
      },
      downloadApplications() {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_download_application');
        } else {
          this.set('downloading', true);
          var self = this;
          var applicationsIDs = [];
          applicationsIDs = this.get('bulkList').map(application => application.id);
          var data = {
            application_ids: applicationsIDs
          };
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            var url = `${this.get('bulkDownloadUrl')}`;
            window.$.ajax({
              url: url,
              headers: {
                Authorization: headerValue
              },
              type: 'POST',
              data: JSON.stringify(data),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).done(function () {
              self.set('downloading', false);
              self.toast.success(`An email with a link to download these PDFs to follow.`);
            });
          });
        }
      },
      downloadProfile(user) {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_download_profile');
        } else {
          this.set('downloading', true);
          var self = this;
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: `${this.get('apiBaseUrl')}/companies/talent/${user.id}/pdf`,
              headers: {
                Authorization: headerValue
              },
              type: 'GET',
              // below is so that we can turn the blob into our pdf
              xhr: function () {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                return xhr;
              }
            }).done(function (data) {
              self.set('downloading', false);
              var blob = new Blob([data]);
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${self.get('full_name')}.pdf`;
              document.body.appendChild(link);
              link.click();
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
              }, 100);
            });
          });
        }
      },
      reloadJob() {
        this.get('job').reload();
      },
      requestBio(application) {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_request');
        } else {
          this.set('sending', true);
          var self = this;
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: `${this.get('apiBaseUrl')}/jobs/${this.get('job.id')}/applications/${application.get('id')}/bio`,
              headers: {
                Authorization: headerValue
              },
              type: 'POST'
            }).done(function () {
              self.set('sending', false);
              self.set('application.bio_requested', true);
              self.toast.success(`Bio requested`);
            });
          });
        }
      },
      requestInterview(application) {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_request');
        } else {
          this.set('sending', true);
          var self = this;
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: `${this.get('apiBaseUrl')}/jobs/${this.get('job.id')}/applications/${application.get('id')}/interview`,
              headers: {
                Authorization: headerValue
              },
              type: 'POST'
            }).done(function () {
              self.set('sending', false);
              self.set('application.interview_requested', true);
              self.toast.success(`Interview requested`);
            });
          });
        }
      },
      requestInterviewBulk() {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'application_detail_request');
        } else {
          this.set('sending', true);
          var bulkListLength = this.get('bulkList').length;
          var applicationsIDs = this.get('bulkList').map(application => application.id);
          var data = {
            application_ids: applicationsIDs
          };
          var self = this;
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: `${this.get('apiBaseUrl')}/jobs/${this.get('job.id')}/applications/interview/bulk`,
              headers: {
                Authorization: headerValue
              },
              type: 'POST',
              data: JSON.stringify(data),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).done(function () {
              self.set('sending', false);
              self.get('bulkList').forEach(application => {
                application.set('interview_requested', true);
              });
              self.toast.success(`Interview requested for ${bulkListLength} talents`);
            });
          });
        }
      },
      shareWithTeamMembers() {
        if (this.get('teamMembers').length > 0) {
          window.$(`#share-talent-team-members_${this.get('id')}`).modal('show');
        } else {
          window.swal("Oops!", "You currently have no staff members.", "warning");
        }
      }
    }
  });
});