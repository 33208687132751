define('jobbio-backend/components/employer-branding/edit-channel', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    address: '',
    longitude: '',
    latitude: '',
    country: '',
    country_short: '',
    tags: null,
    options: null,
    store: Ember.inject.service(),
    saving: false,
    locationUpdating: false,
    error: null,
    init() {
      this._super(...arguments);
      this.set('tags', []);
      this.set('options', {});
      this.set('error', {});
      this.get('store').unloadAll('channel');
      this.get('store').unloadAll('company');
      this.get('store').findRecord('channel', this.get('currentUser.teammember.company.slug')).then(response => {
        this.set('channel', response);
      });
    },
    didInsertElement() {
      window.$('.main-panel').scroll(function () {
        if (!window.$('.spectrum-color-picker-container').hasClass('sp-hidden')) window.$('.spectrum-color-picker-replacer').trigger('click');
      });
    },
    actions: {
      update: function (channel) {
        this.set('saving', true);
        this.set('error', {});
        this.send('updateChannel', channel);
      },
      updateChannel: function (channel) {
        var execute = true;
        // Check if executable
        if (!channel.get('name')) {
          this.set('error.name', 'This is a required field.');
          this.set('saving', false);
          execute = false;
        } else if (channel.get('name').length > 25) {
          this.set('error.name', 'Ensure this field has no more than 25 characters.');
          this.set('saving', false);
          execute = false;
        }

        if (execute) {
          var data = {
            'name': channel.get('name'),
            'caption': channel.get('caption') ? channel.get('caption') : null,
            'colour': this.get('currentUser.teammember.company.colour')
          };
          var url = _environment.default.APP.API_HOST + '/channels';
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            window.$.ajax({
              url: url,
              headers: {
                Authorization: headerValue
              },
              type: 'PATCH',
              data: JSON.stringify(data),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).then(() => {
              window.Intercom('trackEvent', 'channel-description-color');
              if (this.get('image_logo')) {
                window.Intercom('trackEvent', 'logo-image');
                this.send('uploadImage', 'image_logo', this.get('image_logo'));
              } else if (this.get('image_banner')) {
                window.Intercom('trackEvent', 'banner-image');
                this.send('uploadImage', 'image_banner', this.get('image_banner'));
              } else if (this.get('image_card')) {
                window.Intercom('trackEvent', 'tile-image');
                this.send('uploadImage', 'image_card', this.get('image_card'));
              } else if (this.get('locationUpdating')) {
                this.send('updateLocation');
              } else {
                this.set('saving', false);
                this.toast.success('Channel updated');
              }
            }, xhr => {
              this.set('error', xhr.responseText);
            });
          });
        } else {
          window.$('.main-panel').animate({
            scrollTop: 0
          }, 800);
        }
      },
      setImage: function (type, file) {
        file.readAsDataURL().then(function (url) {
          file.set('uploadUrl', url);
        });
        this.set(type, file);
      },
      uploadImage: function (type, file) {
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          var options = {
            headers: {
              Authorization: headerValue
            },
            contentType: 'multipart/form-data',
            method: 'PATCH',
            fileKey: type
          };
          file.upload(_environment.default.APP.API_HOST + '/companies/edit', options).then(response => {
            this.set(type, null);
            if (this.get('image_banner')) {
              this.send('uploadImage', 'image_banner', this.get('image_banner'));
            } else if (this.get('image_card')) {
              this.send('uploadImage', 'image_card', this.get('image_card'));
            } else if (this.get('locationUpdating')) {
              this.send('updateLocation');
            } else {
              this.set('saving', false);
              this.toast.success('Channel updated');
            }
            this.set('currentUser.teammember.company', response.body);
          });
        });
      },
      updateLocation: function () {
        var data = {
          'address': this.get('address'),
          'longitude': this.get('longitude'),
          'latitude': this.get('latitude'),
          'country': this.get('country'),
          'country_short': this.get('country_short')
        };
        var url = _environment.default.APP.API_HOST + '/companies/edit';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'PATCH',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            this.set('currentUser.teammember.company.location.address', this.get('address'));
            this.set('address', '');
            this.set('locationUpdating', false);
            this.set('saving', false);
            this.toast.success('Channel updated');
          }, xhr => {
            this.set('error', xhr.responseText);
          });
        });
      },
      addTag: function (tag) {
        this.get('tags').pushObject(tag);
      },
      removeTagAtIndex: function (index) {
        this.get('tags').removeAt(index);
      },
      didUpdatePlace: function (place) {
        var country, country_short;
        this.set('address', place.place.formatted_address);
        this.set('longitude', place.lng);
        this.set('latitude', place.lat);
        place.place.address_components.forEach(function (element) {
          if (element.types[0] == "country") {
            country = element.long_name;
            country_short = element.short_name;
          }
        });
        this.set('country', country);
        this.set('country_short', country_short);
        this.set('locationUpdating', true);
      },
      invalidUserSelection: function () {},
      invalidateSession: function () {
        this.get('session').invalidate();
        window.location.href = "https://" + _environment.default.APP.DOMAIN;
      }
    }
  });
});