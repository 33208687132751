define('jobbio-backend/components/common/job-edit-details', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment', 'ember-concurrency'], function (exports, _baseComponent, _environment, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    errors: null,
    options: null,
    salaryRanges: Ember.String.w('0-10k 11-20k 21-30k 31-40k 41-50k 51-60k 61-75k 75-100k'),
    is_redirect: Ember.computed('job', function () {
      return this.get('job.company.redirect');
    }),
    convertedSalary: Ember.computed('job', function () {
      var from = this.get('job.salary_from') / 1000;
      var to = this.get('job.salary_to') / 1000;
      return from + "-" + to + "k";
    }),
    selectedSkills: Ember.computed('job.skills', function () {
      var skills = [];
      var jobSkills = this.get('job.skills');
      for (var key in jobSkills) {
        var skill = jobSkills[key];
        if (skill.name != 'ROOT' && skill.name != 'b' && skill.name != 'v') {
          skills.pushObject(skill.name);
        }
      }
      return skills;
    }),
    full_address: Ember.computed('job.location.{country,city,state,address,post_code}', function () {
      return `${this.get('job.location.address')}, ${this.get('job.location.city')}, ${this.get('job.location.state')}, ${this.get('job.location.country')}, ${this.get('job.location.post_code')}`;
    }),
    locationUpdated: Ember.observer('job.location.updated', function () {
      if (this.get('job.location.updated')) {
        this.set('job.location.updated', false);
        this.set('job.isUpdated', true);
      }
    }),
    titleUpdated: Ember.observer('job.title', function () {
      if (window.$(`.${this.get('job.id')}-job-detail-title input`).is(":focus")) {
        this.set('job.isUpdated', true);
      }
    }),
    salaryFromUpdated: Ember.observer('job.salary_from', function () {
      if (window.$(`.${this.get('job.id')}-job-detail-salary .salary_from`).is(":focus")) {
        this.set('job.isUpdated', true);
      }
    }),
    salaryToUpdated: Ember.observer('job.salary_to', function () {
      if (window.$(`.${this.get('job.id')}-job-detail-salary .salary_to`).is(":focus")) {
        this.set('job.isUpdated', true);
      }
    }),
    redirectUpdated: Ember.observer('job.redirect', function () {
      if (window.$(`.${this.get('job.id')}-job-detail-redirect input`).is(":focus")) {
        this.set('job.isUpdated', true);
      }
    }),
    originalJobDescription: Ember.computed('job', function () {
      return this.get('job.description');
    }),
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        content_style: ".mce-content-body {font-size:0.9rem;}",
        plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist | link '
      });
      this.get('store').queryRecord('form', 1).then(response => {
        this.set('form', response);
        var jobTypes = this.get('form.job_types');
        jobTypes.forEach(jobType => {
          if (jobType.name == 'Undefined') {
            jobTypes.splice(jobTypes.indexOf(jobType), 1);
          }
        });
      });
    },
    didRender() {
      // Prevent '-' & '+' inputs in the salary fields
      window.$('input.salary-field').on('keypress keyup blur', function (event) {
        if (event.which == 43 || event.which == 45) {
          event.preventDefault();
        }
      });
      // Clear job description(TinyMCE) undo history when job changed
      window.$('a[id^=job_list_id_]').on('click', function () {
        window.tinymce.activeEditor.undoManager.clear();
      });
      // Disable Close/Duplicate job when focusing on the job description
      var self = this;
      window.$(`.${this.get('job.id')}-job-detail-description iframe`).contents().find('#tinymce').on('focusin', function () {
        self.set('descriptionWasUpdated', true);
      });
      window.$(`.${this.get('job.id')}-job-detail-description iframe`).contents().find('#tinymce').on('focusout blur', function () {
        if (self.get('originalJobDescription') == self.get('job.description')) {
          self.set('descriptionWasUpdated', false);
        }
      });
      if (this.get('job.description') == '-') {
        this.set('job.description', '');
      }
    },
    actions: {
      setCurrentJob(job) {
        this.set('currentJob', job);
        window.$('#editLocationPopupSelect').val(job.get('location.country'));
      },
      updateDescription(description) {
        this.set('job.description', description);
        this.set('job.isUpdated', true);
      },
      selectContract(contract) {
        this.set('job.contract', contract);
        this.set('job.isUpdated', true);
      },
      selectLevel(level) {
        this.set('job.level', level);
        this.set('job.isUpdated', true);
      },
      selectType(typeId) {
        var type = null;
        var jobTypes = this.get("form.job_types");
        for (var key in jobTypes) {
          var jobType = jobTypes[key];
          if (typeId == jobType.id) {
            type = jobType;
          }
        }
        this.set('job.type', type);
        this.set('job.isUpdated', true);
      },
      selectCurrency(currency) {
        this.set('job.currency', currency);
        this.set('job.isUpdated', true);
      },
      selectSalaryType(type) {
        this.set('job.salary_type', type);
        this.set('job.isUpdated', true);
      },
      selectSalaryDisclosed(salaryDisclosed) {
        var disclosed = salaryDisclosed == "true";
        this.set('job.salary_disclosed', disclosed);
        this.set('job.isUpdated', true);
      },
      updateJob() {
        var isValid = true;
        var errors = {};
        var scrollLength = null;
        var id = this.get('job.id');
        if (!this.get('job.title')) {
          isValid = false;
        } else if (this.get('job.title').length >= 100) {
          isValid = false;
        }

        if (!this.get('job.description')) {
          errors['description'] = 'This is a required field';
          isValid = false;
          scrollLength = window.$(`.${id}-job-detail-description`).position().top;
        }

        if (this.get('job.skill_names') != undefined || this.get('job.skills').length == 0) {
          if (!this.get('job.skill_names')) {
            errors['skills'] = 'This is a required field';
            isValid = false;
            scrollLength = window.$(`.${id}-job-detail-skills`).position().top;
          }
        }

        if (!this.get('job.location.address') || !this.get('job.location.city') || !this.get('job.location.state') || !this.get('job.location.country') || !this.get('job.location.post_code') || this.get('job.location.address') == '-' || this.get('job.location.city') == '-' || this.get('job.location.state') == '-' || this.get('job.location.country') == '-' || this.get('job.location.post_code') == '-') {
          errors['address'] = 'This is a required field';
          isValid = false;
          scrollLength = window.$(`.${id}-job-detail-location`).position().top;
        }

        var fields = ['salary_from', 'salary_to', 'salary_type'];
        fields.forEach(field => {
          if (!this.get(`job.${field}`)) {
            errors[field] = 'This is a required field';
            isValid = false;
            scrollLength = window.$(`.${id}-job-detail-salary`).position().top;
          }
        });

        if (this.get('job.salary_from') && this.get('job.salary_to')) {
          if (parseInt(this.get('job.salary_from')) > parseInt(this.get('job.salary_to'))) {
            errors['salary_to'] = 'To salary cannot be less than From salary';
            isValid = false;
            scrollLength = window.$(`.${id}-job-detail-salary`).position().top;
          }
        }

        if (parseInt(this.get('job.salary_from')) <= 0) {
          errors['salary_from'] = 'This value must be greater than zero';
          isValid = false;
          scrollLength = window.$(`.${id}-job-detail-salary`).position().top;
        }

        if (parseInt(this.get('job.salary_to')) <= 0) {
          errors['salary_to'] = 'This value must be greater than zero';
          isValid = false;
          scrollLength = window.$(`.${id}-job-detail-salary`).position().top;
        }

        if (this.get('is_redirect') && this.get('job.redirect')) {
          var reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
          if (!reg.test(this.get('job.redirect'))) {
            errors['redirect'] = 'Please enter a valid URL';
            isValid = false;
            scrollLength = window.$(`.${id}-job-detail-redirect`).position().top;
          }
        }

        this.set('job.errors', errors);

        if (isValid) {
          this.get('job').save().then(() => {
            this.toast.success('Your job has been updated');
            this.set('job.isUpdated', undefined);
            this.set('descriptionWasUpdated', undefined);
            this.set('skillLengthError', '');
          });
        } else {
          window.$('.job-detail-wrapper').animate({
            scrollTop: scrollLength
          }, 800);
        }
      },
      createSkillOnEnter(select, e) {
        var error = '';
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          if (select.searchText.length < 2) {
            error = 'The skill name must be at least 2 characters long.';
            window.$('.job-detail-wrapper').animate({
              scrollTop: window.$(`.${this.get('job.id')}-job-detail-skills`).position().top
            }, 800);
          } else {
            let selected = this.get('selectedSkills');
            if (!selected.includes(select.searchText)) {
              select.actions.choose(select.searchText);
            }
          }
          this.set('skillLengthError', error);
        }
      },
      updateSkills(options) {
        var error = '';
        var isValid = true;
        var last_skill = options[options.length - 1];
        if (last_skill) {
          if (last_skill.length < 2) {
            error = 'The skill name must be at least 2 characters long.';
            isValid = false;
            window.$('.job-detail-wrapper').animate({
              scrollTop: window.$(`.${this.get('job.id')}-job-detail-skills`).position().top
            }, 800);
          }
        }
        if (isValid) {
          var skills = options.join(',');
          this.set('job.skill_names', skills);
          this.set('job.isUpdated', true);
          skills = [];
          options.forEach(skill => {
            skills.pushObject({ name: skill });
          });
          this.set('job.skills', skills);
        }
        this.set('skillLengthError', error);
      }
    },
    searchSkills: (0, _emberConcurrency.task)(function* (term) {
      var url = _environment.default.APP.API_HOST + '/skills?search=' + term;
      yield (0, _emberConcurrency.timeout)(300);
      return window.$.ajax({
        url: url,
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (json) {
        var skills = [term];
        // BUG: adds 'ROOT' to skills due to key in json returning a ROOT object
        for (var key in json) {
          var item = json[key];
          if (item["name"] != 'ROOT' && item["name"] != 'b' && item["name"] != 'v' && item["name"] != term) {
            skills.pushObject(item["name"]);
          }
        }
        return skills;
      });
    })
  });
});