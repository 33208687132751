define('jobbio-backend/routes/company/employer-branding', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        currentUser: Ember.inject.service(),
        afterModel() {
            // Initialize the currentUser
            this.get('store').unloadAll('teammember');
            this.get('currentUser').load();

            window.$('.main-panel').scrollTop(0);
        }
    });
});