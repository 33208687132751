define('jobbio-backend/components/company/post-job', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment', 'ember-concurrency', 'pell'], function (exports, _baseComponent, _environment, _emberConcurrency, _pell) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    subscription: Ember.inject.service(),
    errors: null,
    salaryRanges: Ember.String.w('0-10k 11-20k 21-30k 31-40k 41-50k 51-60k 61-75k 75k+ hourly'),
    content: null,
    questions: null,
    contract: '',
    level: '',
    salary_from: null,
    salary_to: null,
    salary_disclosed: true,
    salary_type: null,
    currency: '',
    status: 'draft',
    skills: null,
    selectedSkills: null,
    full_address: '',
    address: '',
    country_short: '',
    country: '',
    post_code: '',
    state: '',
    city: '',
    is_redirect: false,
    saving: false,
    options: null,
    onQuestionAdded: Ember.observer('questionAdded', function () {
      this.get('store').query('question', {}).then(response => {
        var questions = response.toArray().reverse();
        this.set('model_questions', questions);
        Ember.run.later(function () {
          window.$('.job-question-checkbox').first().click();
        }, 500);
      });
    }),
    onContentAdded: Ember.observer('contentAdded', function () {
      this.get('store').query('job-content', {}).then(response => {
        var contents = response.toArray().reverse();
        this.set('model_contents', contents);
        Ember.run.later(function () {
          window.$('.job-content-checkbox').first().click();
        }, 500);
      });
    }),
    init() {
      this._super(...arguments);
      this.get('subscription').load();
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        content_style: ".mce-content-body {font-size:0.9rem;}",
        plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist | link '
      });
      this.set('optionsPell', {
        actions: [{
          name: 'bold',
          icon: '<span><svg width="24" height="24"><path d="M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 01-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4z" fill-rule="evenodd"></path></svg></span>',
          result: () => (0, _pell.exec)('bold')
        }, {
          name: 'ulist',
          icon: '<span><svg width="24" height="24"><path d="M11 5h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zm0 6h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 010-2zM4.5 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1zm0 6c0-.4.1-.8.4-1 .3-.4.7-.5 1.1-.5.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .4-.1.8-.4 1-.3.4-.7.5-1.1.5-.4 0-.8-.1-1-.4-.4-.3-.5-.7-.5-1.1z" fill-rule="evenodd"></path></svg></span>',
          result: () => (0, _pell.exec)('insertUnorderedList')
        }, {
          name: 'link',
          icon: '<span><svg width="24" height="24"><path d="M6.2 12.3a1 1 0 011.4 1.4l-2.1 2a2 2 0 102.7 2.8l4.8-4.8a1 1 0 000-1.4 1 1 0 111.4-1.3 2.9 2.9 0 010 4L9.6 20a3.9 3.9 0 01-5.5-5.5l2-2zm11.6-.6a1 1 0 01-1.4-1.4l2-2a2 2 0 10-2.6-2.8L11 10.3a1 1 0 000 1.4A1 1 0 119.6 13a2.9 2.9 0 010-4L14.4 4a3.9 3.9 0 015.5 5.5l-2 2z" fill-rule="nonzero"></path></svg></span>',
          result: () => {
            const url = window.prompt('Enter the link URL');
            if (url) (0, _pell.exec)('createLink', url);
          }
        }]
      });
      this.set('content', []);
      this.set('questions', []);
      this.set('skills', []);
      this.set('selectedSkills', []);
      var jobTypes = this.get('model.form.job_types');
      jobTypes.forEach(jobType => {
        if (jobType.name == 'Undefined') {
          jobTypes.splice(jobTypes.indexOf(jobType), 1);
        }
      });
      this.set('is_redirect', this.get('currentUser.teammember.company.redirect'));
      // get contents and questions, and reverse them
      this.get('store').query('question', {}).then(response => {
        var questions = response.toArray().reverse();
        this.set('model_questions', questions);
      });
      this.get('store').query('job-content', {}).then(response => {
        var contents = response.toArray().reverse();
        this.set('model_contents', contents);
      });
    },
    didRender() {
      // Prevent '-' & '+' inputs in the salary fields
      window.$('input.salary-field').on('keypress keyup blur', function (event) {
        if (event.which == 43 || event.which == 45) {
          event.preventDefault();
        }
      });
      if (this.get('currentUser.teammember.company.status') == 'frozen') {
        window.swal({
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: false,
          text: "Your account is now frozen. Please contact your relationship manager to unlock your account",
          icon: "warning"
        });
      }
    },
    actions: {
      post_job: function () {
        this.set('status', 'active');
        if (this.get('subscription.companySubscription.credits') === -1 || this.get('subscription.companySubscription.credits') > 0) {
          this.send('create_job');
        } else {
          window.swal({
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: { confirm: {
                text: 'Ok',
                closeModal: true,
                className: "btn btn-warning dashboard_activate_swal_confirm"
              } },
            text: "You've ran out of job credits. Please contact your relationship manager to get more credits.",
            icon: "warning"
          });
        }
      },
      draft_job: function () {
        this.set('status', 'draft');
        this.send('create_job');
      },
      create_job: function () {
        var job = this.getProperties('title', 'address', 'redirect');
        job.description = this.get('description');
        job.content = this.get('content');
        job.questions = this.get('questions');
        job.contract = this.get('contract');
        job.level = this.get('level');
        job.country = this.get('country');
        job.country_short = this.get('country_short');
        job.post_code = this.get('post_code');
        job.state = this.get('state');
        job.city = this.get('city');
        job.salary_from = this.get('salary_from');
        job.salary_to = this.get('salary_to');
        job.salary_disclosed = this.get('salary_disclosed');
        job.salary_type = this.get('salary_type');
        job.currency = this.get('currency');
        job.status = this.get('status');
        job.job_type_id = this.get('type');
        job.skills = this.get('selectedSkills').join(", ");
        var self = this;
        var swalText = "";
        var new_job = this.get('store').createRecord('job', job);
        new_job.validate().then(({ validations }) => {
          this.set('errors', {});
          var errors = {};
          var scrollLength = 10000;
          var position = null;

          validations.get('errors').forEach(error => {
            var field = error.attribute;
            if (error.type == 'presence') {
              errors[field] = 'This is a required field';
            } else {
              errors[field] = error.message;
            }
            if (field == 'salary_from' || field == 'salary_to' || field == 'salary_type') {
              field = 'job_type_id';
            }
            if (field == 'description') {
              field = 'skills';
            }
            position = window.$('#post_job_' + field).position();
            if (position) {
              if (position.top != 0 && position.top < scrollLength) {
                scrollLength = position.top;
              }
            }
          });

          this.set('errors', errors);

          if (scrollLength < 10000) {
            window.$('.main-panel').animate({
              scrollTop: scrollLength
            }, 800);
          }

          if (validations.get('isValid')) {
            this.set('saving', true);
            new_job.save().then(() => {
              var tabName = null;
              if (self.get('status') === 'active') {
                window.Intercom('trackEvent', 'job-post');
                let x = self.get('subscription.companySubscription.credits');
                self.set('subscription.companySubscription.credits', x === 0 || x === -1 ? x : x - 1);
                self.set('currentUser.teammember.company.live_jobs', self.get('currentUser.teammember.company.live_jobs') + 1);
              } else {
                tabName = 'draft_jobs';
              }
              if (self.get('questions').length > 0 && self.get('content').length > 0) {
                swalText = "Your job is now live";
                if (self.get('status') == 'draft') {
                  swalText = "Your job is now saved as a draft job";
                }
                window.swal({
                  title: "Congrats",
                  text: swalText,
                  icon: "success",
                  buttons: {
                    post_job: {
                      text: "Post Another Job",
                      visible: true,
                      closeModal: true,
                      className: "btn btn-secondary post_job_swal_post"
                    },
                    view_job: {
                      text: "View Job",
                      className: "btn btn-warning post_job_swal_view"
                    }
                  }
                }).then(value => {
                  switch (value) {
                    case "post_job":
                      self.send('clearJob');
                      break;
                    case "view_job":
                      self.set('currentUser.teammember.company.jobPosted', true);
                      self.get('router').transitionTo('company.jobs', { queryParams: { tab: tabName } });
                      break;
                    default:
                      self.send('clearJob');
                      break;
                  }
                });
              } else if (self.get('questions').length == 0 && self.get('content').length == 0) {
                swalText = "Your job is now live, however you have not added any questions or content to your job";
                if (self.get('status') == 'draft') {
                  swalText = "Your job is now saved as a draft job, however you have not added any questions or content to your job";
                }
                window.swal({
                  title: "Heads Up",
                  text: swalText,
                  icon: "success",
                  buttons: {
                    cancel: {
                      text: "Close",
                      visible: true,
                      closeModal: true,
                      className: "btn btn-secondary post_job_swal_cancel"
                    },
                    update_job: {
                      text: "Update Job",
                      className: "btn btn-warning post_job_swal_update"
                    }
                  }
                }).then(value => {
                  switch (value) {
                    case "update_job":
                      self.set('currentUser.teammember.company.jobPosted', true);
                      self.get('router').transitionTo('company.jobs', { queryParams: { tab: tabName } });
                      break;
                    case "cancel":
                      self.send('clearJob');
                      break;
                    default:
                      self.send('clearJob');
                      break;
                  }
                });
              } else if (self.get('questions').length == 0) {
                swalText = "Your job is now live, however you have not added any questions to your job";
                if (self.get('status') == 'draft') {
                  swalText = "Your job is now saved as a draft job, however you have not added any questions to your job";
                }
                window.swal({
                  title: "Heads Up",
                  text: swalText,
                  icon: "success",
                  buttons: {
                    cancel: {
                      text: "Close",
                      visible: true,
                      closeModal: true,
                      className: "btn btn-secondary post_job_swal_cancel"
                    },
                    update_job: {
                      text: "Update Job",
                      className: "btn btn-warning post_job_swal_update"
                    }
                  }
                }).then(value => {
                  switch (value) {
                    case "update_job":
                      self.set('currentUser.teammember.company.jobPosted', true);
                      self.get('router').transitionTo('company.jobs', { queryParams: { tab: tabName } });
                      break;
                    case "cancel":
                      self.send('clearJob');
                      break;
                    default:
                      self.send('clearJob');
                      break;
                  }
                });
              } else if (self.get('content').length == 0) {
                swalText = "Your job is now live, however you have not added any content to your job";
                if (self.get('status') == 'draft') {
                  swalText = "Your job is now saved as a draft job, however you have not added any content to your job";
                }
                window.swal({
                  title: "Heads Up",
                  text: swalText,
                  icon: "success",
                  buttons: {
                    cancel: {
                      text: "Close",
                      visible: true,
                      closeModal: true,
                      className: "btn btn-secondary post_job_swal_cancel"
                    },
                    update_job: {
                      text: "Update Job",
                      className: "btn btn-warning post_job_swal_update"
                    }
                  }
                }).then(value => {
                  switch (value) {
                    case "update_job":
                      self.set('currentUser.teammember.company.jobPosted', true);
                      self.get('router').transitionTo('company.jobs', { queryParams: { tab: tabName } });
                      break;
                    case "cancel":
                      self.send('clearJob');
                      break;
                    default:
                      self.send('clearJob');
                      break;
                  }
                });
              }
            });
          }
        });
      },
      selectContract: function (contract) {
        this.set('contract', contract);
      },
      selectLevel: function (level) {
        this.set('level', level);
      },
      selectType: function (type) {
        this.set('type', type);
      },
      selectSalaryType: function (type) {
        this.set('salary_type', type);
      },
      selectSalaryDisclosed: function (salaryDisclosed) {
        var disclosed = salaryDisclosed == 'true';
        this.set('salary_disclosed', disclosed);
      },
      selectCurrency: function (cur) {
        this.set('currency', cur);
      },
      selectQuestion: function (question) {
        if (this.get('questions').indexOf(parseInt(question)) > -1) {
          this.get('questions').removeObject(parseInt(question));
        } else {
          this.get('questions').pushObject(parseInt(question));
        }
      },
      selectAdminQuestion: function (question) {
        if (this.get('questions').indexOf(parseInt(question)) > -1) {
          this.get('questions').removeObject(parseInt(question));
        } else {
          this.get('questions').pushObject(parseInt(question));
        }
      },
      selectContent: function (content) {
        if (this.get('content').indexOf(parseInt(content)) > -1) {
          this.get('content').removeObject(parseInt(content));
        } else {
          this.get('content').pushObject(parseInt(content));
        }
      },
      createSkillOnEnter(select, e) {
        var error = '';
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          if (select.searchText.length < 2) {
            error = 'The skill name must be at least 2 characters long.';
          } else {
            let selected = this.get('selectedSkills');
            if (!selected.includes(select.searchText)) {
              select.actions.choose(select.searchText);
            }
          }
          this.set('skillLengthError', error);
        }
      },
      updateSkills: function (options) {
        var error = '';
        var isValid = true;
        var last_skill = options[options.length - 1];
        if (last_skill) {
          if (last_skill.length < 2) {
            error = 'The skill name must be at least 2 characters long.';
            isValid = false;
          }
        }
        if (isValid) {
          this.set('selectedSkills', options);
        }
        this.set('skillLengthError', error);
      },
      clearJob() {
        this.set('title', '');
        this.set('description', '');
        this.set('full_address', '');
        this.set('address', '');
        this.set('content', []);
        this.set('questions', []);
        this.set('contract', '');
        this.set('level', '');
        this.set('country', '');
        this.set('country_short', '');
        this.set('post_code', '');
        this.set('state', '');
        this.set('city', '');
        this.set('salary_from', '');
        this.set('salary_to', '');
        this.set('salary_disclosed', true);
        this.set('salary_type', '');
        this.set('currency', '');
        this.set('status', '');
        this.set('type', '');
        this.set('selectedSkills', []);
        this.set('redirect', '');
        this.set('errors', null);
        this.set('skillLengthError', '');
        this.set('jobCleared', !this.get('jobCleared'));
        window.$('.job-question-checkbox').attr('checked', false);
        window.$('.job-content-checkbox').attr('checked', false);
        window.$('#post_job_contract').val(window.$("#post_job_contract option:first").val());
        window.$('#post_job_level').val(window.$("#post_job_level option:first").val());
        window.$('#post_job_job_type_id').val(window.$("#post_job_job_type_id option:first").val());
        window.$('#post_job_salary_type_id').val(window.$("#post_job_salary_type_id option:first").val());
        window.$('#post_job_currency').val(window.$("#post_job_currency option:first").val());
        window.$('select[name=salary_disclosed]').val(window.$("select[name=salary_disclosed] option:first").val());
        window.$('.main-panel').animate({
          scrollTop: 0
        }, 800);
        this.set('saving', false);
      },
      openQuestionPopup: function () {
        this.set('isQuestionPopupOpened', !this.get('isQuestionPopupOpened'));
      },
      openContentBankPopup: function () {
        this.set('isContentBankPopupOpened', !this.get('isContentBankPopupOpened'));
      }
    },
    searchSkills: (0, _emberConcurrency.task)(function* (term) {
      var url = _environment.default.APP.API_HOST + '/skills?search=' + term;
      yield (0, _emberConcurrency.timeout)(300);
      return window.$.ajax({
        url: url,
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (json) {
        var skills = [term];
        // BUG: adds 'ROOT' to skills due to key in json returning a ROOT object
        for (var key in json) {
          var item = json[key];
          if (item["name"] != 'ROOT' && item["name"] != 'b' && item["name"] != 'v' && item["name"] != term) {
            skills.pushObject(item["name"]);
          }
        }
        return skills;
      });
    })
  });
});