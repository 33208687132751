define('jobbio-backend/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'jobbio-backend/config/environment'], function (exports, _applicationRouteMixin, _authenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    beforeModel() {
      this._super(...arguments);
      var sess = this.get('session').session.content.authenticated;
      var url = _environment.default.APP.API_HOST + '/users/' + sess.company_member.user.id;
      if (!sess.company_member) {
        window.location.href = `https://${_environment.default.APP.DOMAIN}`;
      }
      this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
        window.$.ajax({
          url: url,
          headers: {
            Authorization: headerValue
          },
          type: 'GET',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        }).then(response => {
          if (response.company_member.company.status == 'pending') {
            window.location.assign("https://" + _environment.default.APP.DOMAIN + "/company/onboarding/job?oid=" + sess.company_member.company.id);
          }
        }, () => {
          window.location.assign("https://" + _environment.default.APP.DOMAIN + "/company/onboarding/job?oid=" + sess.company_member.company.id);
        });
      });
      return this.get('currentUser').load();
    },
    triggerAuthentication: function () {
      let authenticationRoute = this.get('authenticationRoute');
      window.location.assign(authenticationRoute);
    },
    afterModel: function () {
      var APP_ID = _environment.default.APP.INTERCOM;
      window.$("head").append(`
      <script>
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
      </script>
    `);
      window.Intercom('update');
      window.Intercom('boot', {
        app_id: APP_ID,
        action_color: "#ff4f4f",
        email: this.get('currentUser.teammember.user.email'),
        phone: this.get('currentUser.teammember.user.phone'),
        user_id: this.get('currentUser.teammember.user.id'),
        name: `${this.get('currentUser.teammember.user.first_name')} ${this.get('currentUser.teammember.user.last_name')}`,
        utm_source: this.get('currentUser.teammember.company.source'),
        company: { company_id: this.get('currentUser.teammember.company.id'),
          name: this.get('currentUser.teammember.company.name'),
          created_at: this.get('currentUser.teammember.company.created_at') }
      });
    },
    actions: {
      // ember-route-action-helper bubbles this from the send action button
      sendMessage(user, type) {
        if (type == 'company_member') {
          user.set('unread', 0);
          user = user.get('user');
        }
        this.controller.set('conversation', null);
        this.controller.set('messageFormOpened', !this.controller.get('messageFormOpened'));

        // we need to get the conversation for this changeStage
        var params = { "participantId": user.id };
        this.get('store').createRecord("conversation", params).save().then(conversation => {
          // all we need to do is update the conversation wit hthe returned one from API
          // as this will have the correct id and our chat list component wil lload the message
          // we just sent
          this.controller.set('conversation', conversation);
          if (user.role == 'talent') {
            this.controller.set('updateConversationList', !this.controller.get('updateConversationList'));
          }
        });
      },
      openConversation(conversation) {
        this.controller.set('conversation', conversation);
        this.controller.set('messageFormOpened', !this.controller.get('messageFormOpened'));
      }
    }
  });
});