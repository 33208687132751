define('jobbio-backend/serializers/drf', ['exports', 'ember-django-adapter/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    extractPageNumber: function (url) {
      var match = /cursor=(.*?)&/gm.exec(url);
      var matchEndOfLine = /cursor=(.*)/.exec(url);
      if (match) {
        return match[1];
      } else if (matchEndOfLine) {
        return matchEndOfLine[1];
      }
      return null;
    }
  });
});