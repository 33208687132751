define('jobbio-backend/services/subscription', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),

    load() {
      return this.get('store').queryRecord('subscription', {}).then(response => {
        this.set('companySubscription', response);
      });
    }
  });
});