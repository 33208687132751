define('jobbio-backend/components/application/application-search', ['exports', 'jobbio-backend/components/active-pagination-component'], function (exports, _activePaginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activePaginationComponent.default.extend({
    modelType: 'job-application',
    autoLoad: false,
    isClosedFeed: Ember.computed('job.status', function () {
      return this.get('job.status') == 'closed';
    }),
    onAttrsChanged: Ember.observer('job', 'selectedTag', 'selectedStage', 'selectedApplicationType', function () {
      this.get('resetSearch').perform();
    }),
    onJobChanged: Ember.observer('job.id', 'selectedApplicationType', function () {
      this.set('searchTerm', '');
    }),
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['job_id'] = this.get('job.id');
      if (this.get('selectedStage')) {
        params['stage'] = this.get('selectedStage.id');
      }
      if (this.get('selectedTag')) {
        params['tag'] = this.get('selectedTag.id');
      }
      if (this.get('selectedApplicationType')) {
        params['status'] = this.get('selectedApplicationType');
      }
      return params;
    }),
    actions: {
      loaded() {
        var application = this.get('objects.firstObject');
        this.set('selectedApplication', application);
        this.set('applications', this.get('objects'));
        this.set('bulkList', []);
      },
      selectApplication(application) {
        this.set('selectedApplication', application);
      },
      bulkList(application) {
        if (this.get('bulkList').includes(application)) {
          this.get('bulkList').removeObject(application);
        } else {
          this.get('bulkList').pushObject(application);
        }
      },
      allBulkList(applications) {
        if (this.get('bulkList.length') == applications.length) {
          this.set('bulkList', []);
        } else {
          this.set('bulkList', []);
          applications.forEach(application => {
            this.get('bulkList').pushObject(application);
          });
        }
      }
    }
  });
});