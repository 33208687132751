define('jobbio-backend/components/settings/greenhouse-integration', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);
      var self = this;
      this.set('user', this.get('user_id'));
      this.set('source', this.get('source_id'));
      var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/greenhouse/users';
      this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
        window.$.ajax({
          url: url,
          headers: {
            Authorization: headerValue
          },
          type: 'GET',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        }).then(response => {
          self.set('users', response.users);
        });
      });
      url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/greenhouse/sources';
      this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
        window.$.ajax({
          url: url,
          headers: {
            Authorization: headerValue
          },
          type: 'GET',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        }).then(response => {
          self.set('sources', response.sources);
        });
      });
    },
    actions: {
      selectUser(user) {
        this.set('user_id', user);
        this.set('user', user);
      },
      selectSource(source) {
        this.set('source_id', source);
        this.set('source', source);
      },
      updateGreenhouse() {
        var self = this;
        var data = this.getProperties('user', 'source', 'board');
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/greenhouse';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'PATCH',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.toast.success('Greenhouse details updated');
          });
        });
      },
      deleteGreenhouse: function () {
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/greenhouse';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.toast.success('Greenhouse access revoked');
          });
        });
      }
    }
  });
});