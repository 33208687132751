define('jobbio-backend/components/company/post-content-bank', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    errors: null,
    options: null,
    onContentBankPopupOpened: Ember.observer('isContentBankPopupOpened', function () {
      this.send('clearContentBank');
    }),
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist | link '
      });
      this.set('errors', {
        'text': {},
        'image': {},
        'video': {}
      });
    },
    actions: {
      text: function () {
        var content = {};
        content.title = this.get('content_title');
        content.text = this.get('content_text');
        content.type = "text";
        content.in_bank = true;
        var new_text = this.get('store').createRecord('job_content', content);
        if (this.get('content_title') && this.get('content_text')) {
          new_text.save().then(response => {
            if (this.get('view') == 'content-bank') {
              this.get('content').unshiftObject(response);
            } else if (this.get('view') == 'post-job') {
              this.set('contentAdded', !this.get('contentAdded'));
            }
          });
          var text = "";
          if (this.get('view') == "post-job") {
            text = "Your content has been added to your content bank and this job.";
          } else if (this.get("view") == "content-bank") {
            text = "Your content has been added to your content bank.";
          }
          window.swal({
            title: "Congrats!",
            text: text,
            icon: "success",
            buttons: {
              cancel: {
                text: "Close",
                visible: true,
                closeModal: true,
                className: "btn btn-secondary post_content_text_swal_cancel"
              },
              confirm: {
                text: "Add More Content",
                closeModal: true,
                className: "btn btn-warning post_content_text_swal_confirm"
              }
            }
          }).then(willAdd => {
            if (!willAdd) {
              window.$('#postToContentBank .close').click();
            }
            this.send('clearContentBank');
          });
        }
        if (this.get('content_title')) {
          this.set('errors.text.title', '');
        } else {
          this.set('errors.text.title', 'This field cannot be empty');
        }
        if (this.get('content_text')) {
          this.set('errors.text.text', '');
        } else {
          this.set('errors.text.text', 'This field cannot be empty');
        }
      },
      image: function () {
        var content = {};
        content.title = this.get('image_title');
        content.text = this.get('image_text');
        content.type = "image";
        content.in_bank = true;
        if (this.get('image_title') && this.get('image') && this.get('image_text')) {
          this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
            var options = {
              headers: {
                Authorization: headerValue
              },
              contentType: 'multipart/form-data',
              method: 'POST',
              fileKey: 'image',
              data: content
            };
            this.get('image').upload(_environment.default.APP.API_HOST + '/jobs/content', options).then(() => {
              if (this.get('view') == 'content-bank') {
                this.get('store').query('job-content', {}).then(response => {
                  var content = response.toArray().reverse();
                  this.set('content', content);
                });
              } else if (this.get('view') == 'post-job') {
                this.set('contentAdded', !this.get('contentAdded'));
              }
              if (this.get('view') == "post-job") {
                window.swal({
                  title: "Congrats!",
                  text: "Your content has been added to your content bank and this job. Do you want to add another piece of content?",
                  icon: "success",
                  buttons: {
                    cancel: {
                      text: "Close",
                      visible: true,
                      closeModal: true,
                      className: "btn btn-secondary post_content_image_swal_cancel"
                    },
                    confirm: {
                      text: "Add More Content",
                      closeModal: true,
                      className: "btn btn-warning post_content_image_swal_confirm"
                    }
                  }
                }).then(willAdd => {
                  if (!willAdd) {
                    window.$('#postToContentBank .close').click();
                  }
                  this.send('clearContentBank');
                });
              } else if (this.get("view") == "content-bank") {
                window.swal({
                  title: "Congrats!",
                  text: "Your content has been added to your content bank. Do you want to add another piece of content?",
                  icon: "success",
                  buttons: {
                    cancel: {
                      text: "Close",
                      visible: true,
                      closeModal: true,
                      className: "btn btn-secondary post_content_image_swal_cancel"
                    },
                    confirm: {
                      text: "Add More Content",
                      closeModal: true,
                      className: "btn btn-warning post_content_image_swal_confirm"
                    }
                  }
                }).then(willAdd => {
                  if (!willAdd) {
                    window.$('#postToContentBank .close').click();
                  }
                  this.send('clearContentBank');
                });
              }
            });
          });
        }
        if (this.get('image_title')) {
          this.set('errors.image.title', '');
        } else {
          this.set('errors.image.title', 'This field cannot be empty');
        }
        if (this.get('image')) {
          this.set('errors.image.image', '');
        } else {
          this.set('errors.image.image', 'Your file cannot be empty');
        }
        if (this.get('image_text')) {
          this.set('errors.image.text', '');
        } else {
          this.set('errors.image.text', 'This field cannot be empty');
        }
      },
      video: function () {
        var content = this.getProperties('video');
        content.title = this.get('video_title');
        content.type = "video";
        content.in_bank = true;
        var validYoutubeURL = /^(https\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(content.video);
        var validVimeoURL = /^(https\:\/\/)?(player\.vimeo\.com)\/.+$/.test(content.video);
        if (this.get('video_title') && this.get('video') && (validYoutubeURL || validVimeoURL)) {
          var video = this.get('store').createRecord('job_content', content);
          video.save().then(response => {
            if (this.get('view') == 'content-bank') {
              this.get('content').unshiftObject(response);
            } else if (this.get('view') == 'post-job') {
              this.set('contentAdded', !this.get('contentAdded'));
            }
          });
          if (this.get('view') == "post-job") {
            window.swal({
              title: "Congrats!",
              text: "Your content has been added to your content bank and this job. Do you want to add another piece of content?",
              icon: "success",
              buttons: {
                cancel: {
                  text: "Close",
                  visible: true,
                  closeModal: true,
                  className: 'post_content_video_swal_cancel'
                },
                confirm: {
                  text: "Add More Content",
                  closeModal: true,
                  className: 'post_content_video_swal_confirm'
                }
              }
            }).then(willAdd => {
              if (!willAdd) {
                window.$('#postToContentBank .close').click();
              }
              this.send('clearContentBank');
            });
          } else if (this.get("view") == "content-bank") {
            window.swal({
              title: "Congrats!",
              text: "Your content has been added to your content bank. Do you want to add another piece of content?",
              icon: "success",
              buttons: {
                cancel: {
                  text: "Close",
                  visible: true,
                  closeModal: true
                },
                confirm: {
                  text: "Add More Content",
                  closeModal: true
                }
              }
            }).then(willAdd => {
              if (!willAdd) {
                window.$('#postToContentBank .close').click();
              }
              this.send('clearContentBank');
            });
          }
        }
        if (this.get('video_title')) {
          this.set('errors.video.title', '');
        } else {
          this.set('errors.video.title', 'This field cannot be empty');
        }
        if (this.get('video')) {
          if (!validYoutubeURL && !validVimeoURL) {
            this.set('errors.video.url', 'Please enter a valid URL');
          } else {
            this.set('errors.video.url', '');
          }
        } else {
          this.set('errors.video.url', 'This field cannot be empty');
        }
      },
      clearContentBank: function () {
        this.setProperties({
          content_title: '',
          image_title: '',
          video_title: '',
          image: '',
          video: '',
          content_text: '',
          image_text: ''
        });
        this.set('errors', {
          'text': {},
          'image': {},
          'video': {}
        });
      },
      uploadImage: function (file) {
        this.set('image', file);
      }
    }
  });
});