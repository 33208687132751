define('jobbio-backend/components/common/job-log', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    text: Ember.computed('log.id', function () {
      var status = '';
      if (this.get('log.status') == 'job_created') {
        status = "was created.";
      } else if (this.get('log.status') == 'job_paused') {
        status = "was paused.";
      } else if (this.get('log.status') == 'job_un-paused') {
        status = "was unpaused.";
      } else if (this.get('log.status') == 'job_published') {
        status = "was published.";
      } else if (this.get('log.status') == 'job_closed') {
        status = "was closed.";
      }
      return "Job " + status;
    })
  });
});