define('jobbio-backend/components/settings/edit-account', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    savingEmail: false,
    savingNotifications: false,
    init() {
      this._super(...arguments);
      this.get('store').queryRecord('social', {}).then(response => {
        this.set('socialmedia', response);
      });
      this.get('store').queryRecord("gdpr-settings", {}).then(record => {
        this.set("setting", record);
      });
      this.get('store').queryRecord('notification', {}).then(response => {
        this.set('notifications', response);
      });
      this.set('user', this.get('currentUser.teammember.user'));
    },
    actions: {
      email: function (user) {
        var email = this.get('store').createRecord('email', { 'email': user.email });
        email.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = error.message;
          });
          this.set('errors', errors);
          if (validations.get('isValid')) {
            this.set('savingEmail', true);
            email.save().then(() => {
              this.set('savingEmail', false);
              this.get('session').invalidate();
            }).catch(() => {
              this.set('errors.email', 'That email address is already in use.');
              this.set('savingEmail', false);
            });
          }
        });
      },
      answers: function (value) {
        if (value == "on") {
          this.set('setting.answers_top', true);
        } else if (value == "off") {
          this.set('setting.answers_top', false);
        }
        this.get("setting").save().then(() => {
          this.toast.success('Setting updated');
        });
      },
      email_action: function (notifications) {
        notifications.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = error.message;
          });
          this.set('notifications.errors', errors);
          if (validations.get('isValid')) {
            this.set('notifications.errors', {});
            this.set('savingNotifications', true);
            var self = this;
            notifications.save().then(() => {
              Ember.run.later(function () {
                self.toast.success('Notification settings updated');
                self.set('savingNotifications', false);
              }, 500);
            }).catch(() => {
              this.set('savingNotifications', false);
            });
          }
        });
      },
      setAction: function (type, value) {
        this.get('notifications').set(type, !value);
      }
    }
  });
});