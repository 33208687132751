define('jobbio-backend/components/application/job-stage-filter', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    new_stage_name: "",
    onJobChanged: Ember.observer('job.id', 'applicationType', function () {
      this.set('new_stage_name', '');
      this.set('job.stage_error', '');
    }),
    init() {
      this._super(...arguments);
    },
    actions: {
      addStage(stageName, job) {
        if (stageName != '') {
          if (stageName.length < 31) {
            this.get('store').createRecord("stage", {
              name: stageName,
              job_id: job.id
            }).save().then(record => {
              job.get("stages").pushObject(record);
            });
            this.set('new_stage_name', '');
            this.set('job.stage_error', '');
          } else {
            this.set('job.stage_error', 'Ensure this field has no more than 30 characters.');
          }
        } else {
          this.set('job.stage_error', 'This is a required field');
        }
      },
      setSelectedStage(stage) {
        this.set('selectedStage', stage);
      }
    }
  });
});