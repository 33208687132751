define('jobbio-backend/adapters/application', ['exports', 'jobbio-backend/adapters/drf', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _drf, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:token',
    addTrailingSlashes: false,
    // there are certain queries we'll want to intercept
    // so that we can change the url while still using
    // the meber store
    query: function (store, type) {
      switch (type.modelName) {
        case 'feed-item':
          return this.feedItem(...arguments);
        case 'channel-feed-item':
          return this.channelFeedItemQuery(...arguments);
        case 'latest-application':
          return this.latestApplication(...arguments);
        case 'job-application':
          return this.jobApplication(...arguments);
        case 'job':
          return this.jobList(...arguments);
        case 'answer':
          return this.answerList(...arguments);
        case 'application':
          return this.applicationList(...arguments);
        case 'application-history':
          return this.applicationHistoryList(...arguments);
        case 'talent':
          return this.talentList(...arguments);
        case 'talent-shared':
          return this.talentSharedList(...arguments);
        case 'company-member':
          return this.companyMembers(...arguments);
        case 'follower':
          return this.followerList(...arguments);
        case 'tag':
          return this.tagList(...arguments);
        case 'live-job-tag':
          return this.tagList(...arguments);
        case 'paused-job-tag':
          return this.tagList(...arguments);
        case 'closed-job-tag':
          return this.tagList(...arguments);
        case 'talent-pool-tag':
          return this.tagList(...arguments);
        case 'followers-tag':
          return this.tagList(...arguments);
        case 'message':
          return this.messageList(...arguments);
        case 'conversation':
          return this.conversationList(...arguments);
        case 'note':
          return this.noteList(...arguments);
        case 'billing':
          return this.billingInformation(...arguments);
        case 'auto-reply':
          return this.emailTemplates(...arguments);
        case 'question':
          return this.getQuestions(...arguments);
        case 'branding-gallery':
          return this.getBrandingGallery(...arguments);
        case 'branding-content':
          return this.getBrandingContent(...arguments);
        case 'branding-testimonial':
          return this.getBrandingTestimonial(...arguments);
        case 'workable-account':
          return this.workableAccount(...arguments);
        case 'job-content':
          return this.getContentBank(...arguments);
        case 'stage-settings':
          return this.getSettingsStages(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    // same as query but for single record
    queryRecord: function (store, type) {
      switch (type.modelName) {
        case 'form':
          return this.formQuery(...arguments);
        case 'social':
          return this.socialMedia(...arguments);
        case 'subscription':
          return this.subscriptions(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    // same as above but for patching a single record
    updateRecord: function (store, type) {
      switch (type.modelName) {
        case 'job-application':
          return this.updateJobApplication(...arguments);
        case 'message':
          return this.updateMessage(...arguments);
        case 'stage-settings':
          return this.updateStage(...arguments);
        case 'job':
          return this.updateJob(...arguments);
        case 'social':
          return this.updateSocialMedia(...arguments);
        case 'tour':
          return this.updateTour(...arguments);
        case 'notification':
          return this.updateNotifications(...arguments);
        case 'auto-reply':
          return this.updateAutoreply(...arguments);
        case 'billing':
          return this.updateBillingInfo(...arguments);
        case 'gdpr-settings':
          return this.updateGDPRSettings(...arguments);
        case 'talent-pool-tag':
          return this.updateTag(...arguments);
        case 'followers-tag':
          return this.updateTag(...arguments);
        case 'job-content':
          return this.updateContent(...arguments);
        case 'branding-content':
          return this.updateBrandingContent(...arguments);
        case 'branding-gallery':
          return this.updateBrandingGallery(...arguments);
        case 'question':
          return this.updateQuestion(...arguments);
        case 'memberdetail':
          return this.updateTeamMember(...arguments);
        case 'latest-application':
          return this.updateJobApplication(...arguments);
        case 'feed-application':
          return this.updateJobApplication(...arguments);
        case 'workable-account':
          return this.updateWorkableAccount(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    createRecord: function (store, type) {
      switch (type.modelName) {
        case 'duplicated-job':
          return this.duplicateJob(...arguments);
        case 'stage':
          return this.addStage(...arguments);
        case 'talent-pool-tag':
          return this.addTag(...arguments);
        case 'followers-tag':
          return this.addTag(...arguments);
        case 'conversation':
          return this.addConversation(...arguments);
        case 'message':
          return this.addMessage(...arguments);
        case 'note':
          return this.addNote(...arguments);
        case 'branding-gallery':
          return this.addBrandingGallery(...arguments);
        case 'branding-content':
          return this.addBrandingContent(...arguments);
        case 'branding-testimonial':
          return this.addTestimonial(...arguments);
        case 'job-question':
          return this.addJobQuestion(...arguments);
        case 'feed-application':
          return this.updateJobApplication(...arguments);
        case 'password':
          return this.updatePassword(...arguments);
        case 'email':
          return this.updateEmail(...arguments);
        case 'billing':
          return this.addBillingInfo(...arguments);
        case 'memberdetail':
          return this.addTeamMember(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    findRecord: function (store, type) {
      switch (type.modelName) {
        case 'teammember':
          return this.loadTeamMember(...arguments);
        case 'channel-feed-item':
          return this.loadChannel(...arguments);
        case 'job':
          return this.loadJob(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    // same as above but for patching a single record
    deleteRecord: function (store, type) {
      switch (type.modelName) {
        case 'stage-settings':
          return this.deleteStage(...arguments);
        case 'talent-pool-tag':
          return this.deleteTag(...arguments);
        case 'followers-tag':
          return this.deleteTag(...arguments);
        case 'content':
          return this.deleteContent(...arguments);
        case 'branding-content':
          return this.deleteBrandingContent(...arguments);
        case 'branding-gallery':
          return this.deleteBrandingGallery(...arguments);
        case 'question':
          return this.deleteQuestion(...arguments);
        case 'branding-testimonial':
          return this.deleteBrandingTestimonial(...arguments);
        default:
          return this._super(...arguments);
      }
    },
    feedItem(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/feed";
      return this.ajax(url, 'GET', { data: query });
    },
    latestApplication(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/applications";
      return this.ajax(url, 'GET', { data: query });
    },
    jobApplication(store, type, query) {
      var job_id = query.job_id;
      var url = this.get('host') + this.get('namespace') + "/jobs/" + job_id + "/applications";
      return this.ajax(url, 'GET', { data: query });
    },
    updatePassword() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/companies/members";
      var res = this.ajax(url, 'PATCH', { data: data }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
      return res;
    },
    updateEmail() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/companies/members";
      var res = this.ajax(url, 'PATCH', { data: data }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
      return res;
    },
    updateJobApplication(store, type, snapshot) {
      var id = snapshot.id;
      var job_id = null;
      var data = this.getData(...arguments);
      if (data.job_id) {
        job_id = data.job_id;
      } else {
        job_id = data.job.id;
      }

      // api expects stage as id not the object for patching
      if (data.stage) {
        data.stage = data.stage.id;
      } else {
        delete data.stage;
      }

      var url = this.get('host') + this.get('namespace') + "/jobs/" + job_id + "/applications/" + id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateJob(store, type, snapshot) {
      var id = snapshot.id;

      var data = this.getData(...arguments);

      var updateData = { status: data.status,
        title: data.title,
        contract: data.contract,
        level: data.level,
        job_type_id: data.type.id,
        description: data.description,
        currency: data.currency,
        salary_from: data.salary_from,
        salary_to: data.salary_to,
        salary_type: data.salary_type,
        salary_disclosed: data.salary_disclosed,
        skills: data.skill_names,
        address: data.location.address,
        country: data.location.country,
        country_short: data.location.country_short,
        city: data.location.city,
        state: data.location.state,
        post_code: data.location.post_code,
        redirect: data.redirect,
        hired: data.hired
      };

      var url = this.get('host') + this.get('namespace') + "/jobs/" + id;
      return this.ajax(url, 'PATCH', { data: updateData });
    },
    duplicateJob() {
      var data = this.getData(...arguments);
      var job_id = data.selected_job_id;
      data = { title: data.title };
      var url = this.get('host') + this.get('namespace') + "/jobs/" + job_id + "/duplicate";
      return this.ajax(url, 'POST', { data: data });
    },
    updateMessage(store, type, snapshot) {
      var data = this.getData(...arguments);
      var conversation_id = data.conversation_id;
      var message_id = snapshot.id;

      // only want to send over read as being the changed property
      // if we sent the text property api would assume we want to patch it
      delete data.text;

      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages/" + message_id;
      var res = this.ajax(url, 'PATCH', { data: data }).then().catch(() => {
        return null;
      });
      return res;
    },
    jobList(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/jobs";
      return this.ajax(url, 'GET', { data: query });
    },
    formQuery(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/form";
      return this.ajax(url, 'GET', { data: query });
    },
    applicationList(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/applications";
      return this.ajax(url, 'GET', { data: query });
    },
    applicationHistoryList(store, type, query) {
      var talent_id = query.talent_id;
      var url = this.get('host') + this.get('namespace') + "/talent/" + talent_id + "/applications";
      return this.ajax(url, 'GET', { data: query });
    },
    talentList(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/talent";
      return this.ajax(url, 'GET', { data: query });
    },
    answerList(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/applications/" + query.application_id + "/answers";
      return this.ajax(url, 'GET', { data: query });
    },
    talentSharedList(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/applications/share";
      return this.ajax(url, 'GET', { data: query });
    },
    companyMembers(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/members/detail";
      return this.ajax(url, 'GET', { data: query });
    },
    followerList(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/channels/following";
      return this.ajax(url, 'GET', { data: query });
    },
    channelFeedItemQuery() {
      var url = this.get('host') + this.get('namespace') + "/companies/content";
      return this.ajax(url, 'GET');
    },
    tagList(store, type, query) {
      var job_id = query.job_id;
      var talent_id = query.talent_id;
      var url = this.get('host') + this.get('namespace') + "/talent/tags";
      if (job_id) {
        url = this.get('host') + this.get('namespace') + "/jobs/" + job_id + "/tags";
      } else if (talent_id) {
        url = this.get('host') + this.get('namespace') + "/talent/" + talent_id + "/tags";
      }
      return this.ajax(url, 'GET', { data: query });
    },
    messageList(store, type, query) {
      var conversation_id = query.conversation_id;
      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages";
      return this.ajax(url, 'GET', { data: query });
    },
    noteList(store, type, query) {
      var talent_id = query.talent_id;
      var url = this.get('host') + this.get('namespace') + "/talent/" + talent_id + "/notes";
      return this.ajax(url, 'GET', { data: query });
    },
    conversationList(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/conversations";
      return this.ajax(url, 'GET', { data: query });
    },
    addMessage() {
      var data = this.getData(...arguments);
      var conversation_id = data.conversation_id;
      var url = this.get('host') + this.get('namespace') + "/conversations/" + conversation_id + "/messages";
      return this.ajax(url, 'POST', { data: data });
    },
    addStage() {
      var data = this.getData(...arguments);
      var job_id = data.job_id;
      var name = data.name;

      var url = this.get('host') + this.get('namespace') + "/jobs/" + job_id + "/stages";
      return this.ajax(url, 'POST', { data: { name: name } });
    },
    addTag() {
      var data = this.getData(...arguments);
      var name = data.name;

      var url = this.get('host') + this.get('namespace') + "/talent/tags";
      return this.ajax(url, 'POST', { data: { name: name } });
    },
    addConversation() {
      var data = this.getData(...arguments);
      // api expects participants id array
      data["participants"] = [data.participant_id];
      data["text"] = data.last_message;

      var url = this.get('host') + this.get('namespace') + "/conversations";
      return this.ajax(url, 'POST', { data: data });
    },
    addNote() {
      var data = this.getData(...arguments);
      var body = data.body;
      var talent_id = data.talent_id;

      var url = this.get('host') + this.get('namespace') + "/talent/" + talent_id + "/notes";
      return this.ajax(url, 'POST', { data: { body: body } });
    },
    addBrandingGallery() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/jobs/content/" + data.content.id + "/activities";
      return this.ajax(url, 'GET');
    },
    addBrandingContent() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/jobs/content/" + data.content.id + "/activities";
      return this.ajax(url, 'GET');
    },
    addTestimonial() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/jobs/content";
      return this.ajax(url, 'POST', { data: data });
    },
    addBillingInfo() {
      var data = this.getData(...arguments);
      var formData = {
        'contact_name': data.contact_name,
        'contact_email': data.contact_email,
        'contact_address': data.contact_address,
        'vat_number': data.vat_number
      };
      var url = this.get('host') + this.get('namespace') + "/companies/billing";
      return this.ajax(url, 'POST', { data: formData });
    },
    getData(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      return data;
    },
    loadTeamMember(store, type, id) {
      var url = this.get('host') + this.get('namespace') + "/users/" + id;
      return this.ajax(url, 'GET');
    },
    addTeamMember() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/companies/members";
      return this.ajax(url, 'POST', { data: data });
    },
    updateTeamMember() {
      var data = this.getData(...arguments);
      if (!data.password) delete data.password;
      var member_id = data.user.id;
      var url = this.get('host') + this.get('namespace') + "/companies/members/" + member_id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    getSettingsStages(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/stages";
      return this.ajax(url, 'GET', { data: query });
    },
    updateStage() {
      var data = this.getData(...arguments);
      var stage_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/companies/stages/" + stage_id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    deleteStage() {
      var data = this.getData(...arguments);
      var stage_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/companies/stages/" + stage_id;
      return this.ajax(url, 'DELETE');
    },
    billingInformation(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/billing";
      return this.ajax(url, 'GET', { data: query });
    },
    emailTemplates(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/autoreplies";
      return this.ajax(url, 'GET', { data: query });
    },
    subscriptions(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/subscriptions";
      return this.ajax(url, 'GET', { data: query });
    },
    socialMedia(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/socialmedia";
      return this.ajax(url, 'GET', { data: query });
    },
    updateSocialMedia() {
      var data = this.getData(...arguments);

      var url = this.get('host') + this.get('namespace') + "/companies/socialmedia";
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateTour() {
      var data = this.getData(...arguments);

      var url = this.get('host') + this.get('namespace') + "/user/tours";
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateNotifications() {
      var data = this.getData(...arguments);

      var url = this.get('host') + this.get('namespace') + "/companies/notifications";
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateAutoreply() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/companies/autoreplies/" + data.id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateBillingInfo() {
      var data = this.getData(...arguments);
      var formData = {
        'contact_name': data.contact_name,
        'contact_email': data.contact_email,
        'contact_address': data.contact_address,
        'vat_number': data.vat_number
      };
      var url = this.get('host') + this.get('namespace') + "/companies/billing/" + data.id;
      return this.ajax(url, 'PATCH', { data: formData });
    },
    updateGDPRSettings() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/companies/settings";
      return this.ajax(url, 'PATCH', { data: data });
    },
    deleteTag() {
      var data = this.getData(...arguments);
      var tag_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/talent/tags/" + tag_id;
      return this.ajax(url, 'DELETE');
    },
    updateTag() {
      var data = this.getData(...arguments);
      var tag_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/talent/tags/" + tag_id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    getQuestions(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/questions";
      return this.ajax(url, 'GET', { data: query });
    },
    getContentBank(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/jobs/content";
      return this.ajax(url, 'GET', { data: query });
    },
    getBrandingGallery(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/branding";
      return this.ajax(url, 'GET', { data: query });
    },
    getBrandingContent(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/branding";
      return this.ajax(url, 'GET', { data: query });
    },
    getBrandingTestimonial(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/branding";
      return this.ajax(url, 'GET', { data: query });
    },
    updateContent() {
      var data = this.getData(...arguments);
      var content_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/jobs/content/" + content_id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateBrandingContent() {
      var data = this.getData(...arguments);
      var content_id = data.id;
      delete data.order;
      var url = this.get('host') + this.get('namespace') + "/companies/activities/" + content_id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    updateBrandingGallery() {
      var data = this.getData(...arguments);
      delete data.order;
      var panel_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/companies/activities/" + panel_id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    loadChannel(store, type, query) {
      var channel_id = query.channel_id;
      var url = this.get('host') + this.get('namespace') + "/channels/" + channel_id + "/feed";
      return this.ajax(url, 'GET', { data: query });
    },
    loadJob(store, type, query) {
      var job_id = query;
      var url = this.get('host') + this.get('namespace') + "/companies/jobs/" + job_id;
      return this.ajax(url, 'GET');
    },
    updateQuestion() {
      var data = this.getData(...arguments);
      var question_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/jobs/questions/" + question_id;
      return this.ajax(url, 'PATCH', { data: data });
    },
    addJobQuestion() {
      var data = this.getData(...arguments);
      var job_id = data.job_id;
      data = { id: data.question_id };
      var url = this.get('host') + this.get('namespace') + "/jobs/" + job_id + "/questions";
      var res = this.ajax(url, 'POST', { data: data }).then(response => {
        var d = new Date();
        var id = d.getTime();
        response.id = id;
        return response;
      });
      return res;
    },
    deleteBrandingContent() {
      var data = this.getData(...arguments);
      var panel_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/companies/activities/" + panel_id;
      var res = this.ajax(url, 'DELETE').then(() => {
        return null;
      });
      return res;
    },
    deleteBrandingGallery() {
      var data = this.getData(...arguments);
      var gallery_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/companies/activities/" + gallery_id;
      var res = this.ajax(url, 'DELETE').then(() => {
        return null;
      });
      return res;
    },
    deleteContent() {
      var data = this.getData(...arguments);
      var content_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/jobs/content/" + content_id;
      return this.ajax(url, 'DELETE');
    },
    deleteQuestion() {
      var data = this.getData(...arguments);
      var question_id = data.id;
      var url = this.get('host') + this.get('namespace') + "/jobs/questions/" + question_id;
      return this.ajax(url, 'DELETE');
    },
    deleteBrandingTestimonial() {
      var data = this.getData(...arguments);
      var testimony_id = data.content.id;
      var url = this.get('host') + this.get('namespace') + "/jobs/content/" + testimony_id;
      return this.ajax(url, 'DELETE');
    },
    workableAccount(store, type, query) {
      var url = this.get('host') + this.get('namespace') + "/companies/" + query.company_id + "/workable/accounts";
      return this.ajax(url, 'GET', { data: query });
    },
    updateWorkableAccount() {
      var data = this.getData(...arguments);
      var url = this.get('host') + this.get('namespace') + "/companies/" + data.company + "/workable/accounts/" + data.id;
      return this.ajax(url, 'PATCH', { data: data });
    }
  });
});