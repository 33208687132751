define('jobbio-backend/components/application/job-applications', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    active: '',
    expanded: 'false',
    showFilter: true,
    selectedJob: null,
    selectedStage: null,
    selectedTag: null,
    selectedApplicationType: 'pending',
    job_type: null,
    searchJobs: true,
    searchApplications: true,
    searchFollowers: false,
    searchTalentPool: false,
    jobs: null,
    onJobChanged: Ember.observer('selectedJob.id', function () {
      this.set('selectedStage', null);
      this.set('selectedTag', null);
      this.set('selectedApplicationType', 'pending');
      if (this.get('selectedJob.pending') == 0 && this.get('selectedJob.new_applications') > 0) {
        this.set('selectedApplicationType', 'submitted');
      }
    }),
    init() {
      this._super(...arguments);
      this.set('jobs', []);
    },
    didRender() {
      if (this.get('job_type') == 'active') {
        this.set('selectedLiveJob', this.get('selectedJob'));
      } else if (this.get('job_type') == 'paused') {
        this.set('selectedPausedJob', this.get('selectedJob'));
      } else if (this.get('job_type') == 'closed') {
        this.set('selectedClosedJob', this.get('selectedJob'));
      }
    }
  });
});