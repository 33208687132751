define('jobbio-backend/routes/company/jobs', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      id: null,
      tab: 'selected-tab'
    },
    model: function (params) {
      return {
        id: params.id,
        tab: params.tab
      };
    },
    afterModel: function () {
      // window.$("head").append(`
      //   <link rel="stylesheet" href="https://assets.ziggeo.com/v2-stable/ziggeo.css" />
      //   <script src="https://assets.ziggeo.com/v2-stable/ziggeo.js"></script>
      //   <script>
      //     var ziggeoApp = new ZiggeoApi.V2.Application({
      //     	token:"r1fe55282ce51b9d318a8f21252ecc9c"
      //     });
      //     ZiggeoApi.V2.Locale.setLocale("en");
      //   </script>
      // `);
    },
    actions: {
      didTransition() {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var urlParams = new URLSearchParams(window.location.search);
          var tabName = urlParams.get('tab');
          tabName = tabName ? tabName : 'live_jobs';
          this.controller.set("selectedTab", tabName);
          this.controller.set("resetSelectedJobs", !this.controller.get("resetSelectedJobs"));
          this.transitionTo({ queryParams: { tab: null } });
        });
      }
    }
  });
});