define('jobbio-backend/components/common/dashboard-chat', ['exports', 'jobbio-backend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    conversation: null,
    title: Ember.computed('conversation', function () {
      if (this.get('conversation') == null) {
        return "";
      }
      var currentUserId = this.get('session.data.authenticated.company_member.user.id');
      return this.get('conversation').title(currentUserId);
    }),
    subtitle: Ember.computed('conversation', function () {
      return "";
    }),
    image: Ember.computed('conversation', function () {
      if (this.get('conversation') == null) {
        return "";
      } else if (this.get('conversation.participant.role') === 'talent') {
        return this.get('conversation.participant.avatar');
      } else {
        return this.get('currentUser.teammember.company.image_logo');
      }
    })
  });
});