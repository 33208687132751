define('jobbio-backend/components/application/application-tags', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'tag',
    autoLoad: false,
    talent: null,
    onTagUpdated: Ember.observer('tagUpdated', function () {
      this.get('store').query('tag', {}).then(response => {
        if (response == null) {
          return;
        }
        var self = this;
        this.set('companyTags', []);
        response.forEach(function (object) {
          self.get('companyTags').pushObject(object);
        });
      });
    }),
    params: Ember.computed('cursor', 'talent.user.id', function () {
      var params = this._super();
      params['talent_id'] = this.get('talent.user.id');
      return params;
    }),
    init() {
      this._super(...arguments);
      this.send('loadMore');
      this.get('store').query('tag', {}).then(response => {
        if (response == null) {
          return;
        }
        var self = this;
        this.set('companyTags', []);
        response.forEach(function (object) {
          self.get('companyTags').pushObject(object);
        });
      });
    },
    didUpdateAttrs() {
      this.set('hasMore', true);
      this.set('objects', []);
      this.set('cursor', '');
      this.send('loadMore');
    },
    actions: {
      loaded() {
        let temp = this.get('objects').slice(0);
        this.set('objects', temp);
      }
    }
  });
});