define('jobbio-backend/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const inflector = _emberInflector.default.inflector;

  inflector.singularize('content');
  inflector.irregular('question', 'jobs/questions');
  inflector.irregular('job-content', 'jobs/content');
  inflector.irregular('talent-pool', 'talent/pool');
  inflector.irregular('memberdetail', 'companies/members/detail');
  inflector.irregular('company-member', 'companies/members');
  inflector.irregular('stage-settings', 'companies/stages');
  inflector.irregular('gdpr-settings', 'companies/settings');
  inflector.irregular('tour', 'user/tours');
  inflector.irregular('notification', 'companies/notifications');
  inflector.irregular('branding-panel', 'jobs/content');

  exports.default = {};
});