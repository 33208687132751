define('jobbio-backend/components/company/job-actions', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    subscription: Ember.inject.service(),
    domain: _environment.default.APP.DOMAIN,
    teamMembers: null,
    link: Ember.computed('selectedJob.status', function () {
      return "https://" + this.get('domain') + "/companies/" + this.get('selectedJob.company.slug') + "/jobs/" + this.get('selectedJob.slug');
    }),
    isDraft: Ember.computed('selectedJob.status', function () {
      return this.get('selectedJob.status') == 'draft';
    }),
    isLive: Ember.computed('selectedJob.status', function () {
      return this.get('selectedJob.status') == 'active';
    }),
    isPaused: Ember.computed('selectedJob.status', function () {
      return this.get('selectedJob.status') == 'paused';
    }),
    isClosed: Ember.computed('selectedJob.status', function () {
      return this.get('selectedJob.status') == 'closed';
    }),
    disableActionButtons: Ember.computed('descriptionWasUpdated', 'selectedJob.isUpdated', function () {
      return !this.get('descriptionWasUpdated') && this.get('selectedJob.isUpdated') || this.get('selectedJob.isUpdated') || this.get('selectedJob.skills').length == 0;
    }),
    onResetSelectedJobs: Ember.observer('resetSelectedJobs', function () {
      var self = this;
      setTimeout(function () {
        self.send('setSelectedJob');
      }, 1000);
    }),
    init: function () {
      this._super();
      this.get('subscription').load();
      var self = this;
      var teamMembers = [];
      this.get('store').findAll('memberdetail').then(members => {
        members.forEach(member => {
          if (member.get('id')) {
            if (member.get('user.id') != self.get('currentUser.teammember.id')) {
              if (member.get('role') != 'admin') {
                if (member.get('role') != 'recruiter_admin') {
                  teamMembers.pushObject(member);
                }
              }
            }
          }
        });
        this.set('teamMembers', teamMembers);
      });
    },
    actions: {
      closeJob() {
        var content = document.createElement('div');
        content.innerHTML = `<a id="close_job_swal_xButton" class="close" onClick="swal.close()">
                            <span>&times;</span>
                          </a>
                          <p style="padding: 20px 20px 0;">Pausing a role means you can republish this job at any time.<br/><br/>
                          Closing a role means all candidates will be informed that this role is no longer listed on Jobbio.</p>`;
        window.swal({
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: content,
          buttons: {
            pause: {
              text: 'Pause Job',
              className: 'btn btn-primary close_job_swal_pause'
            },
            close: {
              text: 'Close Job',
              className: 'btn btn-danger close_job_swal_close'
            }
          }
        }).then(val => {
          if (val == 'pause') {
            this.set('selectedJob.status', 'paused');
            this.get('selectedJob').save().then(() => {
              if (!this.get('feed')) {
                this.set('selectedTab', 'paused_jobs');
                this.send('move', this.get('selectedJob'), 'liveJobs', 'pausedJobs');
                this.set('currentUser.teammember.company.new_applications', this.get('currentUser.teammember.company.new_applications') - this.get('selectedJob.new_applications'));
                this.send('setSelectedJob');
              }
              this.set('currentUser.teammember.company.live_jobs', this.get('currentUser.teammember.company.live_jobs') - 1);
              this.toast.success('Your job has been paused.');
            });
          } else if (val == 'close') {
            window.swal({
              closeOnClickOutside: false,
              closeOnEsc: false,
              text: 'Did you hire for this role via Jobbio?',
              buttons: {
                cancel: {
                  text: 'No',
                  visible: true,
                  closeModal: true,
                  className: 'btn btn-secondary close_job_swal_cancel'
                },
                confirm: {
                  text: 'Yes',
                  className: 'btn btn-warning close_job_swal_confirm'
                }
              }
            }).then(val => {
              this.set('selectedJob.status', 'closed');
              if (val) {
                this.set('selectedJob.hired', true);
              }
              this.get('selectedJob').save().then(() => {
                if (!this.get('feed')) {
                  this.set('selectedTab', 'closed_jobs');
                  this.send('move', this.get('selectedJob'), 'liveJobs', 'closedJobs');
                  this.set('currentUser.teammember.company.new_applications', this.get('currentUser.teammember.company.new_applications') - this.get('selectedJob.new_applications'));
                  this.send('setSelectedJob');
                }
                this.set('currentUser.teammember.company.live_jobs', this.get('currentUser.teammember.company.live_jobs') - 1);
                this.toast.success('Your job has been closed.');
              });
            });
          }
        });
      },
      unpauseJob() {
        if (this.get('subscription.companySubscription.credits') === -1 || this.get('subscription.companySubscription.credits') > 0) {
          this.set('selectedJob.status', 'active');
          this.get('selectedJob').save().then(() => {
            this.send('updateCredits');
            if (!this.get('feed')) {
              this.set('selectedTab', 'live_jobs');
              this.send('move', this.get('selectedJob'), 'pausedJobs', 'liveJobs');
              this.set('currentUser.teammember.company.new_applications', this.get('currentUser.teammember.company.new_applications') + this.get('selectedJob.new_applications'));
              this.send('setSelectedJob');
            }
            this.set('currentUser.teammember.company.live_jobs', this.get('currentUser.teammember.company.live_jobs') + 1);
            this.toast.success('Your job has been unpaused.');
          });
        } else {
          this.send('jobCreditPopup');
        }
      },
      publishJob() {
        if (this.get('currentUser.teammember.company.status') == 'frozen') {
          this.send('showFrozenPopup', 'job_action_publish');
        } else {
          if (this.get('subscription.companySubscription.credits') === -1 || this.get('subscription.companySubscription.credits') > 0) {
            this.set('selectedJob.status', 'active');
            var self = this;
            if (this.get('feed')) {
              this.get('selectedJob').save().then(() => {
                self.send('updateCredits');
                self.set('currentUser.teammember.company.live_jobs', self.get('currentUser.teammember.company.live_jobs') + 1);
                self.toast.success('Your job has been published.');
              });
            } else {
              var swalText = '';
              var title = "Heads Up";
              var buttons = {
                cancel: {
                  text: "Close",
                  visible: true,
                  closeModal: true,
                  className: "btn btn-secondary publish_job_swal_cancel"
                },
                update_job: {
                  text: "Update Job",
                  className: "btn btn-warning publish_job_swal_update"
                }
              };
              this.get('selectedJob').save().then(function (record) {
                self.send('updateCredits');
                self.set('currentUser.teammember.company.live_jobs', self.get('currentUser.teammember.company.live_jobs') + 1);
                if (record.get('questions').length > 0 && record.get('content').length > 0) {
                  title = "Congrats";
                  swalText = "Your job is now live";
                  buttons = {
                    cancel: {
                      text: "Close",
                      visible: true,
                      closeModal: true,
                      className: "btn btn-secondary publish_job_swal_cancel"
                    },
                    view_job: {
                      text: "View Job",
                      className: "btn btn-warning publish_job_swal_view"
                    }
                  };
                } else if (record.get('questions').length == 0 && record.get('content').length == 0) {
                  swalText = "Your job is now live, however you have not added any questions or content to your job";
                } else if (record.get('questions').length == 0) {
                  swalText = "Your job is now live, however you have not added any questions to your job";
                } else if (record.get('content').length == 0) {
                  swalText = "Your job is now live, however you have not added any content to your job";
                }
                window.swal({
                  title: title,
                  text: swalText,
                  icon: "success",
                  buttons: buttons
                }).then(value => {
                  if (value) {
                    self.set('selectedTab', 'live_jobs');
                  }
                });
              }, xhr => {
                var errors = {};
                xhr.errors.forEach(error => {
                  errors[error.source.pointer.replace('/data/attributes/', '')] = 'This is a required field';
                });
                this.set('errors', errors);
              });
              this.send('move', this.get('selectedJob'), 'draftJobs', 'liveJobs');
              this.send('setSelectedJob');
            }
          } else {
            this.send('jobCreditPopup');
          }
        }
      },
      move(job, fromArrayName, toArrayName) {
        var fromArray = this.get(fromArrayName);
        var toArray = this.get(toArrayName);
        fromArray.removeObject(job);
        toArray.unshiftObject(job);
      },
      setSelectedJob() {
        this.set('selectedLiveJob', this.get('liveJobs.firstObject'));
        this.set('selectedPausedJob', this.get('pausedJobs.firstObject'));
        this.set('selectedDraftJob', this.get('draftJobs.firstObject'));
        this.set('selectedClosedJob', this.get('closedJobs.firstObject'));
        var type = this.get('type');
        if (type == 'active') {
          this.set('selectedJob', this.get('liveJobs.firstObject'));
        } else if (type == 'paused') {
          this.set('selectedJob', this.get('pausedJobs.firstObject'));
        } else if (type == 'draft') {
          this.set('selectedJob', this.get('draftJobs.firstObject'));
        } else if (type == 'closed') {
          this.set('selectedJob', this.get('closedJobs.firstObject'));
        }
      },
      duplicateJob(selectedJob) {
        if (this.get('feed')) {
          window.$('#jobFeedPopup').hide();
        }
        var previousName = selectedJob.get('title');
        var self = this;
        var swalText = `Please enter job title below.\nCurrent name: ${previousName}`;
        window.swal({
          title: "Heads Up",
          text: swalText,
          content: {
            element: "input",
            attributes: {
              placeholder: "Enter job title"
            }
          },
          buttons: {
            cancel: {
              text: "Close",
              visible: true,
              closeModal: true,
              className: "btn btn-secondary duplicate_job_swal_cancel"
            },
            confirm: {
              text: "Create Draft Job",
              className: "btn btn-warning duplicate_job_swal_confirm"
            }
          }
        }).then(name => {
          if (this.get('feed')) {
            window.$('#jobFeedPopup').show();
          }
          if (name != null) {
            if (name.length > 100) {
              window.swal({
                text: "Ensure job title has no more than 100 characters.",
                icon: "error",
                buttons: {
                  cancel: {
                    text: "Close",
                    visible: true,
                    closeModal: true,
                    className: "btn btn-secondary duplicate_job_swal_error_cancel"
                  }
                }
              }).then(() => {
                self.send('duplicateJob', selectedJob);
              });
            } else {
              var new_title = name == '' ? new_title = selectedJob.get('title') : name;
              var data = {
                title: new_title,
                selected_job_id: this.get('selectedJob.id')
              };
              this.get('store').createRecord('duplicated-job', data).save().then(response => {
                selectedJob.set('title', previousName);
                self.get('store').findRecord('job', response.id).then(job => {
                  if (!self.get('feed')) {
                    self.get('draftJobs').unshiftObject(job);
                    self.send('setSelectedJob');
                    self.set('selectedTab', 'draft_jobs');
                  }
                  self.toast.success('Your draft job has been created.');
                });
              });
            }
          }
        });
      },
      editJob(tabName) {
        window.$('#jobFeedPopup').modal('hide');
        this.get('router').transitionTo('company.jobs', { queryParams: { tab: tabName } });
      },
      shareJob() {
        if (this.get('teamMembers').length > 0) {
          window.$(`#shareJobTeamMembers_${this.get('id')}`).modal('show');
        } else {
          window.swal("Oops!", "You currently have no staff members.", "warning");
        }
      },
      jobCreditPopup() {
        window.swal({
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: { confirm: {
              text: 'Ok',
              closeModal: true,
              className: "btn btn-warning dashboard_activate_swal_confirm"
            } },
          text: "You've ran out of job credits. Please contact your relationship manager to get more credits.",
          icon: "warning"
        });
      },
      updateCredits() {
        let x = this.get('subscription.companySubscription.credits');
        this.set('subscription.companySubscription.credits', x === 0 || x === -1 ? x : x - 1);
      }
    }
  });
});