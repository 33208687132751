define('jobbio-backend/components/settings/workable-integration', ['exports', 'jobbio-backend/components/pagination-component', 'jobbio-backend/config/environment'], function (exports, _paginationComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'workable-account',
    page_size: 10,
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['company_id'] = this.get('currentUser.teammember.company.id');
      return params;
    }),
    saving: false,
    actions: {
      revokeWorkable: function () {
        var url = _environment.default.APP.API_HOST + '/companies/' + this.get('currentUser.teammember.company.id') + '/workable';
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          window.$.ajax({
            url: url,
            headers: {
              Authorization: headerValue
            },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            self.toast.success('Workable access revoked');
            self.set('workable', false);
          });
        });
      },
      accountSwitch: function (value) {
        value.set('active', !value.get('active'));
        var self = this;
        value.save().then(() => {
          self.toast.success('Workable details updated');
        });
      }
    }
  });
});