define('jobbio-backend/components/application/application-history', ['exports', 'jobbio-backend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'application-history',
    autoLoad: false,
    talent: null,
    params: Ember.computed('cursor', 'talent', function () {
      var params = this._super();
      params['talent_id'] = this.get('talent.user.id');
      return params;
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.send("loadMore");
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.set('hasMore', true);
      this.set('objects', []);
      this.set('cursor', '');
      this.send("loadMore");
    }
  });
});