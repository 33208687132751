define('jobbio-backend/components/company/post-content', ['exports', 'jobbio-backend/components/base-component', 'jobbio-backend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    options: null,
    init() {
      this._super(...arguments);
      this.set('options', {
        menubar: false,
        height: 300,
        theme: 'silver',
        elementpath: false,
        plugins: ['autolink lists link charmap print preview hr anchor pagebreak', 'searchreplace wordcount visualblocks visualchars code fullscreen', 'insertdatetime media nonbreaking save table directionality', 'emoticons template paste textpattern imagetools'],
        toolbar1: 'bold | bullist | link '
      });
    },
    actions: {
      text: function () {
        var content = this.getProperties('title', 'text');
        content.type = "text";
        var text = this.get('store').createRecord('job_content', content);
        text.save();
        this.send('close');
      },
      image: function () {
        var content = this.getProperties('title');
        content.text = this.get('image_text');
        content.type = "image";
        this.get('session').authorize('authorizer:token', (headerName, headerValue) => {
          var options = {
            headers: {
              Authorization: headerValue
            },
            contentType: 'multipart/form-data',
            method: 'POST',
            fileKey: 'image',
            data: content
          };
          this.get('image').upload(_environment.default.APP.API_HOST + '/jobs/content', options).then(() => {
            this.send('close');
          });
        });
      },
      video: function () {
        var content = this.getProperties('title', 'video');
        content.type = "video";
        var video = this.get('store').createRecord('job_content', content);
        video.save();
        this.send('close');
      },
      close() {
        this.setProperties({
          text: '',
          title: '',
          image: '',
          video: ''
        });
        window.$('#postToChannel .close').click();
      },
      uploadImage: function (file) {
        this.set('image', file);
      },
      closeModal() {
        window.$('#postToChannel .close').click();
        this.send('close');
      }
    }
  });
});